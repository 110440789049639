import { NavLink, useLocation } from "react-router-dom";
import React, { useContext, useState } from "react";
import classes from "./Header.module.scss";
import authContext from "../../contexts/auth-context";
import HeaderDropdown from "./HeaderDropdown/HeaderDropdown";
import logoDark from "../../logo.svg";
import logoLight from "../../logo-light.svg";
import { useTranslation } from "react-i18next";

const Header = () => {
    const { t } = useTranslation();
    const { isLoggedIn } = useContext(authContext);
    const current_path = useLocation().pathname;
    const [bgColorClass, setBgColorClass] = useState("");
    const changeColor = () => {
        if (window.scrollY < 16) {
            setBgColorClass("");
        } else if (window.scrollY < 32) {
            setBgColorClass("header-scroll-bg-1rem");
        } else if (window.scrollY < 48) {
            setBgColorClass("header-scroll-bg-2rem");
        } else if (window.scrollY < 64) {
            setBgColorClass("header-scroll-bg-3rem");
        } else {
            setBgColorClass("header-scroll-bg");
        }
    };

    const logo =
        !isLoggedIn && current_path === "/" && window.scrollY < 16
            ? logoLight
            : logoDark;

    const bgColor =
        !isLoggedIn && current_path === "/" && window.scrollY < 16
            ? "dark"
            : "light";

    window.addEventListener("scroll", changeColor);

    let content = (
        <div
            id="6ef218cc-3f29-49c9-b9e8-f3c3d21b82ee"
            className="d-flex align-items-center gap-3 mt-1"
        >
            <NavLink
                // id="header-to-login"
                id="e1981887-0840-491d-aec0-d22c7aa8592a"
                to="/login"
                className={`nav-link px-0 text-black ${
                    !isLoggedIn &&
                    current_path === "/" &&
                    window.scrollY < 16 &&
                    "text-white"
                }`}
                style={{ fontWeight: "600" }}
            >
                {t("header.login")}
            </NavLink>
            <NavLink
                // id="header-to-signup"
                id="d16c496f-0f0a-49a6-8758-21a6bf239471"
                to="/signup"
                className="btn btn-danger d-none d-sm-block"
                style={{ fontWeight: "600" }}
            >
                {t("header.signup")}
            </NavLink>
        </div>
    );

    if (isLoggedIn) {
        content = (
            <div
                id="cc1f659b-24ef-459f-8790-25a1b29ca1cc"
                className="d-flex align-items-center"
            >
                <HeaderDropdown />
            </div>
        );
    }
    return (
        <header
            id="47bed243-c2d5-4051-911f-9598835af4fb"
            className={`${classes.header} ${
                !isLoggedIn &&
                current_path === "/" &&
                classes["header-welcome-bg"]
            } ${bgColorClass !== "" && classes[bgColorClass]} ${
                (isLoggedIn || current_path !== "/") && "border-bottom"
            }`}
        >
            <div
                id="7ca15a00-2204-40c6-bdde-01f514425fe0"
                className="w-100 px-3"
            >
                <nav
                    id="57b1339b-814e-463f-bed8-1ee00bcffecb"
                    className={`nav justify-content-between`}
                >
                    <NavLink
                        // id="header-to-home"
                        id="0036e673-677e-4fcb-9920-8823d4576117"
                        to="/"
                        className="nav-link px-0 d-flex align-items-center gap-1"
                    >
                        <img
                            id="0036e673-677e-4fcb-9920-8823d4576117-img"
                            src={logo}
                            width={150}
                            alt="Logo"
                        />
                    </NavLink>
                    <div
                        id="87100f50-8e1c-4238-9e40-297d64890773"
                        className="d-flex align-items-center gap-3"
                    >
                        {/*<SelectLanguage bgColor={bgColor} />*/}
                        {content}
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;
