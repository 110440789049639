import { Navigate, Outlet, useMatch } from "react-router-dom";
import React, { useContext } from "react";
import Footer from "../../components/Footer/Footer";
import authContext from "../../contexts/auth-context";

export enum HelpPageTab {
    HOW_TO_SETUP_CREATOR_ACCOUNT = "how-to-set-up-creator-account",
}

const HelpPage = () => {
    const { isLoggedIn } = useContext(authContext);

    const match = useMatch("/help/:tab");
    if (!match)
        return (
            <Navigate
                to={`/help/${HelpPageTab.HOW_TO_SETUP_CREATOR_ACCOUNT}`}
            />
        );

    return (
        <div id="10bd4f0d-5452-487b-ac52-c07808d3b8b9" className="container">
            <div id="f519b036-fd44-4876-a0b2-07b678988711" className="py-4">
                <Outlet />
            </div>
            {!isLoggedIn && (
                <Footer id="4ebcab55-82a9-4333-8b66-ab11bcf854cf" />
            )}
        </div>
    );
};

export default HelpPage;
