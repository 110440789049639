import React, { ForwardedRef, InputHTMLAttributes } from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    errorMsg?: string;
    leadingAddon?: JSX.Element;
    trailingAddon?: JSX.Element;
}
const InputGroup = React.forwardRef(
    (
        {
            errorMsg,
            label,
            leadingAddon,
            trailingAddon,
            className,
            id,
            ...rest
        }: Props,
        ref: ForwardedRef<HTMLInputElement>
    ) => {
        return (
            <div id={id} className="w-100">
                {label && (
                    <label
                        id="bb19b4b5-f917-4ae7-a9a8-b283f1e865db"
                        className="form-label"
                    >
                        {label}
                    </label>
                )}
                <div
                    id="bb19b4b5-f917-4ae7-a9a8-b283f1e865db"
                    className={`input-group has-validation w-100 ${className}`}
                >
                    {leadingAddon}
                    <input
                        id={id}
                        className={`form-control ${!!errorMsg && "is-invalid"}`}
                        ref={ref}
                        {...rest}
                    />
                    {trailingAddon}
                    <div
                        id="a724dc1d-b0f5-46de-becb-cebb53c492fd"
                        className="invalid-feedback"
                    >
                        {errorMsg}
                    </div>
                </div>
            </div>
        );
    }
);

export default InputGroup;
