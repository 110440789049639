import { Link } from "react-router-dom";
import AuthForm from "../../../components/AuthForm/AuthForm";
import React, {
    ChangeEvent,
    HTMLAttributes,
    useContext,
    useEffect,
    useState,
} from "react";
import AuthContext from "../../../contexts/auth-context";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import { FailureResponse } from "../../../api/users/signup";
import { FALLBACK_LOCALE, LOCALE } from "../../../constants";
import { Trans, useTranslation } from "react-i18next";
import { GoogleLogin } from "@react-oauth/google";
import {
    ga_onLogin,
    ga_onSignup,
    ga_onSignupAttempt,
    LoginMethod,
    SignupMethod,
} from "../../../utils/google-analytics";

interface Props extends HTMLAttributes<any> {}

const SignupForm = ({ id }: Props) => {
    const { t } = useTranslation();
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [nameInputErrorMsg, setNameInputErrorMsg] = useState("");
    const [usernameInputErrorMsg, setUsernameInputErrorMsg] = useState("");
    const [emailInputErrorMsg, setEmailInputErrorMsg] = useState("");
    const [passwordInputErrorMsg, setPasswordInputErrorMsg] = useState("");
    const [formErrorMsg, setFormErrorMsg] = useState("");

    useEffect(() => {}, []);

    const isNameValid = () => {
        if (!name) {
            setNameInputErrorMsg(t("auth.pleaseEnterName"));
            return false;
        }
        return true;
    };

    const isUsernameValid = () => {
        if (!username) {
            setUsernameInputErrorMsg(t("auth.pleaseEnterUsername"));
            return false;
        }
        return true;
    };

    const isEmailValid = () => {
        if (!email) {
            setEmailInputErrorMsg(t("auth.pleaseEnterEmail"));
            return false;
        }
        return true;
    };

    const isPasswordValid = () => {
        if (!password) {
            setPasswordInputErrorMsg(t("auth.pleaseEnterPassword"));
            return false;
        }
        return true;
    };

    const nameInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.currentTarget.value);
        setNameInputErrorMsg("");
        setFormErrorMsg("");
    };

    const usernameInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setUsername(event.currentTarget.value);
        setUsernameInputErrorMsg("");
        setFormErrorMsg("");
    };

    const emailInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
        setEmailInputErrorMsg("");
        setFormErrorMsg("");
    };

    const passwordInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setPassword(event.currentTarget.value);
        setPasswordInputErrorMsg("");
        setFormErrorMsg("");
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();

        if (
            isNameValid() &&
            isUsernameValid() &&
            isEmailValid() &&
            isPasswordValid()
        ) {
            setIsLoading(true);
            setFormErrorMsg("");
            try {
                const invitedBy = localStorage.getItem("invitedBy");
                ga_onSignupAttempt(SignupMethod.SIGNUP_FORM, id!);
                await authCtx.signup(
                    name,
                    username,
                    email,
                    password,
                    invitedBy
                );
                ga_onSignup(SignupMethod.SIGNUP_FORM, id!);
                localStorage.removeItem("invitedBy");
                const referrer = localStorage.getItem("referrer");
                localStorage.setItem("checkIfCreator", "true");
                if (referrer) {
                    window.location.href = referrer;
                }
            } catch (error) {
                const { errors } = error as FailureResponse;

                if (errors) {
                    if (errors.non_field_errors)
                        setFormErrorMsg(errors.non_field_errors[0]);
                    if (errors.name) setNameInputErrorMsg(errors.name[0]);
                    if (errors.username)
                        setUsernameInputErrorMsg(errors.username[0]);
                    if (errors.email) setEmailInputErrorMsg(errors.email[0]);
                    if (errors.password)
                        setPasswordInputErrorMsg(errors.password[0]);
                    if (errors.invited_by)
                        setFormErrorMsg(errors.invited_by[0]);
                }
            }
            setIsLoading(false);
        }
    };

    const loginWithGoogle = async (googleJWT: string) => {
        setFormErrorMsg("");
        setIsLoading(true);
        try {
            const invitedBy = localStorage.getItem("invitedBy");
            ga_onSignupAttempt(SignupMethod.SIGNUP_WITH_GOOGLE, id!);
            const response = await authCtx.loginWithGoogle(
                googleJWT,
                invitedBy
            );
            if (response.status == 201) {
                ga_onSignup(SignupMethod.SIGNUP_WITH_GOOGLE, id!);
                localStorage.setItem("checkIfCreator", "true");
            } else ga_onLogin(LoginMethod.LOGIN_WITH_GOOGLE, id!);
            localStorage.removeItem("invitedBy");
            const referrer = localStorage.getItem("referrer");
            if (referrer) {
                window.location.href = referrer;
            }
        } catch (error) {
            const { errors } = error as FailureResponse;

            if (errors) {
                if (errors.non_field_errors)
                    setFormErrorMsg(errors.non_field_errors[0]);
            }
        }
        setIsLoading(false);
    };

    return (
        <>
            <AuthForm
                id={id}
                title={t("auth.joinAblebees")}
                errorMsg={formErrorMsg}
                submitButtonText={t("auth.signup")}
                onSubmit={submitHandler}
                isLoading={isLoading}
            >
                <GoogleLogin
                    width="360"
                    text="signup_with"
                    locale={localStorage.getItem(LOCALE) || FALLBACK_LOCALE}
                    onSuccess={async (credentialResponse) => {
                        await loginWithGoogle(
                            credentialResponse.credential || ""
                        );
                    }}
                    onError={() => {}}
                />
                <div id="1f2511ab-572b-41cd-9c9b-cb5b9fc50ccc">or</div>
                <InputGroup
                    id="f8284cc8-dd4a-4d04-b667-41caaba23143"
                    type="text"
                    placeholder={t("auth.name")}
                    errorMsg={nameInputErrorMsg}
                    onChange={nameInputChangeHandler}
                    value={name}
                />
                <InputGroup
                    id="1c571b2b-8a7e-4cc5-ae8c-34115a828cd6"
                    type="email"
                    placeholder={t("auth.email")}
                    errorMsg={emailInputErrorMsg}
                    onChange={emailInputChangeHandler}
                    value={email}
                />
                <InputGroup
                    id="5179bc50-71d6-4ffb-a67c-b9e01b8d9908"
                    type="text"
                    placeholder={t("auth.username")}
                    errorMsg={usernameInputErrorMsg}
                    onChange={usernameInputChangeHandler}
                    value={username}
                />
                <InputGroup
                    id="be43a3b2-7864-4e52-a7c3-75700dc1f4c9"
                    type="password"
                    placeholder={t("auth.password")}
                    errorMsg={passwordInputErrorMsg}
                    onChange={passwordInputChangeHandler}
                    value={password}
                />
            </AuthForm>
            <div
                id="dfd9a492-77f6-4bba-b4ed-943759545cb3"
                className="d-flex flex-column align-items-center mt-2 gap-3"
            >
                <div
                    id="0d5a1928-8c9f-42fe-b4cf-d292e0245a75"
                    className="form-text"
                >
                    <Trans
                        id="2b67f890-e94f-4071-b0f0-23865c14ba0a"
                        i18nKey="auth.acceptTerms"
                        components={[
                            <Link
                                // id="signup-form-to-terms"
                                id="241c59cc-8244-449a-b502-1691e6b2b96d"
                                to="/terms"
                                className="text-decoration-none"
                            />,
                            <Link
                                // id="signup-form-to-privacy"
                                id="ffabbb2c-2e23-4b68-a80e-75309a5c8ce4"
                                to="/privacy"
                                className="text-decoration-none"
                            />,
                            <Link
                                // id="signup-form-to-cookies"
                                id="a6d7bf63-0639-4fe1-9c57-7b30fd0eb9f0"
                                to="/cookies"
                                className="text-decoration-none"
                            />,
                        ]}
                    />
                </div>
                <div
                    id="03e93788-f8eb-432e-a2ad-0e3494198cb3"
                    className="form-text mt-2"
                >
                    {t("auth.alreadyHaveAccount")}{" "}
                    <Link
                        // id="signup-form-to-login"
                        id="a70e0695-8732-4a92-924f-a0bb67df3c03"
                        to="/login"
                        className="text-decoration-none"
                    >
                        {t("auth.login")}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default SignupForm;
