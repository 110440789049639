import React, { HTMLAttributes, useContext } from "react";
import TopUpBalanceForm from "./TopUpBalanceForm/TopUpBalanceForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../../contexts/auth-context";
import { FALLBACK_LOCALE, LOCALE } from "../../../../../constants";
import { StripeElementLocale } from "@stripe/stripe-js/types/stripe-js/elements-group";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PK!, {
    locale: (localStorage.getItem(LOCALE) ||
        FALLBACK_LOCALE) as StripeElementLocale,
});

interface Props extends HTMLAttributes<any> {}

const BalanceSettings = ({ id }: Props) => {
    const { user } = useContext(AuthContext);
    const { t } = useTranslation();
    return (
        <div id={id}>
            <h6 id="65be23dc-3a27-4c24-90f2-c2a69938bba0">
                {t("settingsPage.accountSettings.balanceSettings.topUpBalance")}
            </h6>
            <hr id="3ab33399-ebdf-436a-810f-4677a878750b" />
            <div
                id="8762383a-4fc1-4d47-a7a8-7010ece375ff"
                className="d-flex flex-column gap-3 justify-content-between"
            >
                <div
                    id="1e09007b-a565-4ec0-9b0d-1c1799d93227"
                    className="d-flex gap-2"
                >
                    <span
                        id="c7d76716-7519-41b4-a4bd-dc5e45578bd4"
                        className="fs-6"
                    >
                        {t(
                            "settingsPage.accountSettings.balanceSettings.balance"
                        )}
                        :
                    </span>{" "}
                    <span
                        id="23bceb36-e842-4f50-a724-51c7edff80b3"
                        className="text-primary"
                    >
                        {user!.account!.payment_settings.displayBalance()}
                    </span>
                </div>
                <Elements stripe={stripe}>
                    <TopUpBalanceForm id="e55caebe-bc53-45d8-884b-219b8c674cb8" />
                </Elements>
            </div>
        </div>
    );
};

export default BalanceSettings;
