import { Link, useSearchParams } from "react-router-dom";
import {
    HTMLAttributes,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import {
    FailureResponse as VerifyEmailFailureResponse,
    verifyEmail as api_verifyEmail,
} from "../../api/users/verifyEmail";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import DraftPetitionContext from "../../contexts/draft-petition-context";

interface Props extends HTMLAttributes<any> {}

const VerifyEmailPage = ({ id }: Props) => {
    const { t } = useTranslation();
    let [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const { draftPetition } = useContext(DraftPetitionContext);

    const verifyEmail = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await api_verifyEmail(code!);
            const referrer = localStorage.getItem("referrer");
            if (referrer) {
                window.location.href = referrer;
            }
            setError(false);
            setMessage(response.message);
        } catch (error) {
            const { errors } = error as VerifyEmailFailureResponse;
            setError(true);
            if (errors)
                if (errors.code) setMessage(errors.code[0]);
                else if (errors.error)
                    setMessage(
                        errors.error instanceof Array
                            ? errors.error[0]
                            : errors.error
                    );
        }
        setIsLoading(false);
    }, [code]);

    useEffect(() => {
        verifyEmail();
    }, [verifyEmail]);

    let link = (
        <Link
            // id="verify-email-page-to-home"
            id="309ea6b8-25d5-4cb3-86b3-4dc1f36f50a6"
            to="/"
            className="text-decoration-none"
        >
            {t("verifyEmailPage.home")}
        </Link>
    );

    if (!error && draftPetition) {
        link = (
            <Link
                // id="verify-email-page-to-start"
                id="de4e34ca-b371-49b5-8637-2ca561f9c72f"
                to="/start"
                className="btn btn-sm btn-primary"
            >
                {t("navbar.startPetition")}
            </Link>
        );
    }

    return (
        <div id={id} className="d-flex flex-column align-items-center">
            {isLoading && <Spinner id="7fa58f13-d0e1-4193-a10b-290ea9eb8de8" />}
            {!isLoading && (
                <>
                    <div
                        id="d5e6ffa0-ff47-4a08-b1fd-e9b5bab8c225"
                        className={`alert alert-${
                            error ? "danger" : "success"
                        } w-100`}
                        role="alert"
                    >
                        {message}
                    </div>
                    <div
                        id="eacb314c-6da6-4a23-9cc4-9df370e535b7"
                        className="d-flex flex-column align-items-center"
                    >
                        <div
                            id="3908e95d-515f-4fe0-aca3-83ee78b4268e"
                            className="form-text mt-1"
                        >
                            {link}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default VerifyEmailPage;
