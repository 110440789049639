import React, { HTMLAttributes, useContext, useEffect } from "react";
import ChargeBreakdown from "../ChargeBreakdown/ChargeBreakdown";
import AuthContext from "../../contexts/auth-context";
import { useTranslation } from "react-i18next";

export enum PledgeSourceTypes {
    PAYMENT_METHOD = "payment_method",
    BALANCE = "balance",
}
export type PledgeSourceType =
    | PledgeSourceTypes.BALANCE
    | PledgeSourceTypes.PAYMENT_METHOD;

interface Props extends HTMLAttributes<any> {
    amount: number;
    source: PledgeSourceType;
    onSourceChange: (source: PledgeSourceType) => void;
}

const SelectPledgeSourceForm = ({
    amount,
    source = PledgeSourceTypes.BALANCE,
    onSourceChange,
    id,
}: Props) => {
    const { user, refreshUser } = useContext(AuthContext);
    const { t } = useTranslation();

    useEffect(() => {
        refreshUser();
    }, []);

    const sourceChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        onSourceChange(event.currentTarget.value as PledgeSourceType);
    };

    return (
        <div id={id}>
            <label id="2494709d-e1c7-4406-9491-976e53692385" className="mb-2">
                {t("selectPledgeSourceForm.pledgeSource")}:
            </label>
            <div
                id="bd7aedf7-991d-4e5b-a742-fb971e68c7fa"
                className="form-check"
            >
                <input
                    id="ad07cb68-9fe6-46b6-9eca-5e962f4635c4"
                    className="form-check-input"
                    type="radio"
                    name="balanceInput"
                    checked={source === PledgeSourceTypes.BALANCE}
                    onChange={sourceChangeHandler}
                    value={PledgeSourceTypes.BALANCE}
                />
                <label
                    id="b5bfcd86-e2a9-4b0f-ba85-400ccddf3aa4"
                    className="form-check-label"
                    htmlFor="balanceInput"
                >
                    {t("selectPledgeSourceForm.balance")} (
                    {
                        <span
                            id="292f7cdc-f20b-4b11-b7d0-d6c33ac5cb54"
                            className="text-primary"
                        >
                            {user!.account!.payment_settings.displayBalance()}
                        </span>
                    }
                    )
                </label>
            </div>
            <div
                id="ceb4cd8c-a2fa-4f7f-ad6d-715202e7dc5c"
                className="form-check"
            >
                <input
                    id="c48c5d23-8c33-4da3-a41b-6c5c67069d3f"
                    className="form-check-input"
                    type="radio"
                    name="paymentMethodInput"
                    checked={source === PledgeSourceTypes.PAYMENT_METHOD}
                    onChange={sourceChangeHandler}
                    value={PledgeSourceTypes.PAYMENT_METHOD}
                />
                <label
                    id="2ca3a08d-341f-4e00-92c4-7412822aa1d0"
                    className="form-check-label"
                >
                    {t("selectPledgeSourceForm.paymentMethod")}
                </label>
            </div>
            {source === PledgeSourceTypes.PAYMENT_METHOD && (
                <ChargeBreakdown
                    id="c73bb784-24e9-41aa-8421-fd6d508d04e4"
                    amount={amount}
                    type="pledge"
                />
            )}
        </div>
    );
};

export default SelectPledgeSourceForm;
