import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
} from "react";
import { PaymentIntent, Stripe } from "@stripe/stripe-js";
import { delay } from "../utils/helpers";
import AlertOverlay from "../components/Modal/overlays/AlertOverlay/AlertOverlay";
import { Link } from "react-router-dom";
import ModalContext from "./modal-context";
import { useTranslation } from "react-i18next";
import {
    createPayment as api_createPayment,
    FailureResponse as createPaymentFailureResponse,
} from "../api/payments/createPayment";
import { ApiErrorCode } from "../constants";
import EmailNotVerifiedErrorOverlay from "../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";

const defaultState = {
    processPaymentIntentStatus: async (
        paymentIntent: PaymentIntent,
        onSuccess: () => Promise<void> | void
    ) => {},
    makePayment: async (
        stripe: Stripe | null,
        amount: number,
        setIsLoading: Dispatch<SetStateAction<boolean>>,
        setAmountInputErrorMsg: Dispatch<SetStateAction<string>>,
        onSuccess: () => Promise<void> | void,
        petitionSupporterUUID?: string
    ) => {},
};

const PaymentContext = createContext(defaultState);

export const PaymentContextProvider = ({
    children,
}: {
    children: JSX.Element;
}) => {
    const { showModal, closeModal } = useContext(ModalContext);
    const { t } = useTranslation();

    const processPaymentIntentStatus = useCallback(
        async (
            paymentIntent: PaymentIntent,
            onSuccess: () => Promise<void> | void
        ) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    // wait until Stripe webhook event is processed on the server
                    // 5s should be more than enough I guess
                    await delay(5000);
                    await onSuccess();
                    break;
                case "requires_payment_method":
                    showModal(
                        <AlertOverlay
                            id="1e45d082-1581-4341-b82f-00efe0d3e867"
                            status="error"
                            message={t(
                                "paymentContext.tryAnotherPaymentMethod"
                            )}
                            link={
                                <Link
                                    id="payment-context-requires_payment_method-alert-overlay-to-settings-pyment-methods"
                                    to="/settings/payment-methods"
                                    className="btn btn-sm btn-primary"
                                >
                                    {t("paymentContext.updatePaymentMethod")}
                                </Link>
                            }
                        />
                    );
                    break;
                default:
                    showModal(
                        <AlertOverlay
                            id="60fb49ce-14c2-4485-89e4-9fb5ca6dc2ff"
                            status="error"
                            message={t("paymentContext.somethingWentWrong")}
                        />
                    );
                    break;
            }
        },
        []
    );

    const makePayment = async (
        stripe: Stripe | null,
        amount: number,
        setIsLoading: Dispatch<SetStateAction<boolean>>,
        setAmountInputErrorMsg: Dispatch<SetStateAction<string>>,
        onSuccess: () => Promise<void> | void,
        petitionSupporterUUID?: string
    ) => {
        if (!stripe) return;

        // Docs: https://stripe.com/docs/payments/accept-a-payment?ui=elements
        setIsLoading(true);
        try {
            const { payment_intent_client_secret: clientSecret } =
                await api_createPayment(amount * 100, petitionSupporterUUID);

            const { paymentIntent, error } = await stripe.confirmCardPayment(
                clientSecret,
                {
                    return_url: `${process.env.REACT_APP_BASE_URL}/settings/account`,
                }
            );

            if (error) {
                if (error.payment_intent)
                    await processPaymentIntentStatus(
                        error.payment_intent,
                        onSuccess
                    );
                else {
                    let message = t("paymentContext.somethingWentWrong");

                    if (error.message) message = error.message;
                    showModal(
                        <AlertOverlay
                            id="c3c3a6a3-12c7-4e92-b7aa-95c98996205b"
                            status="error"
                            message={message}
                        />
                    );
                }
            } else {
                await processPaymentIntentStatus(paymentIntent, onSuccess);
            }
        } catch (error) {
            const { errors, status_code, code } =
                error as createPaymentFailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else if (code === ApiErrorCode.NO_PAYMENT_METHOD) {
                        showModal(
                            <AlertOverlay
                                id="6c02bb34-33cd-486a-9020-e14968454034"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                                link={
                                    <Link
                                        id="payment-context-no_payment_method-alert-overlay-to-settings-pyment-methods"
                                        to="/settings/payment-methods"
                                        className="btn btn-sm btn-primary"
                                    >
                                        {t(
                                            "settingsPage.paymentMethodsSettings.addNewPaymentMethod.addPaymentMethod"
                                        )}
                                    </Link>
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                id="dded3b00-657e-4bb2-b522-b51b3b249596"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else {
                    if (errors.amount) setAmountInputErrorMsg(errors.amount[0]);
                    else if (errors.non_field_errors)
                        setAmountInputErrorMsg(errors.non_field_errors[0]);
                    else if (errors.error)
                        showModal(
                            <AlertOverlay
                                id="743c0fe5-481e-41a1-8422-6f43fddb4cd1"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                }
            }
        }
        setIsLoading(false);
    };

    const contextValue = {
        processPaymentIntentStatus,
        makePayment,
    };

    return (
        <PaymentContext.Provider value={contextValue}>
            {children}
        </PaymentContext.Provider>
    );
};

export default PaymentContext;
