import blank_profile_image from "../../../../../images/blank-profile-picture.svg";
import React, {
    ChangeEvent,
    HTMLAttributes,
    useContext,
    useRef,
    useState,
} from "react";
import {
    FailureResponse as UpdateProfileImageFailureResponse,
    updateProfileImage as api_updateProfileImage,
} from "../../../../../api/users/updateProfileImage";
import AuthContext from "../../../../../contexts/auth-context";
import Button from "../../../../../components/UI/Button/Button";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../../contexts/modal-context";
import { ApiErrorCode } from "../../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import InputGroup from "../../../../../components/UI/InputGroup/InputGroup";
import { useTranslation } from "react-i18next";
import { parseProfileImageSrc } from "../../../../../utils/helpers";

interface Props extends HTMLAttributes<any> {}

const ProfileImageForm = ({ id }: Props) => {
    const { t } = useTranslation();
    const { showModal } = useContext(ModalContext);
    const inputRef = useRef<HTMLInputElement>(null);
    const { user, storeUser } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [profileImageInputErrorMsg, setProfileImageInputErrorMsg] =
        useState("");

    const profileImageInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setImageFile(null);
        const file = event.currentTarget.files![0];
        if (file.size >= 2_092_152) {
            setProfileImageInputErrorMsg(
                t(
                    "settingsPage.profileSettings.profileImageForm.profileImageSize"
                )
            );
        } else {
            setImageFile(file);
            setProfileImageInputErrorMsg("");
        }
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (imageFile) {
            try {
                setIsLoading(true);
                const response = await api_updateProfileImage(imageFile);
                storeUser(response);
                setImageFile(null);
                inputRef.current!.value = "";
            } catch (error) {
                const { errors, status_code, code } =
                    error as UpdateProfileImageFailureResponse;

                if (errors) {
                    if (status_code === 403) {
                        if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                            showModal(
                                <EmailNotVerifiedErrorOverlay
                                    id="3bf99637-dadf-49c1-afec-58f48d3b9ccc"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                        } else
                            showModal(
                                <AlertOverlay
                                    id="4fe838b3-2974-44b2-b195-8d3e0d7c523a"
                                    status="error"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                    } else {
                        if (errors.profile_image)
                            setProfileImageInputErrorMsg(
                                errors.profile_image[0]
                            );
                    }
                }
            }
            setIsLoading(false);
        }
    };

    let image_src: string = blank_profile_image;
    if (!!user?.profile_image)
        image_src = parseProfileImageSrc(user.profile_image);

    return (
        <div id={id} className="border rounded-3 p-3">
            <h6 id="c249389a-6676-4833-928c-2e5252effe21">
                {t(
                    "settingsPage.profileSettings.profileImageForm.uploadProfileImage"
                )}
            </h6>
            <hr id="2ff3d299-aefe-424d-b0fd-149cb858cddc" />
            <div
                id="06806887-6594-4fda-b38c-bfdadbc855ea"
                className="d-flex flex-column align-items-center"
            >
                <img
                    id="42a1f2ce-ff5d-4814-9799-6400e9272b02"
                    src={image_src}
                    alt="profile"
                    width="130"
                    height="130"
                    className="rounded-circle me-2 border"
                />
                <form
                    id="1a34b21a-1069-435a-951e-e05109918c14"
                    className="mt-3 w-100"
                    onSubmit={submitHandler}
                >
                    <span
                        id="0b072879-d8e0-466d-b293-ec76067ccaab"
                        className="form-text"
                    >
                        {t(
                            "settingsPage.profileSettings.profileImageForm.uploadSquareImage"
                        )}
                    </span>
                    <InputGroup
                        id="5ebe4b4f-1b5f-4760-91ca-04c5440634e9"
                        className="input-group-sm"
                        errorMsg={profileImageInputErrorMsg}
                        type="file"
                        accept="image/*"
                        ref={inputRef}
                        onChange={profileImageInputChangeHandler}
                        trailingAddon={
                            <Button
                                id="b280ffe2-c710-4e57-886a-efdf444bb5aa"
                                className="btn btn-sm btn-primary"
                                type="submit"
                                disabled={
                                    !imageFile || !!profileImageInputErrorMsg
                                }
                                isLoading={isLoading}
                                onlySpinner={true}
                            >
                                {t(
                                    "settingsPage.profileSettings.profileImageForm.upload"
                                )}
                            </Button>
                        }
                    />
                </form>
            </div>
        </div>
    );
};

export default ProfileImageForm;
