import React from "react";
import PaymentsList from "./PaymentsList/PaymentsList";

const BillingSettingsPage = () => {
    return (
        <div
            id="7cf00fb6-7512-4945-a818-d9a6cea87645"
            className="d-flex flex-column gap-3"
        >
            <div
                id="a4ab19a5-94bf-4500-962a-643156c4b55c"
                className="border rounded-3 p-3"
            >
                <PaymentsList id="754e4da8-b607-4e17-af65-904e9c2bf100" />
            </div>
        </div>
    );
};

export default BillingSettingsPage;
