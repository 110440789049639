import PaymentMethodSetupForm from "./PaymentMethodSetupForm/PaymentMethodSetupForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import React, { HTMLAttributes, useContext } from "react";
import { FALLBACK_LOCALE, LOCALE } from "../../../../../constants";
import { useTranslation } from "react-i18next";
import { StripeElementLocale } from "@stripe/stripe-js/types/stripe-js/elements-group";
import { Link } from "react-router-dom";
import AuthContext from "../../../../../contexts/auth-context";

interface Props extends HTMLAttributes<any> {
    setPaymentMethodAsDefault: (id: string) => void;
}

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PK!, {
    locale: (localStorage.getItem(LOCALE) ||
        FALLBACK_LOCALE) as StripeElementLocale,
});

const AddNewPaymentMethod = ({ id, setPaymentMethodAsDefault }: Props) => {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);

    let content = <></>;
    if (user?.account) {
        if (user.account.payment_settings.currency) {
            const options: StripeElementsOptions = {
                mode: "setup",
                currency: user.account.payment_settings.currency,
                setupFutureUsage: "on_session",
                locale: (localStorage.getItem(LOCALE) ||
                    FALLBACK_LOCALE) as StripeElementLocale,
            };

            content = (
                <Elements stripe={stripe} options={options}>
                    <PaymentMethodSetupForm
                        id="19688108-4ccd-4d46-a38d-8c54eaeb9613"
                        setPaymentMethodAsDefault={setPaymentMethodAsDefault}
                    />
                </Elements>
            );
        } else {
            content = (
                <div
                    id="38b4aaaa-de65-450d-aa4a-11e7f1b86163"
                    className="d-flex gap-3 justify-content-between"
                >
                    <div id="dd75f572-e432-4c95-b9bc-f37de0918767">
                        {t("petition.pleaseSetCurrency")}
                    </div>
                    <Link
                        // id="add-new-payment-method-to-settings-account"
                        id="0ffd73af-e312-4299-ad7c-9862aa565e1b"
                        to="/settings/account"
                        className="btn btn-sm btn-primary"
                    >
                        {t("petition.setCurrency")}
                    </Link>
                </div>
            );
        }
    }

    return (
        <div id={id} className="border rounded-3 p-3">
            <h6 id="f0262ba1-bcd9-43d0-8733-c3b5acbc9386">
                {t(
                    "settingsPage.paymentMethodsSettings.addNewPaymentMethod.addNewPaymentMethod"
                )}
            </h6>
            <hr id="df3094c0-cce5-4aa8-a970-6dcd3c0edd6c" />
            {content}
        </div>
    );
};

export default AddNewPaymentMethod;
