import classes from "./PageContainer.module.scss";
import React, { useContext } from "react";
import AuthContext from "../../contexts/auth-context";
import Navbar from "../Navbar/Navbar";

type Props = {
    children: React.ReactNode;
};

const PageContainer = ({ children }: Props) => {
    const { isLoggedIn, navbarHidden } = useContext(AuthContext);

    let content = (
        <div
            id="54be46fa-4312-48a0-8189-abb984287c32"
            className={`${classes["page-container"]} position-relative`}
        >
            <div id="662ab519-731a-49ee-b7b2-a40e3821d1b1" className="row mx-0">
                <div
                    id="21c711d2-32ba-4f5e-8e7f-5a6d52996fd3"
                    className="col pt-3 px-0"
                >
                    {children}
                </div>
            </div>
        </div>
    );

    if (isLoggedIn && !navbarHidden) {
        content = (
            <div
                id="c107a224-c63c-4779-a16f-93a54719c484"
                className={`${classes["page-container"]} container position-relative`}
            >
                <Navbar id="857e6a06-9c03-498d-a2ee-26562b212f4b" />
                <div
                    id="199f2c49-5fb2-4d3a-8137-1aadf7e59e8f"
                    className={`${classes["page-content"]}`}
                >
                    <div
                        id="6143e8be-38c7-4f07-8f57-56f25af2fe1a"
                        className="row"
                    >
                        <div
                            id="de6617a3-30f5-479e-b42d-2b8b44c03477"
                            className="col col-lg-9 col-xl-8 col-xxl-7 p-0 m-3"
                        >
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return content;
};

export default PageContainer;
