import React, {
    ChangeEvent,
    HTMLAttributes,
    useContext,
    useEffect,
    useState,
} from "react";
import InputGroup from "../../../../../components/UI/InputGroup/InputGroup";
import Button from "../../../../../components/UI/Button/Button";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../../contexts/auth-context";
import Textarea from "../../../../../components/UI/Textarea/Textarea";
import {
    ApiErrorCode,
    MAX_DESCRIPTION_LENGTH,
    MAX_HEADLINE_LENGTH,
    MAX_PETITION_DESCRIPTION_LENGTH,
} from "../../../../../constants";
import {
    FailureResponse as UpdateProfileFailureResponse,
    updateProfile as api_updateProfile,
} from "../../../../../api/users/updateProfile";
import EmailNotVerifiedErrorOverlay from "../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../../contexts/modal-context";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "../../../SettingsPage";

interface Props extends HTMLAttributes<any> {}

const ProfileAboutForm = ({ id, className }: Props) => {
    const { t } = useTranslation();
    const { scrollMarginTop } = useOutletContext<OutletContextType>();
    const { user, refreshUser } = useContext(AuthContext);
    const { showModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [headline, setHeadline] = useState(user!.profile!.headline || "");
    const [headlineInputErrorMsg, setHeadlineInputErrorMsg] = useState("");
    const [description, setDescription] = useState(
        user!.profile!.description || ""
    );
    const [descriptionTextareaErrorMsg, setDescriptionInputErrorMsg] =
        useState("");

    let saveButtonDisabled = true;

    useEffect(() => {
        refreshUser();
    }, [refreshUser]);

    const checkSaveButtonState = () => {
        if (
            headline !== user?.profile!.headline ||
            description !== user?.profile!.description
        ) {
            saveButtonDisabled = false;
        } else {
            saveButtonDisabled = true;
        }
    };

    checkSaveButtonState();

    const headlineInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        if (event.currentTarget.value !== user?.profile!.headline)
            saveButtonDisabled = false;
        setHeadline(event.currentTarget.value);
        setHeadlineInputErrorMsg("");
        checkSaveButtonState();
    };

    const descriptionTextareaChangeHandler = (
        event: ChangeEvent<HTMLTextAreaElement>
    ) => {
        if (event.currentTarget.value !== user?.profile!.description)
            saveButtonDisabled = false;
        setDescription(event.currentTarget.value);
        setDescriptionInputErrorMsg("");
        checkSaveButtonState();
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            setIsLoading(true);
            const response = await api_updateProfile(
                user!.uuid,
                headline !== user?.profile!.headline ? headline : undefined,
                description !== user?.profile!.description
                    ? description
                    : undefined
            );
            refreshUser();
            saveButtonDisabled = true;
        } catch (error) {
            const { errors, status_code, code } =
                error as UpdateProfileFailureResponse;

            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                id="17686fb0-9726-4bce-abb3-d50ed6ab880c"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                id="9aaf8a1f-30c7-4ab1-b2b1-b599bba7a7cd"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else {
                    if (errors.headline)
                        setHeadlineInputErrorMsg(errors.headline[0]);
                    if (errors.description)
                        setDescriptionInputErrorMsg(errors.description[0]);
                }
            }
        }
        setIsLoading(false);
    };

    return (
        <div
            id={id}
            className={`border rounded-3 p-3 ${className}`}
            style={{ scrollMarginTop: scrollMarginTop }}
        >
            <h6 id="d0bab59d-2818-43a3-9733-50225cc110c3">
                {t(
                    "settingsPage.profileSettings.profileAboutForm.updateAboutInfo"
                )}
            </h6>
            <hr id="f08a0497-7585-4752-9acc-7feef806af56" />
            <form
                id="db587f41-693d-4aab-9e32-5ac4020587bc"
                onSubmit={submitHandler}
            >
                <div
                    id="e7ff2d3e-9051-4618-a4a0-29d3ee38130c"
                    className="row mb-3"
                >
                    <div
                        id="fbe495e0-d040-4623-bc79-dad4ae2b06ad"
                        className="col"
                    >
                        <InputGroup
                            id="bdc6b983-3348-4152-af98-dee92da16044"
                            className="input-group-sm"
                            type="text"
                            label={t(
                                "settingsPage.profileSettings.profileAboutForm.headlineLabel"
                            )}
                            errorMsg={headlineInputErrorMsg}
                            onChange={headlineInputChangeHandler}
                            value={headline}
                            maxLength={MAX_HEADLINE_LENGTH}
                        />
                        <div
                            id="98f2fce8-a4e4-4ebd-9518-f8e48c194131"
                            className="form-text d-flex justify-content-end"
                        >
                            {headline
                                ? MAX_HEADLINE_LENGTH - headline.length
                                : MAX_HEADLINE_LENGTH}
                        </div>
                    </div>
                </div>
                <div
                    id="70002e0b-51c4-4bae-bfb9-aa8049d944ac"
                    className="row mb-3"
                >
                    <div
                        id="294c09dd-e37e-435c-ad5a-ff17e1024396"
                        className="col"
                    >
                        <Textarea
                            id="35670c07-43ae-426c-b252-f6cb95e95238"
                            className="form-control-sm"
                            resize={"block"}
                            rows={5}
                            errorMsg={descriptionTextareaErrorMsg}
                            label={t(
                                "settingsPage.profileSettings.profileAboutForm.descriptionLabel"
                            )}
                            onChange={descriptionTextareaChangeHandler}
                            value={description}
                            maxLength={MAX_DESCRIPTION_LENGTH}
                        />
                        <div
                            id="58e82778-0158-4669-942c-07ef03d7d4c9"
                            className="form-text d-flex justify-content-end"
                        >
                            {description
                                ? MAX_DESCRIPTION_LENGTH - description.length
                                : MAX_DESCRIPTION_LENGTH}
                        </div>
                    </div>
                </div>
                <Button
                    id="672a757a-2e69-4857-93c3-e62dfce9019d"
                    className="btn btn-sm btn-primary float-end"
                    type="submit"
                    disabled={isLoading || saveButtonDisabled}
                    isLoading={isLoading}
                >
                    {t("settingsPage.save")}
                </Button>
            </form>
        </div>
    );
};

export default ProfileAboutForm;
