import React, { ForwardedRef, TextareaHTMLAttributes } from "react";
import classes from "./Textarea.module.scss";
import { Property } from "csstype";

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    resize?: Property.Resize;
    errorMsg: string;
}
const Textarea = React.forwardRef(
    (
        { id, className, label, resize = "none", errorMsg, ...rest }: Props,
        ref: ForwardedRef<HTMLTextAreaElement>
    ) => {
        return (
            <div id={id} className="w-100">
                {label && (
                    <label
                        id="32455bfd-c1fa-4816-b6d5-6d781a0eaebe"
                        className="form-label"
                    >
                        {label}
                    </label>
                )}
                <textarea
                    id={id}
                    className={`${classes.textarea} form-control ${
                        !!errorMsg && "is-invalid"
                    } ${className}`}
                    style={{ resize: resize }}
                    ref={ref}
                    {...rest}
                />
                <div
                    id="49f0fa89-6abd-4f6f-8a6a-98c41e4e24f5"
                    className="invalid-feedback"
                >
                    {errorMsg}
                </div>
            </div>
        );
    }
);

export default Textarea;
