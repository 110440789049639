import LoginForm from "./LoginForm/LoginForm";
import { useMatch } from "react-router-dom";
import SignupForm from "./SignupForm/SignupForm";
import classes from "./AuthPage.module.scss";
import ForgotPasswordForm from "./ForgotPasswordForm/ForgotPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm/ResetPasswordForm";
import VerifyEmailPage from "../VerifyEmailPage/VerifyEmailPage";
import Footer from "../../components/Footer/Footer";
import { useContext, useEffect } from "react";
import ModalContext from "../../contexts/modal-context";

enum Page {
    LOGIN = "login",
    SIGN_UP = "signup",
    FORGOT_PASSWORD = "forgot-password",
    RESET_PASSWORD = "reset-password",
    VERIFY_EMAIL = "verify-email",
}

const AuthPage = () => {
    const match = useMatch("/:page");
    const page: Page = match?.params.page as Page;
    const { closeModal } = useContext(ModalContext);

    useEffect(() => {
        closeModal();
    }, [closeModal]);

    let content;
    switch (page) {
        case Page.LOGIN:
            content = <LoginForm id="9dabd7fb-4eef-43db-8821-50fefe57e634" />;
            break;
        case Page.SIGN_UP:
            content = <SignupForm id="a60b9524-f4b6-4b93-a5da-854cd927b002" />;
            break;
        case Page.FORGOT_PASSWORD:
            content = (
                <ForgotPasswordForm id="25a59983-c959-470d-bc50-e54e82066c52" />
            );
            break;
        case Page.RESET_PASSWORD:
            content = (
                <ResetPasswordForm id="e0f89425-af0a-4413-9ef5-bd4228329a53" />
            );
            break;
        case Page.VERIFY_EMAIL:
            content = (
                <VerifyEmailPage id="869edbe2-1dc3-4d70-a4d0-059163373756" />
            );
            break;
    }

    return (
        <div
            id="0a119a0e-c672-423a-8935-c975df12a82e"
            className="row d-flex flex-column align-items-center justify-content-between"
        >
            <div
                id="8ddfa494-a599-4589-b522-8f52480af893"
                className={`col ${classes.col}`}
            >
                <div
                    id="abe2c71c-f64e-4bf9-b397-42d745f913de"
                    className={`${classes.content} p-3 py-4`}
                >
                    {content}
                </div>
            </div>
            <Footer id="c952a652-74a8-42bd-9da2-08bf4cafc297" />
        </div>
    );
};

export default AuthPage;
