import User from "../../models/User";
import blank_profile_image from "../../images/blank-profile-picture.svg";
import { MdOutlineVerified } from "react-icons/md";
import React, { BaseHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { parseProfileImageSrc } from "../../utils/helpers";

interface Props extends BaseHTMLAttributes<HTMLDivElement> {
    user: User;
    showVerifiedMark?: boolean;
}

const UserBasicInfo = ({ user, showVerifiedMark = true, id }: Props) => {
    const { t } = useTranslation();
    let image_src: string = blank_profile_image;
    const image_url = user.profile_image;
    if (image_url) image_src = parseProfileImageSrc(image_url);

    return (
        <div id={id} className="d-flex align-items-center">
            <img
                id="483ced2d-cd7a-4866-8e06-17bb8065da15"
                src={image_src}
                alt="profile"
                width="45"
                height="45"
                className="rounded-circle me-2"
            />
            <div
                id="23f981eb-5fed-487b-bb24-e814135986e0"
                className="d-flex flex-column"
            >
                <div
                    id="093487ac-ef35-4f9e-9fa0-dae025522ae7"
                    className="d-flex align-items-center gap-1"
                >
                    <div
                        id="fa44df37-abf6-4ac4-8cd9-07eb24ca2f53"
                        className="d-flex flex-wrap gap-1 form-text text-black fw-bolder"
                    >
                        <span id="5745b35c-be89-499d-8bb4-ae2d40b7f183">
                            {user.name}
                        </span>
                    </div>
                    {user.is_account_verified && showVerifiedMark && (
                        <MdOutlineVerified
                            id="0cda8b38-e406-4204-b9db-392edd1ea4b1"
                            className="text-primary"
                            style={{ marginTop: "0.2rem" }}
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title={t("userBasicInfo.verifiedAccount")}
                        />
                    )}
                    {user.is_placeholder && showVerifiedMark && (
                        <MdOutlineVerified
                            id="a66d3adf-fc01-4164-877f-dde21c47e600"
                            className="text-secondary"
                            style={{ marginTop: "0.2rem" }}
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title={t(
                                "userBasicInfo.verifiedPlaceholderAccount"
                            )}
                        />
                    )}
                </div>
                <span
                    id="ab3cd1c6-62b4-467d-925a-87dd4d7b76f1"
                    className="form-text mt-0"
                >
                    @{user.username}
                </span>
            </div>
        </div>
    );
};

export default UserBasicInfo;
