import { Link } from "react-router-dom";
import { CiLogout, CiSettings } from "react-icons/ci";
import { VscVerified } from "react-icons/vsc";

import { HiOutlineBars3 } from "react-icons/hi2";
import { useContext } from "react";
import authContext from "../../../contexts/auth-context";
import { useTranslation } from "react-i18next";

type Props = {
    className?: string;
};
const HeaderDropdown = ({ className }: Props) => {
    const { t } = useTranslation();
    const authCtx = useContext(authContext);
    const signOutHandler = () => {
        authCtx.logout();
    };

    return (
        <div
            id="767d89ff-3c6e-4220-8aea-6f5428871185"
            className={`dropdown ${className}`}
        >
            <div
                id="38cd3111-a6d9-44e2-8869-56f3d96f5d2f"
                className="btn btn-link d-flex align-items-center justify-content-between link-dark text-decoration-none"
                data-bs-toggle="dropdown"
            >
                <HiOutlineBars3
                    id="38cd3111-a6d9-44e2-8869-56f3d96f5d2f-icon"
                    className="mt-1 text-dark fs-4"
                />
            </div>
            <ul
                id="2b3ce04a-7bdb-41a6-b8c7-db3684786d16"
                className="dropdown-menu"
            >
                {!authCtx.user?.is_creator && (
                    <li id="b4bc4c99-ab94-4307-abc9-9e3f5fe1c3da">
                        <Link
                            // id="header-to-settings-account_become-creator"
                            id="6278b0b9-637e-4c41-861a-b18a2ccbc248"
                            to="/settings/account?sc=become-creator"
                            className="dropdown-item py-2"
                        >
                            <VscVerified
                                id="6278b0b9-637e-4c41-861a-b18a2ccbc248-icon"
                                className="mb-1 me-2"
                            />
                            {t("header.becomeCreator")}
                        </Link>
                    </li>
                )}
                <li id="fdcad760-738c-453e-aca1-292afefc9e1e">
                    <Link
                        // id="header-to-settings"
                        id="a8dd34d9-bd7d-4c0e-8567-d514a6df8175"
                        to="/settings"
                        className="dropdown-item py-2"
                    >
                        <CiSettings
                            id="a8dd34d9-bd7d-4c0e-8567-d514a6df8175-icon"
                            className="mb-1 me-2"
                        />
                        {t("header.settings")}
                    </Link>
                </li>
                <li id="1fcb6c81-8f39-4a8b-b677-831be316e366">
                    <hr
                        id="ccf7e27a-c517-4fde-844c-89ad65a48738"
                        className="dropdown-divider"
                    />
                </li>
                <li id="6fc5c91c-c9c5-4eca-84c1-7e16c43a9f7f">
                    <button
                        id="ef4a7615-db6f-4f22-994f-ec01391254cd"
                        className="dropdown-item"
                        onClick={signOutHandler}
                    >
                        <CiLogout
                            id="ef4a7615-db6f-4f22-994f-ec01391254cd-icon"
                            className="mb-1 me-2"
                        />
                        {t("header.signOut")}
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default HeaderDropdown;
