import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import Petition from "../../../../models/Petition";
import React, {
    HTMLAttributes,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import PetitionSupporter from "../../../../models/PetitionSupporter";
import { listPetitionSupporters as api_listPetitionSupporters } from "../../../../api/petitions/listPetitionSupporters";
import Spinner from "../../../UI/Spinner/Spinner";
import ModalContext from "../../../../contexts/modal-context";
import Button from "../../../UI/Button/Button";
import { parseCursor } from "../../../../utils/helpers";
import PetitionSupportersList from "../../../PetitionSupportersList/PetitionSupportersList";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {
    petition: Petition;
}

const PetitionSupportersListOverlay = ({ id, petition }: Props) => {
    const { t } = useTranslation();
    const { closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(true);
    const [supporters, setSupporters] = useState<PetitionSupporter[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getPetitionSupporters = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!hasMore) return;
            setIsLoading(true);
            try {
                const response = await api_listPetitionSupporters(
                    petition.uuid,
                    cursor
                );
                if (cursor === undefined) setSupporters(response.results);
                else
                    setSupporters((prev: PetitionSupporter[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [petition.uuid]
    );

    useEffect(() => {
        setSupporters([]);
        setCursor(undefined);
        setHasMore(true);
        getPetitionSupporters(undefined, true);
    }, [getPetitionSupporters]);

    return (
        <ModalOverlay id={id}>
            <h6 id="24ba2237-8154-469c-8c81-2e304c5abfc9">
                {t("modal.overlays.petitionSupportersList.supporters")}
            </h6>
            <hr id="29edc60a-a0c1-4c6a-86c9-283876406a27" />
            {!!supporters && (
                <PetitionSupportersList
                    id="4afd08af-30d3-4f9e-a82c-0d7134fd00a8"
                    supporters={supporters}
                    showPledgeAmount={petition.accepts_pledges}
                    getPetitionSupporters={() =>
                        getPetitionSupporters(cursor, hasMore)
                    }
                    isLoading={isLoading}
                />
            )}
            {isLoading && <Spinner className="mt-3" />}
            <hr />
            <div className="d-flex justify-content-end">
                <Button className="btn btn-sm btn-dark" onClick={closeModal}>
                    {t("modal.close")}
                </Button>
            </div>
        </ModalOverlay>
    );
};

export default PetitionSupportersListOverlay;
