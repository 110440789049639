import React, { HTMLAttributes, useContext, useState } from "react";
import Button from "../../../../../components/UI/Button/Button";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../../contexts/modal-context";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {
    isLoading: boolean;
    updateAccount: (currency: string) => void;
}

const CurrencySettings = ({ id, updateAccount, isLoading }: Props) => {
    const { t } = useTranslation();
    const { showModal } = useContext(ModalContext);
    const [currency, setCurrency] = useState<string | undefined>();
    const [errorMsg, setErrorMsg] = useState<string>();

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!!currency) {
            showModal(
                <AlertOverlay
                    id="dd312a1e-e96c-483a-8f95-346e4f37c705"
                    status="warning"
                    message={`${t(
                        "settingsPage.accountSettings.currencySettings.setCurrencyWarning",
                        { currency: currency.toUpperCase() }
                    )}`}
                    onConfirm={updateCurrency}
                />
            );
        } else
            setErrorMsg(
                t(
                    "settingsPage.accountSettings.currencySettings.pleaseSelectCurrency"
                )
            );
    };

    const updateCurrency = () => {
        updateAccount(currency!);
    };

    const changeHandler = (event: React.FormEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setErrorMsg(undefined);
        const val = event.currentTarget.value;
        if (!!val) setCurrency(val);
        else setCurrency(undefined);
    };

    return (
        <div id={id}>
            <h6 id="7c74ec4c-2674-4d19-b218-2f54a18bfc05">
                {t(
                    "settingsPage.accountSettings.currencySettings.setBalanceCurrency"
                )}
            </h6>
            <hr id="0a8d8deb-9fb5-4dc8-897d-463652552e7e" />
            <form
                id="6cc3d005-83fa-4fe1-8b3e-4f427bad25c2"
                onSubmit={submitHandler}
            >
                <div
                    id="39303bd4-d9f6-47cf-994a-347f68ffb6dd"
                    className="input-group input-group-sm has-validation"
                >
                    <select
                        id="32e1d455-2635-4e06-844f-d6ab288f1df9"
                        className={`form-select ${!!errorMsg && "is-invalid"}`}
                        onChange={changeHandler}
                    >
                        <option
                            id="6259fd1e-f9fd-4f57-a05b-e371d84e63cd"
                            value=""
                        >
                            {t(
                                "settingsPage.accountSettings.currencySettings.selectCurrency"
                            )}
                        </option>
                        <option
                            id="d52198d4-61a9-4854-b575-3372e8af4d16"
                            value="eur"
                        >
                            EUR
                        </option>
                        <option
                            id="8d71aeed-1c8a-4b77-b492-582d426caef6"
                            value="gbp"
                        >
                            GBP
                        </option>
                        <option
                            id="59441dcb-0974-4633-9832-1d7e70723332"
                            value="usd"
                        >
                            USD
                        </option>
                    </select>
                    <Button
                        id="a80cafd7-aa11-4def-8116-03808e7de3c2"
                        className="btn btn-sm btn-dark"
                        type="submit"
                        isLoading={isLoading}
                        onlySpinner={true}
                    >
                        {t("settingsPage.save")}
                    </Button>
                    <div
                        id="e48372f9-658c-46bd-85d2-050566533c98"
                        className="invalid-feedback"
                    >
                        {errorMsg}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CurrencySettings;
