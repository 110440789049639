import React from "react";
import { Link } from "react-router-dom";

const HowToSetUpCreatorAccountPage = () => {
    return (
        <div id="2ad7ea5d-b4bf-4305-ab29-82a71300bb0e">
            <h2 id="513bfdbf-1c92-4129-9114-c47c61ee0654" className="mb-4">
                How to set up a creator account
            </h2>
            <p id="f10e0e41-4e95-4f0a-92ad-fed21dae371d">
                Please take the following steps to set up your creator account
                on Ablebees:
            </p>
            <ul
                id="6b1de7ee-282f-4895-986a-32c7a4add8ce"
                className="d-flex flex-column gap-3"
            >
                <li id="f9ecc3e8-34bd-4ca3-a7cf-51b272718ed7">
                    <p id="e01dbdab-0711-44ba-8aa2-eb9080dd5bad">
                        Sign up as a regular user.
                    </p>
                </li>
                <li id="5511f845-a4bb-436f-9658-458ca0470966">
                    <p id="c8287052-5cb3-47ae-9244-c2e114d822c7">
                        Go to{" "}
                        <Link
                            // id="how-to-set-up-creator-account-page-to-settings-account_become-creator"
                            id="1c65d195-197e-44e1-b077-521fb537c7f2"
                            to="/settings/account?sc=become-creator"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            here
                        </Link>
                        , provide the URL to your external profile where you
                        post your content (e.g., YouTube channel url, Medium
                        profile url, etc.), and request to switch to a creator
                        account.
                    </p>
                    <p id="ae285f6a-f822-4b9a-99bd-23d4c957f087">
                        Once we receive your request, we will check whether your
                        external profile meets our requirements, verify your
                        ownership of that profile and link it to your Ablebees
                        account. You will get an email notification at each step
                        of the request review process.
                    </p>
                </li>
                <li id="a9e167a0-212c-4923-91a3-f5a65e101caa">
                    <p id="de3453a7-5018-4281-ac89-16f81ba113fe">
                        Once your request to switch to a creator account is
                        approved and your ownership of the external profile is
                        verified, go to{" "}
                        <Link
                            // id="how-to-set-up-creator-account-page-to-settings-payout"
                            id="9ab5bb98-ecdd-4f6a-884f-34bab620012f"
                            to="/settings/payout"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            here
                        </Link>{" "}
                        and set up your payout method.
                    </p>
                    <p id="efaa6851-f3dd-44fc-bbf6-9fc016171eff">
                        You will be redirected to the Stripe website to set up
                        your payout method. When you are asked to enter your
                        email address, please ignore the{" "}
                        <i id="e6a552a7-8178-44b2-ac50-ef31376b8760">
                            "Have a Stripe account? You can use the same email."
                        </i>{" "}
                        message. You do not need to have a Stripe account. You
                        may type the email address you used to sign up on
                        Ablebees and proceed to the next step.
                    </p>
                </li>
                <li id="cdd10381-f382-49ae-9e1c-0643ad704cbc">
                    <p id="31662f79-f048-4282-90d9-ab9a7671bb74">
                        Once your payout method is set up, you are ready to let
                        your subscribers know about this new opportunity you
                        offer them on Ablebees and share your personal
                        invitation link with them. You can find the invitation
                        link{" "}
                        <Link
                            // id="how-to-set-up-creator-account-page-to-settings-profile_invitation"
                            id="63d65496-a33b-43c5-8754-2d8a4fcfe893"
                            to="/settings/profile?sc=invitation"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            here
                        </Link>
                        .
                    </p>
                    <p id="674441ae-d17f-4ae0-aaef-a7e9989ff6a1">
                        Those who sign up using your invitation link will
                        automatically become your followers on Ablebees.
                        Whenever you receive a petition, all your followers on
                        Ablebees will get an email notification inviting them to
                        support the petition.
                    </p>
                    <p id="44782097-e61b-46c7-b338-931eaaf2d4e7">
                        Your invitation link contains your username, so please
                        make sure you are happy with the username before you
                        share your link. You can view and modify your username{" "}
                        <Link
                            // id="how-to-set-up-creator-account-page-to-settings-profile"
                            id="43366d17-28c8-4646-8945-5c7cdc037abc"
                            to="/settings/profile"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            here
                        </Link>
                        .
                    </p>
                </li>
                <li id="79b39934-148e-430f-995a-62ea0177201e">
                    <p id="01f76c4e-05dd-4fc3-a97f-9e47a820b4ec">
                        Lastly, please also add some information about yourself
                        to help users who do not know you understand what kind
                        of content you make. You can add the information from{" "}
                        <Link
                            // id="how-to-set-up-creator-account-page-to-settings-profile_about"
                            id="840334ca-106b-4856-a57e-9af414d173e9"
                            to="/settings/profile?sc=about"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            here
                        </Link>
                        .
                    </p>
                </li>
            </ul>
            <p id="f563661e-6b69-4b93-9c87-695cda0a9f0a">
                If you have any questions or encounter any problems with the
                creator account setup process, please let us know at
                support@ablebees.com.
            </p>
        </div>
    );
};

export default HowToSetUpCreatorAccountPage;
