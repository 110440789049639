import React, { HTMLAttributes, Ref, useCallback, useRef } from "react";
import Notification from "../../../models/Notification";
import NotificationsListItem from "./NotificationsListItem/NotificationsListItem";

interface Props extends HTMLAttributes<any> {
    notifications: Notification[];
    getNotifications: () => void;
    isLoading: boolean;
}

const NotificationsList = ({
    notifications,
    getNotifications,
    isLoading,
    className,
    id,
}: Props) => {
    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback(
        (node: Element) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => {
                    if (entries[0].isIntersecting) {
                        getNotifications();
                    }
                }
            );
            if (node) observer.current.observe(node);
        },
        [isLoading, getNotifications]
    );

    const renderedNotificationsList = notifications.map(
        (item: Notification, index: number) => (
            <NotificationsListItem
                id={`notification-${item.uuid}`}
                key={item.uuid}
                notification={item}
                ref={
                    notifications.length === index + 1
                        ? (lastElementRef as Ref<HTMLDivElement>)
                        : undefined
                }
            />
        )
    );

    return (
        <div id={id} className={`${className} d-flex flex-column gap-1`}>
            {renderedNotificationsList}
        </div>
    );
};

export default NotificationsList;
