import axios from "../axiosConfig";
import Petition from "../../models/Petition";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";
import { PledgeSourceTypes } from "../../components/SelectPledgeSourceForm/SelectPledgeSourceForm";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        pledge_amount?: string[];
        non_field_errors?: string[];
        error?: string | string[];
    };
};

export const supportPetition = async (
    petition_uuid: string,
    pledge_amount: number,
    pledge_source: string = PledgeSourceTypes.BALANCE
): Promise<Petition> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.post(
            `/petitions/${petition_uuid}/supporters`,
            {
                pledge_amount,
                pledge_source,
            },
            {
                headers: {
                    Authorization: token ? `Token ${token}` : "",
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        return new Petition(response.data);
    } catch (error: any) {
        throw error.response.data;
    }
};
