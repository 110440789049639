import React, { HTMLAttributes } from "react";
import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import RatePetitionEndResultForm from "./RatePetitionEndResultForm/RatePetitionEndResultForm";
import Petition from "../../../../models/Petition";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {
    petition: Petition;
    onRate: (petition: Petition) => void;
}

const RatePetitionEndResultOverlay = ({ petition, onRate, id }: Props) => {
    const { t } = useTranslation();
    return (
        <ModalOverlay id={id}>
            <h6 id="177bc6f9-584c-4252-a6ce-9a9b8d8da2cc">
                {t("modal.overlays.rateEndResult.rateEndResult")}
            </h6>
            <hr id="f779ad16-95c1-45b3-b24e-668fe1370cf0" />
            <RatePetitionEndResultForm
                id="c74028f0-a641-4406-a817-9a6f7fcd50bb"
                petition={petition}
                onRate={onRate}
            />
        </ModalOverlay>
    );
};

export default RatePetitionEndResultOverlay;
