import React, { ForwardedRef, InputHTMLAttributes } from "react";

interface Props extends InputHTMLAttributes<HTMLSelectElement> {
    errorMsg: string;
}
const Select = React.forwardRef(
    (
        { id, errorMsg, className, children, ...rest }: Props,
        ref: ForwardedRef<HTMLSelectElement>
    ) => {
        return (
            <div id={id} className="w-100">
                <select
                    id="3899d060-ed61-4566-a3c9-5577e46fa0be"
                    className={`form-select ${
                        !!errorMsg && "is-invalid"
                    } ${className}`}
                    ref={ref}
                    {...rest}
                >
                    {children}
                </select>
                <div
                    id="82a1e3a2-2b5b-4a4c-a656-4fd7ce9074e6"
                    className="invalid-feedback"
                >
                    {errorMsg}
                </div>
            </div>
        );
    }
);

export default Select;
