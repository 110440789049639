import User from "../../../models/User";
import {
    FailureResponse,
    followUser as api_followUser,
} from "../../../api/users/followUser";
import { unfollowUser as api_unfollowUser } from "../../../api/users/unfollowUser";
import React, {
    ForwardedRef,
    HTMLAttributes,
    MouseEvent,
    useContext,
    useState,
} from "react";
import UserBasicInfo from "../../UserBasicInfo/UserBasicInfo";
import Button from "../../UI/Button/Button";
import AlertOverlay from "../../Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../contexts/modal-context";
import { ApiErrorCode } from "../../../constants";
import EmailNotVerifiedErrorOverlay from "../../Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import { useTranslation } from "react-i18next";
import classes from "../UsersList.module.scss";
import { Link } from "react-router-dom";

interface Props extends HTMLAttributes<any> {
    user: User;
    showFollowButton?: boolean;
    showVerifiedMark?: boolean;
    clickHandler?: (user: User) => void;
}

const UsersListItem = React.forwardRef(
    (
        {
            id,
            user,
            showFollowButton = true,
            showVerifiedMark = true,
            clickHandler,
        }: Props,
        ref: ForwardedRef<any>
    ) => {
        const { t } = useTranslation();
        const { showModal } = useContext(ModalContext);
        const [isFollowing, setIsFollowing] = useState(
            user && user.am_i_follower
        );

        const followButtonClickHandler = async (
            event: MouseEvent<HTMLButtonElement>
        ) => {
            event.preventDefault();
            try {
                if (isFollowing) await api_unfollowUser((user as User).uuid);
                else await api_followUser((user as User).uuid);
                setIsFollowing((prevState: boolean) => !prevState);
            } catch (error) {
                const { errors, status_code, code } = error as FailureResponse;
                if (errors) {
                    if (status_code === 403) {
                        if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                            showModal(
                                <EmailNotVerifiedErrorOverlay
                                    id="b08ce68b-be74-4081-a652-6ccc7198ae3d"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                        } else
                            showModal(
                                <AlertOverlay
                                    id="07d07f47-e6c7-4905-bf04-755217e4b052"
                                    status="error"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                    } else console.log(errors);
                }
            }
        };

        let followButton = <></>;
        if (showFollowButton && user instanceof User && !user.is_myself) {
            if (isFollowing) {
                followButton = (
                    <Button
                        id="d3618cc5-7ec4-4a0e-91ec-82ba33835220"
                        className="btn btn-sm btn-secondary"
                        onClick={followButtonClickHandler}
                        title={t("profilePage.header.unfollowBtn")}
                    >
                        {t("profilePage.header.followingBtn")}
                    </Button>
                );
            } else {
                followButton = (
                    <Button
                        id="2b9b9d28-33c0-4b63-b568-241ae68271ce"
                        className="btn btn-sm btn-primary"
                        onClick={followButtonClickHandler}
                        title={t("profilePage.header.followBtn")}
                    >
                        {t("profilePage.header.followBtn")}
                    </Button>
                );
            }
        }

        const content = (
            <>
                <UserBasicInfo
                    id={id}
                    user={user}
                    showVerifiedMark={showVerifiedMark}
                />
                {showFollowButton && followButton}
            </>
        );

        if (clickHandler) {
            return (
                <div
                    id={id}
                    key={user.uuid}
                    className={`${classes.user} d-flex align-items-center justify-content-between px-2 py-3 border rounded`}
                    onClick={() => clickHandler(user)}
                    ref={ref}
                >
                    {content}
                </div>
            );
        } else {
            return (
                <Link
                    // id={`users-list-to-users-${user.username}`}
                    id={id}
                    key={(user as User).uuid}
                    to={`/users/${user.username}`}
                    className={`${classes.user} text-decoration-none text-dark d-flex align-items-center justify-content-between px-2 py-3 border rounded`}
                    ref={ref}
                >
                    {content}
                </Link>
            );
        }
    }
);

export default UsersListItem;
