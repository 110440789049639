import InputGroup from "../../../../../components/UI/InputGroup/InputGroup";
import React, {
    ChangeEvent,
    HTMLAttributes,
    useContext,
    useEffect,
    useState,
} from "react";
import AuthContext from "../../../../../contexts/auth-context";
import {
    FailureResponse as UpdateUserFailureResponse,
    updateUser as api_updateUser,
} from "../../../../../api/users/updateUser";
import Button from "../../../../../components/UI/Button/Button";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../../contexts/modal-context";
import { ApiErrorCode } from "../../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "../../../SettingsPage";

interface Props extends HTMLAttributes<any> {}

const ProfileInfoForm = ({ id, className }: Props) => {
    const { t } = useTranslation();
    const { scrollMarginTop } = useOutletContext<OutletContextType>();
    const { showModal } = useContext(ModalContext);
    const { user, refreshUser, storeUser } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState(user?.name);
    const [nameInputErrorMsg, setNameInputErrorMsg] = useState("");
    const [username, setUsername] = useState(user?.username);
    const [usernameInputErrorMsg, setUsernameInputErrorMsg] = useState("");

    let saveButtonDisabled = true;

    useEffect(() => {
        refreshUser();
    }, [refreshUser]);

    const checkSaveButtonState = () => {
        if (name !== user?.name || username !== user?.username) {
            saveButtonDisabled = false;
        } else {
            saveButtonDisabled = true;
        }
    };

    checkSaveButtonState();

    const nameInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.value !== user?.name)
            saveButtonDisabled = false;
        setName(event.currentTarget.value);
        setNameInputErrorMsg("");
        checkSaveButtonState();
    };

    const usernameInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setUsername(event.currentTarget.value);
        setUsernameInputErrorMsg("");
        checkSaveButtonState();
    };

    const isNameValid = () => {
        if (!name) {
            setNameInputErrorMsg(
                t(
                    "settingsPage.profileSettings.profileInfoForm.pleaseEnterName"
                )
            );
            return false;
        }
        return true;
    };

    const isUsernameValid = () => {
        if (!username) {
            setUsernameInputErrorMsg(
                t(
                    "settingsPage.profileSettings.profileInfoForm.pleaseEnterUsername"
                )
            );
            return false;
        }
        return true;
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (isNameValid() && isUsernameValid()) {
            if (username !== user?.username) {
                showModal(
                    <AlertOverlay
                        id="0e03ec97-9fe0-457e-a3bb-45d1dce573ae"
                        status="warning"
                        message={t(
                            "settingsPage.profileSettings.profileInfoForm.invitationLinkWillAlsoBeUpdated"
                        )}
                        onConfirm={updateProfileInfo}
                    />
                );
            } else {
                await updateProfileInfo();
            }
        }
    };

    const updateProfileInfo = async () => {
        try {
            setIsLoading(true);
            const response = await api_updateUser(name, username);
            storeUser(response);
            saveButtonDisabled = true;
        } catch (error) {
            const { errors, status_code, code } =
                error as UpdateUserFailureResponse;

            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                id="d309bf4f-d7e8-43e7-9d2e-a8df2fd1a55f"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                id="a37a177f-455c-438a-a63a-4fedce84b6b4"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else {
                    if (errors.name) setNameInputErrorMsg(errors.name[0]);
                    if (errors.username)
                        setUsernameInputErrorMsg(errors.username[0]);
                }
            }
        }
        setIsLoading(false);
    };

    return (
        <div
            id={id}
            className={`border rounded-3 p-3 ${className}`}
            style={{ scrollMarginTop: scrollMarginTop }}
        >
            <h6 id="038f0415-3be5-473d-8fa3-c2b8b75778fa">
                {t(
                    "settingsPage.profileSettings.profileInfoForm.updateBasicInfo"
                )}
            </h6>
            <hr id="5e910c8d-c9a2-405c-974c-75f1656c482a" />
            <form
                id="3323f919-dda4-4d43-b8ef-535b14a798fe"
                onSubmit={submitHandler}
            >
                <div
                    id="eda653e9-d14a-4cf1-a759-2f6d42304e7f"
                    className="row mb-3"
                >
                    <div
                        id="c64f405f-4665-4e9e-a7de-0b2ab75bb679"
                        className="col"
                    >
                        <InputGroup
                            id="b55746e5-d5e2-4e33-93ac-3907b49ed2e5"
                            className="input-group-sm"
                            type="text"
                            label={t(
                                "settingsPage.profileSettings.profileInfoForm.name"
                            )}
                            errorMsg={nameInputErrorMsg}
                            onChange={nameInputChangeHandler}
                            value={name}
                        />
                    </div>
                </div>
                <div
                    id="2a7dbcee-d1c0-4343-80ed-948d483c7944"
                    className="row mb-3"
                >
                    <div
                        id="c3a8ba64-7f0a-4f24-9218-01528b42911f"
                        className="col"
                    >
                        <InputGroup
                            id="0b32c049-412f-465d-9e9f-56bb45dd5e1c"
                            className="input-group-sm"
                            type="text"
                            label={t(
                                "settingsPage.profileSettings.profileInfoForm.username"
                            )}
                            errorMsg={usernameInputErrorMsg}
                            onChange={usernameInputChangeHandler}
                            value={username}
                        />
                    </div>
                </div>
                <Button
                    id="eddeb4b7-7e93-4133-82c6-06c41cc14766"
                    className="btn btn-sm btn-primary float-end"
                    type="submit"
                    disabled={isLoading || saveButtonDisabled}
                    isLoading={isLoading}
                >
                    {t("settingsPage.save")}
                </Button>
            </form>
        </div>
    );
};

export default ProfileInfoForm;
