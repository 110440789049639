import { SlPencil } from "react-icons/sl";
import { CiGlobe } from "react-icons/ci";
import { MdOutlineVerified } from "react-icons/md";

import { Link, useNavigate } from "react-router-dom";
import User from "../../../models/User";
import blank_profile_image from "../../../images/blank-profile-picture.svg";
import {
    FailureResponse,
    followUser as api_followUser,
} from "../../../api/users/followUser";
import { unfollowUser as api_unfollowUser } from "../../../api/users/unfollowUser";
import React, { HTMLAttributes, useContext, useEffect, useState } from "react";
import Button from "../../../components/UI/Button/Button";
import AlertOverlay from "../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../contexts/modal-context";
import { ApiErrorCode } from "../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import { Trans, useTranslation } from "react-i18next";
import { parseProfileImageSrc } from "../../../utils/helpers";
import ExternalLink from "../../../models/ExternalLink";

interface Props extends HTMLAttributes<any> {
    user: User;
}

const ProfilePageHeader = ({ id, user }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const [isFollowing, setIsFollowing] = useState(user.am_i_follower);

    useEffect(() => {
        setIsFollowing(user.am_i_follower);
        const af = new URLSearchParams(window.location.search).get("af");
        if (!user.is_myself && !user.am_i_follower && af) {
            showModal(
                <AlertOverlay
                    id="5be8ec45-14b6-4fef-a3d5-501e69681402"
                    status="info"
                    message={
                        <Trans
                            id="2295491d-f197-4329-952b-ebfe6b0ffecc"
                            i18nKey="modal.wouldLikeToFollow"
                            components={[
                                <strong id="4f85ab4e-c639-4cc4-9c26-f44a180abdb0" />,
                            ]}
                            values={{ name: user.name }}
                        />
                    }
                    onConfirm={async () => {
                        await followUnfollowUser();
                    }}
                    confirmButtonText={t("profilePage.header.followBtn")}
                    closeButtonText={t("modal.cancel")}
                />
            );
        }
    }, [user]);

    const followUnfollowUser = async () => {
        try {
            if (isFollowing) await api_unfollowUser(user.uuid);
            else await api_followUser(user.uuid);
            setIsFollowing((prevState: boolean) => !prevState);
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                id="c16bfa07-a7e2-47e6-8cd6-03e58a0b654a"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                id="de72038e-5ced-41f5-b514-decf21c47132"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else console.log(errors);
            }
        }
    };

    const followButtonClickHandler = async () => {
        await followUnfollowUser();
    };

    let followButton;
    if (isFollowing) {
        followButton = (
            <Button
                id="7adfadfe-3a19-4ef3-88b2-4e8d7707a4f4"
                className="btn btn-sm btn-dark"
                style={{ width: "9em" }}
                onClick={followButtonClickHandler}
                title={t("profilePage.header.unfollowBtn")}
            >
                {t("profilePage.header.followingBtn")}
            </Button>
        );
    } else {
        followButton = (
            <Button
                id="76f2f60c-7e20-4935-a8f0-28974247b79f"
                className="btn btn-sm btn-primary"
                style={{ width: "9em" }}
                onClick={followButtonClickHandler}
                title={t("profilePage.header.followBtn")}
            >
                {t("profilePage.header.followBtn")}
            </Button>
        );
    }

    let image_src: string = blank_profile_image;
    if (user.profile_image)
        image_src = parseProfileImageSrc(user.profile_image);

    return (
        <div id={id}>
            <div
                id="8dbc0cd2-f1d7-423c-bd34-e494ec488033"
                className="d-flex justify-content-between border-bottom pb-3"
            >
                <div
                    id="b3e88b13-fc73-47b5-85bb-96896228f302"
                    className="d-flex gap-3"
                >
                    <div
                        id="b52da405-22df-457e-8fdf-92b08ebad771"
                        className="d-flex flex-column gap-2 align-items-center"
                        style={{ maxWidth: "6em" }}
                    >
                        <img
                            id="4f0c7273-2785-4953-98bf-5e276cbf3688"
                            src={image_src}
                            alt="profile"
                            width="100"
                            height="100"
                            className="rounded-circle border border-1"
                        />
                        {user.is_creator && user.profile!.external_links && (
                            <div
                                id="abcc1b20-cc24-4f1c-be42-6d6e42671189"
                                className="d-flex flex-wrap gap-1 align-items-center justify-content-between"
                            >
                                {user
                                    .profile!.external_links.filter(
                                        (link: ExternalLink) => link.verified
                                    )
                                    .map((link: ExternalLink) => {
                                        const icon =
                                            link.type.name === "Other" ? (
                                                <CiGlobe
                                                    id="c2356046-6885-4014-91b5-36b4c30558b1-icon"
                                                    size={20}
                                                />
                                            ) : (
                                                <img
                                                    id="c2356046-6885-4014-91b5-36b4c30558b1-icon"
                                                    src={link.type.icon_url}
                                                    height={20}
                                                    alt={link.type.name}
                                                />
                                            );
                                        return (
                                            <a
                                                id="c2356046-6885-4014-91b5-36b4c30558b1"
                                                key={link.type.name}
                                                href={link.url}
                                                target="_blank"
                                                rel="noreferrer"
                                                title={link.type.name}
                                                className="text-dark"
                                            >
                                                {icon}
                                            </a>
                                        );
                                    })}
                            </div>
                        )}
                    </div>
                    <div
                        id="8465a1a1-e0a5-4930-b91e-7fedb899459d"
                        className="d-flex gap-1 flex-column"
                    >
                        <div id="6a8ed8aa-8dd5-4250-a6c5-d8817ff62ffd">
                            <div
                                id="5fafb4b3-0c28-441a-bdcc-fc0cd3ace66b"
                                className="d-flex align-items-center gap-1"
                            >
                                <div
                                    id="2d935d18-706d-4ccf-9b1e-fef1e7aef685"
                                    className="d-flex flex-wrap gap-1 fs-5 fw-bold"
                                >
                                    <span id="d7fc38a3-0da2-4b3c-9271-db59c7d0afaa">
                                        {user.name}
                                    </span>
                                </div>
                                {user.is_account_verified && (
                                    <MdOutlineVerified
                                        id="f1822fb9-f285-4a5b-96a5-7b5b807a149e"
                                        className="text-primary"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={t(
                                            "userBasicInfo.verifiedAccount"
                                        )}
                                    />
                                )}
                                {user.is_placeholder && (
                                    <MdOutlineVerified
                                        id="8263ac6c-9cc8-4992-955e-9bf877a537cf"
                                        className="text-secondary"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={t(
                                            "userBasicInfo.verifiedPlaceholderAccount"
                                        )}
                                    />
                                )}
                            </div>
                            <div
                                id="06ea016f-f4df-4d3a-9357-c505f908cf18"
                                className=""
                            >
                                @{user.username}
                            </div>
                        </div>
                        <div
                            id="5269cf60-93a1-4834-88bb-d20ca9b8afd9"
                            className="d-flex gap-3"
                        >
                            <Link
                                // id="profile-page-header-to-followers"
                                id="8d010630-089d-4648-8770-ed179a2cc854"
                                to="followers"
                                className="btn btn-link text-decoration-none text-black d-flex align-items-center gap-1 px-0"
                                title={t("profilePage.header.followers")}
                            >
                                <div
                                    id="8d010630-089d-4648-8770-ed179a2cc854-div"
                                    className="d-flex flex-column"
                                >
                                    <span
                                        id="8d010630-089d-4648-8770-ed179a2cc854-count"
                                        className="fw-bold"
                                    >
                                        {user.profile!.followers_count}
                                    </span>
                                    <span id="8d010630-089d-4648-8770-ed179a2cc854-text">
                                        {t(
                                            "profilePage.header.followers"
                                        ).toLowerCase()}
                                    </span>
                                </div>
                            </Link>
                            <Link
                                // id="profile-page-header-to-following"
                                id="5347b7e0-0b73-4d33-9d8f-58680e2f0638"
                                to="following"
                                className="btn btn-link text-decoration-none text-black d-flex align-items-center gap-1 px-0"
                                title={t("profilePage.header.following")}
                            >
                                <div
                                    id="5347b7e0-0b73-4d33-9d8f-58680e2f0638-div"
                                    className="d-flex flex-column"
                                >
                                    <span
                                        id="5347b7e0-0b73-4d33-9d8f-58680e2f0638-count"
                                        className="fw-bold"
                                    >
                                        {user.profile!.following_count}
                                    </span>
                                    <span id="5347b7e0-0b73-4d33-9d8f-58680e2f0638-text">
                                        {t(
                                            "profilePage.header.following"
                                        ).toLowerCase()}
                                    </span>
                                </div>
                            </Link>
                        </div>
                        {user.is_creator &&
                            (user.is_myself ||
                                user.profile!.headline ||
                                user.profile!.description) && (
                                <div
                                    id="bb93907c-a689-45fd-b771-6bedd7c1dfdc"
                                    className="d-flex align-items-center gap-2 mb-2"
                                >
                                    {user.profile!.headline && (
                                        <p
                                            id="9702286d-e810-4071-83d1-c36b3d62d2f9"
                                            className="m-0 p-0"
                                        >
                                            {user.profile!.headline}
                                        </p>
                                    )}
                                    {user.is_myself &&
                                        !user.profile!.headline && (
                                            <Link
                                                // id="profile-page-header-to-settings-profile_about"
                                                id="6d2f7c88-dd3a-4094-ae75-121b4d01f264"
                                                to="/settings/profile?sc=about"
                                                className="btn btn-link text-decoration-none text-danger d-flex gap-2 align-items-center p-0"
                                            >
                                                <span id="6d2f7c88-dd3a-4094-ae75-121b4d01f264-text">
                                                    {t(
                                                        "profilePage.header.pleaseAddHeadline"
                                                    )}
                                                </span>
                                                <SlPencil id="6d2f7c88-dd3a-4094-ae75-121b4d01f264-icon" />
                                            </Link>
                                        )}
                                    {user.profile!.description && (
                                        <Button
                                            id="bd406dab-6464-4bfb-9b2f-9b1accd16714"
                                            className="btn btn-link text-dark text-decoration-none fw-bold p-0"
                                            onClick={() => {
                                                showModal(
                                                    <AlertOverlay
                                                        id="e3c7a221-5a1c-4b2b-8513-3ffe004d086e"
                                                        status="info"
                                                        message={user.profile!.about()}
                                                        title={t(
                                                            "profilePage.header.about"
                                                        )}
                                                    />
                                                );
                                            }}
                                        >
                                            {t("profilePage.header.more")}
                                        </Button>
                                    )}
                                </div>
                            )}
                        {(!user.is_myself || user.is_creator) && (
                            <div
                                id="bce1cb8d-1040-4301-a0db-3295916bd2e9"
                                className="d-flex gap-1"
                            >
                                {!user.is_myself && followButton}
                                {user.is_creator && (
                                    <Button
                                        id="2d3536de-a358-44ca-b633-6ab87fbe0dd8"
                                        className="btn btn-sm btn-dark"
                                        style={{ width: "9em" }}
                                        onClick={() => {
                                            navigate(
                                                `/start?a=${user.username}`
                                            );
                                        }}
                                        title={t("navbar.startPetition")}
                                    >
                                        {t("navbar.startPetition")}
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {user.is_myself && (
                    <div
                        id="ee535e18-d28d-4d3b-b5ed-3542f7ac6943"
                        className="d-flex flex-column"
                    >
                        <Link
                            // id="profile-page-header-to-settings-profile"
                            id="d72c4d1e-dee3-4a4c-a57c-43bba981a0eb"
                            to="/settings/profile"
                            className="btn btn-link text-secondary py-0"
                            title={t("profilePage.header.editProfile")}
                        >
                            <SlPencil id="d72c4d1e-dee3-4a4c-a57c-43bba981a0eb-icon" />
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfilePageHeader;
