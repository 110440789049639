import User from "../../models/User";
import UsersListItem from "./UsersListItem/UsersListItem";
import { Link } from "react-router-dom";
import classes from "./UsersList.module.scss";
import {
    ComponentProps,
    HTMLAttributes,
    Ref,
    useCallback,
    useRef,
} from "react";

interface Props extends ComponentProps<any>, HTMLAttributes<any> {
    users: User[];
    itemClickHandler?: (user: User) => void;
    showFollowButton?: boolean;
    showVerifiedMark?: boolean;
    getUsers?: () => void;
    isLoading?: boolean;
}

const UsersList = ({
    users,
    getUsers,
    isLoading,
    itemClickHandler,
    showFollowButton = true,
    showVerifiedMark = true,
    className,
    id,
}: Props) => {
    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback(
        (node: Element) => {
            if (!getUsers || isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => {
                    if (entries[0].isIntersecting) {
                        getUsers();
                    }
                }
            );
            if (node) observer.current.observe(node);
        },
        [isLoading, getUsers]
    );

    const renderedUsers = users.map((user: User, index: number) => {
        return (
            <UsersListItem
                id={`user-${user.uuid}`}
                user={user}
                showFollowButton={showFollowButton}
                showVerifiedMark={showVerifiedMark}
                clickHandler={itemClickHandler}
                ref={users.length === index + 1 ? lastElementRef : undefined}
            />
        );
    });
    return (
        <div id={id} className={`${className} d-flex flex-column gap-1`}>
            {renderedUsers}
        </div>
    );
};

export default UsersList;
