import { Link } from "react-router-dom";
import React, {
    ChangeEvent,
    HTMLAttributes,
    useContext,
    useState,
} from "react";
import AuthContext from "../../../contexts/auth-context";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import AuthForm from "../../../components/AuthForm/AuthForm";
import { FailureResponse } from "../../../api/users/login";
import { useTranslation } from "react-i18next";
import { GoogleLogin } from "@react-oauth/google";
import { FALLBACK_LOCALE, LOCALE } from "../../../constants";
import {
    ga_onLogin,
    ga_onLoginAttempt,
    ga_onSignup,
    LoginMethod,
    SignupMethod,
} from "../../../utils/google-analytics";

interface Props extends HTMLAttributes<any> {}

const LoginForm = ({ id }: Props) => {
    const { t } = useTranslation();
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailInputErrorMsg, setEmailInputErrorMsg] = useState("");
    const [passwordInputErrorMsg, setPasswordInputErrorMsg] = useState("");
    const [formErrorMsg, setFormErrorMsg] = useState("");

    const isEmailValid = () => {
        if (!email) {
            setEmailInputErrorMsg(t("auth.pleaseEnterEmail"));
            return false;
        }
        return true;
    };

    const isPasswordValid = () => {
        if (!password) {
            setPasswordInputErrorMsg(t("auth.pleaseEnterPassword"));
            return false;
        }
        return true;
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (isEmailValid() && isPasswordValid()) {
            setIsLoading(true);
            try {
                ga_onLoginAttempt(LoginMethod.LOGIN_FORM, id!);
                await authCtx.login(email, password);
                ga_onLogin(LoginMethod.LOGIN_FORM, id!);
                const referrer = localStorage.getItem("referrer");
                if (referrer) {
                    window.location.href = referrer;
                }
            } catch (error) {
                const { errors } = error as FailureResponse;

                if (errors) {
                    if (errors.non_field_errors)
                        setFormErrorMsg(errors.non_field_errors[0]);
                    if (errors.email) setEmailInputErrorMsg(errors.email[0]);
                    if (errors.password)
                        setPasswordInputErrorMsg(errors.password[0]);
                }
            }
            setIsLoading(false);
        }
    };

    const loginWithGoogle = async (googleJWT: string) => {
        setFormErrorMsg("");
        setIsLoading(true);
        try {
            const invitedBy = localStorage.getItem("invitedBy");
            ga_onLoginAttempt(LoginMethod.LOGIN_WITH_GOOGLE, id!);
            const response = await authCtx.loginWithGoogle(
                googleJWT,
                invitedBy
            );
            if (response.status == 201) {
                ga_onSignup(SignupMethod.SIGNUP_WITH_GOOGLE, id!);
                localStorage.setItem("checkIfCreator", "true");
            } else ga_onLogin(LoginMethod.LOGIN_WITH_GOOGLE, id!);
            localStorage.removeItem("invitedBy");
            const referrer = localStorage.getItem("referrer");
            if (referrer) {
                window.location.href = referrer;
            }
        } catch (error) {
            const { errors } = error as FailureResponse;

            if (errors) {
                if (errors.non_field_errors)
                    setFormErrorMsg(errors.non_field_errors[0]);
            }
        }
        setIsLoading(false);
    };

    const emailInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
        setEmailInputErrorMsg("");
        setFormErrorMsg("");
    };

    const passwordInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setPassword(event.currentTarget.value);
        setPasswordInputErrorMsg("");
        setFormErrorMsg("");
    };

    return (
        <>
            <AuthForm
                id={id}
                title={t("auth.loginToAblebees")}
                errorMsg={formErrorMsg}
                submitButtonText={t("auth.login")}
                onSubmit={submitHandler}
                isLoading={isLoading}
            >
                <GoogleLogin
                    width="360"
                    text="signin_with"
                    locale={localStorage.getItem(LOCALE) || FALLBACK_LOCALE}
                    onSuccess={async (credentialResponse) => {
                        await loginWithGoogle(
                            credentialResponse.credential || ""
                        );
                    }}
                    onError={() => {}}
                />
                <div id="5e005d9b-8c18-4df4-91a6-dbdecf03d370">or</div>
                <InputGroup
                    id="a4218b57-567a-4343-a7e4-9f09dfd64a43"
                    type="email"
                    placeholder={t("auth.email")}
                    errorMsg={emailInputErrorMsg}
                    onChange={emailInputChangeHandler}
                    value={email}
                />
                <InputGroup
                    id="2ed2b543-cc27-4e13-8b23-5c0d3d484919"
                    type="password"
                    placeholder={t("auth.password")}
                    errorMsg={passwordInputErrorMsg}
                    onChange={passwordInputChangeHandler}
                    value={password}
                />
            </AuthForm>
            <div
                id="e4e5afe5-42fa-41c9-9fca-a835d0380213"
                className="d-flex flex-column align-items-center mt-2 gap-3"
            >
                <div
                    id="958f13a4-5810-4439-9c3a-7b129a52a0d8"
                    className="form-text mt-3"
                >
                    <Link
                        // id="login-form-to-forgot-password"
                        id="ae5c3cf6-fbe3-4c5c-93ab-90901efefbde"
                        to="/forgot-password"
                        className="text-decoration-none"
                    >
                        {t("auth.forgotPassword")}
                    </Link>
                </div>
                <div
                    id="b3bf35b7-38a8-490a-a38b-7ad973b68e57"
                    className="form-text mt-2"
                >
                    {t("auth.newToAblebees")}{" "}
                    <Link
                        // id="login-form-to-signup"
                        id="87c1a31a-df6c-48b6-83cf-565cf6c4eaed"
                        to="/signup"
                        className="text-decoration-none"
                    >
                        {t("auth.signup")}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default LoginForm;
