import React, { ForwardedRef, HTMLAttributes } from "react";
import Payout, { PayoutStatus } from "../../../../../../models/Payout";
import { useTranslation } from "react-i18next";
import { MdOutlineRemoveRedEye } from "react-icons/md";

interface Props extends HTMLAttributes<any> {
    payout: Payout;
}

const PayoutsListItem = React.forwardRef(
    ({ id, payout }: Props, ref: ForwardedRef<HTMLDivElement>) => {
        const { t } = useTranslation();
        let statusBadge = <></>;
        let arrivalDate = (
            <span id="74246ad5-b10f-4b43-b4e2-db66643d2aca">
                {t(
                    "settingsPage.payoutSettings.payoutsList.payoutsListItem.arrival"
                )}
                :{" "}
                {new Date(payout.arrival_date).toLocaleString("en-GB", {
                    dateStyle: "medium",
                })}
            </span>
        );
        let viewError = <></>;

        switch (payout.status) {
            case PayoutStatus.PENDING: {
                statusBadge = (
                    <span
                        id="c7b219d0-11e0-42d7-aea2-1b65a2129c8a"
                        className="badge bg-warning-subtle text-warning-emphasis"
                    >
                        {t(
                            "settingsPage.payoutSettings.payoutsList.payoutsListItem.pending"
                        )}
                    </span>
                );
                break;
            }
            case PayoutStatus.CANCELED:
                statusBadge = (
                    <span
                        id="2ba6fc4a-ca22-43b9-a413-a2b795995121"
                        className="badge bg-secondary-subtle text-secondary-emphasis"
                    >
                        {t(
                            "settingsPage.payoutSettings.payoutsList.payoutsListItem.canceled"
                        )}
                    </span>
                );
                break;
            case PayoutStatus.FAILED:
                statusBadge = (
                    <span
                        id="065ac501-e5e7-4fd3-9b17-04e9e012284c"
                        className="badge bg-danger-subtle text-danger-emphasis"
                    >
                        {t(
                            "settingsPage.payoutSettings.payoutsList.payoutsListItem.failed"
                        )}
                    </span>
                );
                arrivalDate = <></>;
                viewError = (
                    <div
                        id="158c5407-643c-424c-99b6-23d74dcbd01b"
                        className="dropup"
                    >
                        <button
                            id="95c8fc28-25bb-459f-b876-25829ae17c55"
                            className="btn btn-sm btn-link text-decoration-none text-danger"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            title={t("petition.pledgedAmount")}
                        >
                            <MdOutlineRemoveRedEye
                                id="95c8fc28-25bb-459f-b876-25829ae17c55-icon"
                                className="mb-1"
                            />{" "}
                            {t(
                                "settingsPage.payoutSettings.payoutsList.payoutsListItem.viewError"
                            )}
                        </button>
                        <ul
                            id="2a706ff3-410a-456b-80bb-0f8ad52adf85"
                            className="dropdown-menu dropdown-menu-end p-2"
                        >
                            <li
                                id="87a360ef-09f0-410d-850e-fb8e5e6cdf03"
                                className="form-text py-1 m-0 d-flex flex-column"
                            >
                                <span
                                    id="36177bff-dba0-4e3f-a7b1-4ea31e6dfcb0"
                                    className="fw-semibold"
                                >
                                    {t(
                                        "settingsPage.payoutSettings.payoutsList.payoutsListItem.payoutMethod"
                                    )}
                                    :
                                </span>
                                <span id="c6fdede6-2ff2-4f19-b2ff-dd318141189b">
                                    {payout.destination_bank_name}
                                </span>
                                <span id="2376e980-ae0e-43c1-b766-f539db8701c6">
                                    **** {payout.destination_last4}
                                </span>
                            </li>
                            <li id="90b67077-06a6-4864-b2df-ff9674463c48">
                                <hr
                                    id="c7d513fb-b976-4409-9840-632517b56ad7"
                                    className="dropdown-divider"
                                />
                            </li>
                            <li
                                id="e6446567-3db5-4bf2-b456-e0d778958202"
                                className="form-text py-1 m-0"
                            >
                                {payout.failure_message}
                            </li>
                        </ul>
                    </div>
                );
                break;
            case PayoutStatus.IN_TRANSIT:
                statusBadge = (
                    <span
                        id="9126e150-45c1-4f45-a241-8ff452a41248"
                        className="badge bg-info-subtle text-info-emphasis"
                    >
                        {t(
                            "settingsPage.payoutSettings.payoutsList.payoutsListItem.inTransit"
                        )}
                    </span>
                );
                break;
            case PayoutStatus.PAID:
                statusBadge = (
                    <span
                        id="1cbe96a5-362a-4815-9670-889bfcdc9954"
                        className="badge bg-success-subtle text-success-emphasis"
                    >
                        {t(
                            "settingsPage.payoutSettings.payoutsList.payoutsListItem.paid"
                        )}
                    </span>
                );
                break;
        }

        return (
            <div
                id={id}
                className="d-flex flex-wrap align-items-center justify-content-between border rounded p-2 form-text"
                ref={ref}
            >
                <div
                    id="72cc5581-8e7f-439d-80c3-d7800cf4a68c"
                    className="d-flex flex-wrap gap-3"
                >
                    <div
                        id="66e7d450-9a35-4264-bbc2-ce7c66ce17e2"
                        className="d-flex gap-1"
                    >
                        <span id="8a360bda-f130-476d-b444-9481c3fa404d">
                            {payout.currency.toUpperCase()}
                        </span>
                        <span id="16a7046f-6af1-4659-b15d-95182ac63214">
                            {(payout.amount / 100).toFixed(2)}
                        </span>
                    </div>
                    {statusBadge}
                </div>
                <div
                    id="6c1d6360-89f4-42bf-9268-8c9539755d5f"
                    className="d-flex flex-column align-items-end"
                >
                    <span id="81ea9d8e-77e1-48da-a4fb-0679b4d26f67">
                        {new Date(payout.created).toLocaleString("en-GB", {
                            dateStyle: "medium",
                            timeStyle: "short",
                        })}
                    </span>
                    {arrivalDate}
                    {viewError}
                </div>
            </div>
        );
    }
);

export default PayoutsListItem;
