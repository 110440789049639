import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import User from "../../models/User";
import { getUser as api_getUser } from "../../api/users/getUser";
import Spinner from "../../components/UI/Spinner/Spinner";
import ProfilePageHeader from "./ProfilePageHeader/ProfilePageHeader";
import AuthContext from "../../contexts/auth-context";
import ModalContext from "../../contexts/modal-context";
import ProfilePagePetitions, {
    ProfilePagePetitionsTab,
} from "./ProfilePagePetitions/ProfilePagePetitions";
import ProfilePageFollows, {
    ProfilePageFollowsTab,
} from "./ProfilePageFollows/ProfilePageFollows";

const ProfilePage = () => {
    const { closeModal } = useContext(ModalContext);
    const { username } = useParams();

    const { storeUser } = useContext(AuthContext);
    const [user, setUser] = useState<User | null>(null);

    let activeTab = useLocation().pathname.split("/").at(-1)!;
    if (!activeTab || activeTab === username)
        activeTab =
            user && user.is_creator
                ? ProfilePagePetitionsTab.RECEIVED
                : ProfilePagePetitionsTab.SUPPORTED;

    const getUser = useCallback(async () => {
        try {
            const response = await api_getUser(username!);
            setUser(response);
            if (response.is_myself) storeUser(response);
        } catch (error) {
            console.log(error);
        }
    }, [storeUser, username]);

    useEffect(() => {
        closeModal();
        getUser();
    }, [closeModal, getUser]);

    return (
        <div id="c3cea7cd-6f5b-4ad5-83c2-9553ccb18f19">
            {!user && <Spinner id="35bb4ae6-80d6-43c8-a0b0-ccc52675b1f3" />}
            {user && (
                <>
                    <ProfilePageHeader
                        id="152afb71-bc12-4133-ac0b-303251698f29"
                        user={user}
                    />
                    {Object.values<string>(ProfilePagePetitionsTab).includes(
                        activeTab
                    ) && (
                        <ProfilePagePetitions
                            id="104d6825-f6c0-4fe0-b1ce-a6baffcac256"
                            user={user}
                            activeTab={activeTab as ProfilePagePetitionsTab}
                        />
                    )}
                    {Object.values<string>(ProfilePageFollowsTab).includes(
                        activeTab
                    ) && (
                        <ProfilePageFollows
                            id="601d7921-b183-4b06-bf73-eee5a09e9182"
                            user={user}
                            activeTab={activeTab as ProfilePageFollowsTab}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default ProfilePage;
