import Petition from "../../../../../models/Petition";
import React, { HTMLAttributes, useContext, useState } from "react";
import {
    ApiErrorCode,
    MAX_PETITION_PLEDGE_AMOUNT,
    MIN_PETITION_PLEDGE_AMOUNT,
} from "../../../../../constants";
import InputGroup from "../../../../UI/InputGroup/InputGroup";
import {
    FailureResponse as supportPetitionFailureResponse,
    supportPetition as api_supportPetition,
} from "../../../../../api/petitions/supportPetition";
import {
    FailureResponse as getPetitionFailureResponse,
    getPetition as api_getPetition,
} from "../../../../../api/petitions/getPetition";
import Button from "../../../../UI/Button/Button";
import AlertOverlay from "../../AlertOverlay/AlertOverlay";
import ModalContext from "../../../../../contexts/modal-context";
import EmailNotVerifiedErrorOverlay from "../../EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AuthContext from "../../../../../contexts/auth-context";
import { useTranslation } from "react-i18next";
import { useStripe } from "@stripe/react-stripe-js";
import PaymentContext from "../../../../../contexts/payment-context";
import SelectPledgeSourceForm, {
    PledgeSourceType,
    PledgeSourceTypes,
} from "../../../../SelectPledgeSourceForm/SelectPledgeSourceForm";
import ChargeBreakdown from "../../../../ChargeBreakdown/ChargeBreakdown";

interface Props extends HTMLAttributes<any> {
    petition: Petition;
    onSupport: (petition: Petition) => void;
}

const SupportPetitionForm = ({ petition, onSupport, id }: Props) => {
    const stripe = useStripe();
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const { makePayment } = useContext(PaymentContext);
    const { user } = useContext(AuthContext);
    const [pledgeAmount, setPledgeAmount] = useState<number>(
        MIN_PETITION_PLEDGE_AMOUNT
    );
    const [pledgeAmountInputErrorMsg, setPledgeAmountInputErrorMsg] =
        useState("");
    const [pledgeSource, setPledgeSource] = useState<PledgeSourceType>(
        PledgeSourceTypes.BALANCE
    );
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const isPledgeAmountValid = () => {
        if (
            isNaN(pledgeAmount) ||
            pledgeAmount === undefined ||
            pledgeAmount < MIN_PETITION_PLEDGE_AMOUNT ||
            pledgeAmount > MAX_PETITION_PLEDGE_AMOUNT
        ) {
            setPledgeAmountInputErrorMsg(
                t("startPetitionPage.pleaseEnterValueBetween", {
                    min_value: MIN_PETITION_PLEDGE_AMOUNT,
                    max_value: MAX_PETITION_PLEDGE_AMOUNT,
                })
            );
            return false;
        }
        return true;
    };

    const pledgeAmountChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const val = parseInt(event.currentTarget.value);
        setErrorMsg("");
        setPledgeAmount(val);
        if (
            isNaN(val) ||
            val < MIN_PETITION_PLEDGE_AMOUNT ||
            val > MAX_PETITION_PLEDGE_AMOUNT
        ) {
            setPledgeAmountInputErrorMsg(
                t("startPetitionPage.pleaseEnterValueBetween", {
                    min_value: MIN_PETITION_PLEDGE_AMOUNT,
                    max_value: MAX_PETITION_PLEDGE_AMOUNT,
                })
            );
        } else {
            setPledgeAmountInputErrorMsg("");
        }
    };

    const onPaymentSuccess = async (petition_uuid: string) => {
        try {
            const response = await api_getPetition(petition_uuid);
            onSupport(response);
        } catch (error) {
            const { errors, status_code } = error as getPetitionFailureResponse;
            console.log(errors);
            if (status_code === 404) {
                setErrorMsg(t("petitionPage.petitionNotFound"));
            }
        }
    };

    const supportPetition = async (pledgeSource: PledgeSourceType) => {
        setIsLoading(true);
        try {
            const response = await api_supportPetition(
                petition.uuid,
                pledgeAmount * 100,
                pledgeSource
            );
            if (pledgeSource === PledgeSourceTypes.PAYMENT_METHOD)
                await makePayment(
                    stripe,
                    pledgeAmount,
                    setIsLoading,
                    setPledgeAmountInputErrorMsg,
                    async () => await onPaymentSuccess(response.uuid),
                    response.my_petition_supporter_uuid
                );
            else onSupport(response);
        } catch (error) {
            const { errors, status_code, code } =
                error as supportPetitionFailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                id="751cd957-ad6a-442d-8d1c-9ea51d6ec344"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                id="3e2b8255-587b-43b7-be44-a9dc5c298899"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else {
                    if (errors.pledge_amount)
                        setPledgeAmountInputErrorMsg(errors.pledge_amount[0]);
                    if (errors.non_field_errors)
                        setErrorMsg(errors.non_field_errors[0]);
                    if (errors.error)
                        showModal(
                            <AlertOverlay
                                id="3b0fc02a-466b-4465-a3f0-308a6cffdf81"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                }
            }
        }
        setIsLoading(false);
    };

    const showSelectPledgeSource =
        pledgeAmount * 100 <= user!.account!.payment_settings.balance;

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!isPledgeAmountValid()) return;

        if (
            showSelectPledgeSource &&
            pledgeSource === PledgeSourceTypes.BALANCE
        ) {
            await supportPetition(PledgeSourceTypes.BALANCE);
        } else {
            await supportPetition(PledgeSourceTypes.PAYMENT_METHOD);
            // await makePayment(
            //     stripe,
            //     pledgeAmount,
            //     setIsLoading,
            //     setPledgeAmountInputErrorMsg,
            //     supportPetition
            // );
        }
    };

    return (
        <form id={id} onSubmit={submitHandler}>
            <div id="4c2e2ead-9515-46de-b8eb-a24ddb5929b5" className="mb-3 row">
                <label
                    id="c869bfa6-7196-452e-97b7-3eb2e81ea9d4"
                    // htmlFor="pledgeAmountInput"
                    htmlFor="8bd88061-4642-419d-8e7b-2bb3654655ef"
                    className="col-sm-2 col-form-label"
                >
                    {t("modal.overlays.supportPetition.pledge")}:
                </label>
                <div
                    id="c0948246-9ffc-4840-a44e-c5dac5d851fa"
                    className="col-sm-10"
                >
                    <InputGroup
                        // id="pledgeAmountInput"
                        id="8bd88061-4642-419d-8e7b-2bb3654655ef"
                        className="input-group-sm"
                        errorMsg={pledgeAmountInputErrorMsg}
                        leadingAddon={
                            <span
                                id="77c868cc-2290-449f-83e4-fac2ea9cbc56"
                                className="input-group-text"
                            >
                                {user!.account!.payment_settings.currency.toUpperCase()}
                            </span>
                        }
                        trailingAddon={
                            <span
                                id="5225f9bc-b6d3-4ea5-8a0d-31d6e4804389"
                                className="input-group-text"
                            >
                                .00
                            </span>
                        }
                        type="number"
                        min={MIN_PETITION_PLEDGE_AMOUNT}
                        max={MAX_PETITION_PLEDGE_AMOUNT}
                        onChange={pledgeAmountChangeHandler}
                        value={pledgeAmount !== undefined ? pledgeAmount : ""}
                    />
                </div>
            </div>
            {errorMsg && (
                <div
                    id="c9e8e0fb-9916-4b25-acff-e6fec8af12d5"
                    className="form-text text-danger mt-0"
                >
                    {errorMsg}
                </div>
            )}
            {showSelectPledgeSource ? (
                <SelectPledgeSourceForm
                    id="0d5f7d43-1475-45a7-89e4-b55fadd33434"
                    amount={pledgeAmount}
                    source={pledgeSource}
                    onSourceChange={setPledgeSource}
                />
            ) : (
                <ChargeBreakdown
                    id="281a19e9-f4ad-417a-b412-706bd71de847"
                    amount={pledgeAmount}
                    type="pledge"
                />
            )}
            <hr id="14d28e02-4e99-4d40-bbc2-662af7534ad1" />
            <div
                id="9bcb2c1f-7789-4c90-ad9e-a533874a129a"
                className="d-flex justify-content-end gap-1"
            >
                {/*<Link*/}
                {/*    id="1dcbdffe-4854-4b85-8d3c-8057df00aaf1"*/}
                {/*    to="/settings/account"*/}
                {/*    className="btn btn-sm btn-secondary"*/}
                {/*>*/}
                {/*    {t("modal.overlays.supportPetition.topUpBalance")}*/}
                {/*</Link>*/}
                <Button
                    id="ccb8621f-ae7c-4f1c-8b80-b6f511c13a68"
                    className="btn btn-sm btn-primary"
                    type="submit"
                    disabled={isLoading}
                    isLoading={isLoading}
                >
                    {t("modal.overlays.supportPetition.support")}
                </Button>
                <Button
                    id="ee4eedba-c597-48dd-94da-65d032ed4569"
                    className="btn btn-sm btn-dark"
                    type="button"
                    onClick={closeModal}
                >
                    {t("modal.close")}
                </Button>
            </div>
        </form>
    );
};

export default SupportPetitionForm;
