import axios from "../axiosConfig";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        amount?: string[];
        non_field_errors?: string[];
        error?: string | string[];
    };
};

export type SuccessResponse = {
    requires_action: boolean;
    payment_intent_client_secret: string;
};

export const createPayment = async (
    amount: number,
    petition_supporter_uuid?: string
): Promise<SuccessResponse> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.post(
            `/payments/payments`,
            {
                amount,
                petition_supporter_uuid,
            },
            {
                headers: {
                    Authorization: token ? `Token ${token}` : "",
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
