import axios from "../axiosConfig";
import Petition from "../../models/Petition";
import * as qs from "qs";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        error?: string | string[];
    };
};

export type SuccessResponse = {
    results: Petition[];
    next: string;
    previous: string;
};

export const listPetitions = async (
    author_uuid?: string,
    addressee_uuids?: string[],
    supporter_uuid?: string,
    status?: string,
    cursor?: string
): Promise<SuccessResponse> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`/petitions`, {
            headers: {
                Authorization: token ? `Token ${token}` : "",
                "Accept-Language":
                    localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
            },
            params: {
                author: author_uuid,
                addressees: addressee_uuids,
                supporter: supporter_uuid,
                status: status,
                cursor: cursor,
            },
            paramsSerializer: {
                serialize: (params: {
                    author?: string;
                    addressees?: string[];
                    supporter?: string;
                }) => {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            },
        });
        response.data.results = response.data.results.map(
            (item: Petition) => new Petition(item)
        );
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
