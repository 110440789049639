import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import classes from "./TermsPage.module.scss";

const TermsPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div
                id="59e437b1-81e5-4928-bf96-f0320160fc12"
                className={`p-3 form-text text-dark container ${classes.content}`}
            >
                <h2 id="4d6e0437-80e1-4073-a81b-2be741aef4d3" className="mb-4">
                    Terms and Conditions
                </h2>
                <p id="edce2d3a-dd09-4a0e-aec8-4dda470cdbfb">
                    Last updated: October 31, 2024
                </p>
                <p id="e74d2d29-cd01-4d64-bdc4-3b18ea258643">
                    Please read these terms and conditions carefully before
                    using Our Service.
                </p>
                <h4
                    id="046e185c-accb-4389-ba10-f1109c45758b"
                    className="mt-4 mb-3"
                >
                    Interpretation and Definitions
                </h4>
                <h5 id="81f399a9-bcfc-444a-a57f-6a022a0502c9">
                    Interpretation
                </h5>
                <p id="e78e7366-44c2-41ef-a108-07b42a4bcc5a">
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                </p>
                <h5 id="d70408c5-67a8-403a-9b2a-7408fc06e5bb">Definitions</h5>
                <p id="7682933d-f5d4-40d0-8a13-c99242973354">
                    For the purposes of these Terms and Conditions:
                </p>
                <ul id="fda70b57-3fd9-44b3-bd68-30cbd4e25063">
                    <li id="dd829491-91a2-49b3-b5a7-ab9c0d35793c">
                        <p id="2c8c8679-06ef-4c44-9975-924162f6ffda">
                            <strong id="630eaa87-5504-40a8-a653-577acc16f788">
                                Application
                            </strong>{" "}
                            means the software program provided by the Company
                            downloaded by You on any electronic device, named
                            Ablebees
                        </p>
                    </li>
                    <li id="e9574390-6ae2-48ad-afe0-0640661a662f">
                        <p id="4603202b-6a18-48ee-8322-b382c2190cf2">
                            <strong id="d245d54b-518f-42cb-86dd-0794dfc60ef8">
                                Application Store
                            </strong>{" "}
                            means the digital distribution service operated and
                            developed by Apple Inc. (Apple App Store) or Google
                            Inc. (Google Play Store) in which the Application
                            has been downloaded.
                        </p>
                    </li>
                    <li id="5968a3cb-0026-44cb-b3ca-d96bc852a075">
                        <p id="b62fcae0-1541-4e58-afa5-9186fc7cd277">
                            <strong id="668d17ab-d94b-4aa3-a2cf-9c198d0630aa">
                                Affiliate
                            </strong>{" "}
                            means an entity that controls, is controlled by or
                            is under common control with a party, where
                            &quot;control&quot; means ownership of 50% or more
                            of the shares, equity interest or other securities
                            entitled to vote for election of directors or other
                            managing authority.
                        </p>
                    </li>
                    <li id="5a5e5085-2c57-4009-a8fa-8056f9699054">
                        <p id="2c7c86ff-3f7b-44bc-bb66-61ebf628d683">
                            <strong id="5918aa7b-6904-46b0-8eb0-e11e7fca3c2a">
                                Account
                            </strong>{" "}
                            means a unique account created for You to access our
                            Service or parts of our Service.
                        </p>
                    </li>
                    <li id="d7dd0ff9-30b6-41b4-855f-9faf1ee8d728">
                        <p id="2eb5f227-d3ce-450a-92e1-f371ba9b7c03">
                            <strong id="b1ef58e7-8b01-46e0-aa9a-4fd1d14b7935">
                                Country
                            </strong>{" "}
                            refers to: United Kingdom
                        </p>
                    </li>
                    <li id="2922b85c-3dce-48ef-b297-8ea65c0157ae">
                        <p id="5a0175d8-0be1-4269-9c10-0158e5b1b451">
                            <strong id="d7459868-7b71-4fa5-9697-c2469d616d8a">
                                Company
                            </strong>{" "}
                            (referred to as either &quot;the Company&quot;,
                            &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
                            this Agreement) refers to{" "}
                            <strong id="e21c17e5-0858-428c-9299-c403197abb6e">
                                Ablebees Inc., 548 Market St, PMB 76869, San
                                Francisco, CA 94104-5401, US
                            </strong>
                            .
                        </p>
                    </li>
                    <li id="98990599-5844-4046-a3ac-ea772a64d7f4">
                        <p id="3b0b4aba-2ef1-4d2f-8078-27594c8f5952">
                            <strong id="883b0657-e227-4841-a0d1-4fb72f99d94d">
                                Content
                            </strong>{" "}
                            refers to content such as text, images, or other
                            information that can be posted, uploaded, linked to
                            or otherwise made available by You, regardless of
                            the form of that content.
                        </p>
                    </li>
                    <li id="d89929e6-77c9-4e27-9239-0f98923f54ef">
                        <p id="76149438-c5dc-4c34-967c-c958398b901c">
                            <strong id="8dffc444-98fc-4cb6-abd1-3f8dd03c0f53">
                                Device
                            </strong>{" "}
                            means any device that can access the Service such as
                            a computer, a cellphone or a digital tablet.
                        </p>
                    </li>
                    <li id="0982dfed-5ec4-4210-8c90-4f6e1a86a91d">
                        <p id="f05b3c17-a124-4e24-9d6c-afc3e4f1020f">
                            <strong id="1b4cebbb-a7ab-4ce2-804e-d44e121373f0">
                                Feedback
                            </strong>{" "}
                            means feedback, innovations or suggestions sent by
                            You regarding the attributes, performance or
                            features of our Service.
                        </p>
                    </li>
                    <li id="ef83b8e1-e6f1-4db9-82e8-ccdddd47847a">
                        <p id="e7e16a59-dfc5-4c65-88af-cc39b004932a">
                            <strong id="a9b23aea-f907-4d5f-b542-15e1cc3f9674">
                                In-app Purchase
                            </strong>{" "}
                            refers to the purchase of a product, item, service
                            or Subscription made through the Application and
                            subject to these Terms and Conditions and/or the
                            Application Store's own terms and conditions.
                        </p>
                    </li>
                    <li id="ce99994d-e39a-4e65-bff6-cd7599c167fa">
                        <p id="2a474b1a-22d1-4eb2-87c1-01aa4699b597">
                            <strong id="bd016bb6-2627-43f0-8beb-6d5e0f9c4a21">
                                Service
                            </strong>{" "}
                            refers to the Application or the Website or both.
                        </p>
                    </li>
                    <li id="5bdd8ddc-f7e0-4fb0-9858-56712be3e2e4">
                        <p id="427b00d6-cbe1-491a-aaeb-c771312a5f43">
                            <strong id="50169880-ba22-4908-9f1b-00428eee7ae3">
                                Terms and Conditions
                            </strong>{" "}
                            (also referred as &quot;Terms&quot;) mean these
                            Terms and Conditions that form the entire agreement
                            between You and the Company regarding the use of the
                            Service.
                        </p>
                    </li>
                    <li id="78aa2f62-bf24-4ba3-a0eb-1f1ea0a4dc8e">
                        <p id="475aeb0c-f782-4898-bca9-b3427c6df8ec">
                            <strong id="3adbaead-54fc-4c91-bbfc-08caf01f9dd7">
                                Third-party Social Media Service
                            </strong>{" "}
                            means any services or content (including data,
                            information, products or services) provided by a
                            third-party that may be displayed, included or made
                            available by the Service.
                        </p>
                    </li>
                    <li id="d3d5c660-143c-4220-a6ea-169acd0b7297">
                        <p id="7d86ab39-0af5-447b-bdbd-7951eb6e3700">
                            <strong id="45e1830c-0917-41fb-89a0-a9b153bdf5b8">
                                Website
                            </strong>{" "}
                            refers to Ablebees, accessible from{" "}
                            <a
                                id="b9458b36-d6b1-4747-b9be-6860366e4c1c"
                                href="https://www.ablebees.com"
                                rel="external nofollow noopener"
                                target="_blank"
                            >
                                https://www.ablebees.com
                            </a>
                        </p>
                    </li>
                    <li id="efc0223a-503e-4bf8-8580-08d966b26551">
                        <p id="a678ded1-ca7a-4aa3-89b4-fe3614b3af55">
                            <strong id="9b7ab07b-b311-45dc-8a3d-2891cdf71b78">
                                You
                            </strong>{" "}
                            means the individual accessing or using the Service,
                            or the company, or other legal entity on behalf of
                            which such individual is accessing or using the
                            Service, as applicable.
                        </p>
                    </li>
                </ul>
                <h4
                    id="6e9a3bc0-f517-4f57-baf5-0b8bffaf609e"
                    className="mt-4 mb-3"
                >
                    Acknowledgment
                </h4>
                <p id="ae262cd0-6aa5-4a63-8b88-5208b72f0939">
                    These are the Terms and Conditions governing the use of this
                    Service and the agreement that operates between You and the
                    Company. These Terms and Conditions set out the rights and
                    obligations of all users regarding the use of the Service.
                </p>
                <p id="a7ad6db7-a792-4061-b485-0b9de4218cad">
                    Your access to and use of the Service is conditioned on Your
                    acceptance of and compliance with these Terms and
                    Conditions. These Terms and Conditions apply to all
                    visitors, users and others who access or use the Service.
                </p>
                <p id="13ce6e0b-ca6a-48ce-8acb-4c71ca2c50cf">
                    By accessing or using the Service You agree to be bound by
                    these Terms and Conditions. If You disagree with any part of
                    these Terms and Conditions then You may not access the
                    Service.
                </p>
                <p id="ae5c4590-b20a-47dd-aa17-2ec684edcc19">
                    You represent that you are over the age of 18. The Company
                    does not permit those under 18 to use the Service.
                </p>
                <p id="8abc0196-825d-4217-829a-2eab689d4613">
                    Your access to and use of the Service is also conditioned on
                    Your acceptance of and compliance with the{" "}
                    <Link
                        id="dc44b745-c97c-43e0-b466-e1c98b16c1d3"
                        // id="terms-page-to-privacy-1"
                        to="/privacy"
                    >
                        Privacy Policy
                    </Link>{" "}
                    of the Company. Our{" "}
                    <Link
                        id="80e7f4ac-6a03-440a-887a-88b88c8eacec"
                        // id="terms-page-to-privacy-2"
                        to="/privacy"
                    >
                        Privacy Policy
                    </Link>{" "}
                    describes Our policies and procedures on the collection, use
                    and disclosure of Your personal information when You use the
                    Application or the Website and tells You about Your privacy
                    rights and how the law protects You. Please read Our{" "}
                    <Link
                        id="e606535f-71eb-4299-bc5f-1514b9db6f73"
                        // id="terms-page-to-privacy-3"
                        to="/privacy"
                    >
                        Privacy Policy
                    </Link>{" "}
                    carefully before using Our Service.
                </p>
                <h4
                    id="a8307760-5ede-4756-988d-2ca966f03ce6"
                    className="mt-4 mb-3"
                >
                    User Accounts
                </h4>
                <p id="6db6287d-9f52-4345-aadb-9718e48d7052">
                    When You create an account with Us, You must provide Us
                    information that is accurate, complete, and current at all
                    times. Failure to do so constitutes a breach of the Terms,
                    which may result in immediate termination of Your account on
                    Our Service.
                </p>
                <p id="0927c2e4-4f62-4c66-acfc-561ca6c1e903">
                    You are responsible for safeguarding the password that You
                    use to access the Service and for any activities or actions
                    under Your password, whether Your password is with Our
                    Service or a Third-Party Social Media Service.
                </p>
                <p id="ad1a5e81-9960-4113-84fb-651074150703">
                    You agree not to disclose Your password to any third party.
                    You must notify Us immediately upon becoming aware of any
                    breach of security or unauthorized use of Your account.
                </p>
                <p id="9ab49689-9679-43da-901e-914e22d2262e">
                    You may not use as a username the name of another person or
                    entity or that is not lawfully available for use, a name or
                    trademark that is subject to any rights of another person or
                    entity other than You without appropriate authorization, or
                    a name that is otherwise offensive, vulgar or obscene.
                    Regardless of what your username is, We reserve the right to
                    reclaim Your username without liability to you.
                </p>
                <h4
                    id="31be6ac7-1253-4895-8ca0-17ee8aea741e"
                    className="mt-4 mb-3"
                >
                    How petitions works
                </h4>
                <p id="be185d60-0713-4d0c-b115-5a8d1845c168">
                    On Ablebees, users can start a petition addressed to another
                    user on the platform having a creator account. Creators can
                    also address a petition to themselves or other creators on
                    Ablebees. If a petition is addressed to another user, the
                    starter of the petition automatically becomes a supporter of
                    it. Supporters of a petition can pledge money - any amount
                    they are comfortable with - to incentivize the creator to
                    accept the petition.
                </p>
                <p id="fb32eace-332b-4c82-b810-f87abe1a21c1">
                    When starting a petition, the author sets the number of days
                    within which they want it to be fulfilled (i.e., the
                    requested content to be ready). If a petition is not
                    fulfilled within the time set by the author, it
                    automatically expires, and all pledged funds are returned to
                    supporters' balances.
                </p>
                <p id="9fc34a1e-8bfd-494b-b8ce-c41dc2b86928">
                    While the petition is ongoing (i.e., has not been accepted
                    by the addressee creator or has not expired), other users
                    can support it. At this stage of the petition lifecycle the
                    author can cancel it at any time. If a petition is canceled,
                    all pledged funds are returned to supporters' balances. Once
                    the petition is accepted, the author can no longer cancel
                    it.
                </p>
                <p id="593feb91-d68d-4d0c-a483-480731c039ac">
                    As soon as the creator makes the requested content and
                    uploads it to their external profile, they should share the
                    link to the content on Ablebees and mark the petition as
                    fulfilled. Once the petition is marked as fulfilled, all the
                    money pledged by supporters is transferred to the addressee
                    creator's payout balance, which can then be paid out to
                    their external bank account. In case the petition is
                    accepted but not fulfilled within the period set by the
                    author, the petition is automatically marked as not
                    fulfilled, and all pledged funds are returned to supporters'
                    balances.
                </p>
                <p id="d2825efe-fed1-4ab3-8894-eeb043e026e8">
                    There is a limit on how many petitions a creator can have in
                    the accepted but not yet fulfilled state. At the moment it
                    is just one. To be able to accept another petition, the
                    creator should fulfill the already accepted one.
                </p>
                <p id="ca5a0689-5ef3-4a43-86eb-d9ee55dc5b16">
                    It is important to note that Ablebees does not guarantee
                    that an accepted petition will ever be fulfilled, nor does
                    it ensure that the content created for a petition will meet
                    expectations of the supporters of the petition. The
                    responsibility of fulfilling a petition lies solely with the
                    creator, who is free to fulfil it in any way they see fit. A
                    creator can fulfil multiple petitions with the same content,
                    if they consider it appropriate.
                </p>
                <p id="a223e280-d579-4432-a9e5-0da493bd8073">
                    Supporters of a fulfilled petition should also bear in mind
                    that there are no refunds if the end result does not meet
                    their expectations.
                </p>
                <p id="0f9e8140-fde7-4c70-b9f7-1056e6ace5c1">
                    Supporters are encouraged to rate the content created for a
                    petition. Creators who consistently receive low ratings for
                    petitions they fulfil may be limited in their ability to
                    accept new petitions. Ablebees reserves the right to impose
                    this limitation at its sole discretion.
                </p>
                <h4
                    id="e7d17208-ce07-4fcc-b7d5-b25ae512e32c"
                    className="mt-4 mb-3"
                >
                    Platform Fee
                </h4>
                <p id="acfd6239-cdfe-46f9-95d9-653ed65d8737">
                    We charge 10% fee of the total funds raised for a fulfilled
                    petition (plus currency conversion and payout fees charged
                    by our payment partners).
                </p>
                <p id="a00dd42a-d343-4b4e-a3db-b56e4052e5ce">
                    You’re responsible for paying any additional fees or taxes
                    associated with your use of Ablebees.
                </p>
                <h4
                    id="636ca023-4862-4f51-bd34-df7f589d724f"
                    className="mt-4 mb-3"
                >
                    Content
                </h4>
                <h5 id="6e4e1f6e-50cd-4ceb-9509-6ed43ea289b3">
                    Your Right to Post Content
                </h5>
                <p id="bd8cc546-3bd2-4e5c-94b8-34c4bfbc9dc4">
                    Our Service allows You to post Content. You are responsible
                    for the Content that You post to the Service, including its
                    legality, reliability, and appropriateness.
                </p>
                <p id="ccce8613-7ae6-4848-ba85-8092434c18fc">
                    By posting Content to the Service, You grant Us the right
                    and license to use, modify, publicly perform, publicly
                    display, reproduce, and distribute such Content on and
                    through the Service. You retain any and all of Your rights
                    to any Content You submit, post or display on or through the
                    Service and You are responsible for protecting those rights.
                    You agree that this license includes the right for Us to
                    make Your Content available to other users of the Service,
                    who may also use Your Content subject to these Terms.
                </p>
                <p id="f3220aaa-a1ba-4ff6-9a95-457654dbb49f">
                    You represent and warrant that: (i) the Content is Yours
                    (You own it) or You have the right to use it and grant Us
                    the rights and license as provided in these Terms, and (ii)
                    the posting of Your Content on or through the Service does
                    not violate the privacy rights, publicity rights,
                    copyrights, contract rights or any other rights of any
                    person.
                </p>
                <h5 id="7baa23a4-e2a3-4574-ae0e-a3903d38696c">
                    Content Restrictions
                </h5>
                <p id="9e9ca735-0e0b-4d5b-9228-54f78521d480">
                    The Company is not responsible for the content of the
                    Service's users. You expressly understand and agree that You
                    are solely responsible for the Content and for all activity
                    that occurs under your account, whether done so by You or
                    any third person using Your account.
                </p>
                <p id="da9cf8b0-84a1-47a8-bf62-45a01476a791">
                    You may not transmit any Content that is unlawful,
                    offensive, upsetting, intended to disgust, threatening,
                    libelous, defamatory, obscene or otherwise objectionable.
                    Examples of such objectionable Content include, but are not
                    limited to, the following:
                </p>
                <ul id="f74364db-5920-4e34-a0f5-3a8701b20483">
                    <li id="f8b600a2-af5a-482b-9ca1-6ab0c34cfd63">
                        Unlawful or promoting unlawful activity.
                    </li>
                    <li id="6b202b53-80a8-49c1-bd01-27b7528f92c5">
                        Defamatory, discriminatory, or mean-spirited content,
                        including references or commentary about religion, race,
                        sexual orientation, gender, national/ethnic origin, or
                        other targeted groups.
                    </li>
                    <li id="89716c3c-204d-4630-bfed-4db538c4a14f">
                        Spam, machine – or randomly – generated, constituting
                        unauthorized or unsolicited advertising, chain letters,
                        any other form of unauthorized solicitation, or any form
                        of lottery or gambling.
                    </li>
                    <li id="72ccb7bf-1d98-4ecf-a0df-cf3d91f3d0fb">
                        Containing or installing any viruses, worms, malware,
                        trojan horses, or other content that is designed or
                        intended to disrupt, damage, or limit the functioning of
                        any software, hardware or telecommunications equipment
                        or to damage or obtain unauthorized access to any data
                        or other information of a third person.
                    </li>
                    <li id="d94c20b9-6061-44df-b291-bb90e848b29b">
                        Infringing on any proprietary rights of any party,
                        including patent, trademark, trade secret, copyright,
                        right of publicity or other rights.
                    </li>
                    <li id="5f08e793-0ed0-4194-8476-dd462b84566f">
                        Impersonating any person or entity including the Company
                        and its employees or representatives.
                    </li>
                    <li id="4cf84dac-4312-4137-992d-376b4d8c7e63">
                        Violating the privacy of any third person.
                    </li>
                    <li id="075a591a-7cb5-452e-be7d-08f0d4173bde">
                        False information and features.
                    </li>
                </ul>
                <p id="84d5d354-e58a-4ee2-acf7-d4fcf733793c">
                    The Company reserves the right, but not the obligation, to,
                    in its sole discretion, determine whether or not any Content
                    is appropriate and complies with this Terms, refuse or
                    remove this Content. The Company further reserves the right
                    to make formatting and edits and change the manner of any
                    Content. The Company can also limit or revoke the use of the
                    Service if You post such objectionable Content. As the
                    Company cannot control all content posted by users and/or
                    third parties on the Service, you agree to use the Service
                    at your own risk. You understand that by using the Service
                    You may be exposed to content that You may find offensive,
                    indecent, incorrect or objectionable, and You agree that
                    under no circumstances will the Company be liable in any way
                    for any content, including any errors or omissions in any
                    content, or any loss or damage of any kind incurred as a
                    result of your use of any content.
                </p>
                <h5 id="ea9701aa-9985-4a65-b97f-7a0ffba0eb3d">
                    Content Backups
                </h5>
                <p id="74c66a4f-521b-441f-82c3-e37ed1c1fe5f">
                    Although regular backups of Content are performed, the
                    Company does not guarantee there will be no loss or
                    corruption of data.
                </p>
                <p id="dd1bb192-a1c3-440b-9e79-a87984335810">
                    Corrupt or invalid backup points may be caused by, without
                    limitation, Content that is corrupted prior to being backed
                    up or that changes during the time a backup is performed.
                </p>
                <p id="9b1d1e16-6856-4fca-a1e0-efcbc3f0064a">
                    The Company will provide support and attempt to troubleshoot
                    any known or discovered issues that may affect the backups
                    of Content. But You acknowledge that the Company has no
                    liability related to the integrity of Content or the failure
                    to successfully restore Content to a usable state.
                </p>
                <p id="a106bfc7-2ecd-4fc4-a19f-a4a0d1e0f0d1">
                    You agree to maintain a complete and accurate copy of any
                    Content in a location independent of the Service.
                </p>
                <h4
                    id="1b88fe8f-a346-49bb-bcdc-14890f4df824"
                    className="mt-4 mb-3"
                >
                    Copyright Policy
                </h4>
                <h5 id="f3c795db-b15c-4a2c-87eb-023c4062181c">
                    Intellectual Property Infringement
                </h5>
                <p id="2e2cd0f6-b343-41b7-9da3-ae819e5881c3">
                    We respect the intellectual property rights of others. It is
                    Our policy to respond to any claim that Content posted on
                    the Service infringes a copyright or other intellectual
                    property infringement of any person.
                </p>
                <p id="01c50562-0432-43b1-a845-eb4b560d959b">
                    If You are a copyright owner, or authorized on behalf of
                    one, and You believe that the copyrighted work has been
                    copied in a way that constitutes copyright infringement that
                    is taking place through the Service, You must submit Your
                    notice in writing to the attention of our copyright agent
                    via email at{" "}
                    <a
                        id="6bb40361-2e48-4e63-9e98-059182b8f070"
                        href="mailto: support@ablebees.com"
                    >
                        support@ablebees.com
                    </a>{" "}
                    and include in Your notice a detailed description of the
                    alleged infringement.
                </p>
                <p id="ba2b18dc-1e4e-4894-8e83-799db16b0beb">
                    You may be held accountable for damages (including costs and
                    attorneys' fees) for misrepresenting that any Content is
                    infringing Your copyright.
                </p>
                <h5 id="8b22d4a4-59dc-4c8d-8e37-96e6dd9c6d0b">
                    DMCA Notice and DMCA Procedure for Copyright Infringement
                    Claims
                </h5>
                <p id="7a502fd1-475e-41c6-8f94-92a982fdcd37">
                    You may submit a notification pursuant to the Digital
                    Millennium Copyright Act (DMCA) by providing our Copyright
                    Agent with the following information in writing (see 17
                    U.S.C 512(c)(3) for further detail):
                </p>
                <ul id="3eab3638-1184-4f89-b2fb-38871c17f8bd">
                    <li id="d725873f-002a-4970-982e-122ffe7cb467">
                        An electronic or physical signature of the person
                        authorized to act on behalf of the owner of the
                        copyright's interest.
                    </li>
                    <li id="960475cc-f812-4b23-95b8-a2cc1fa02291">
                        A description of the copyrighted work that You claim has
                        been infringed, including the URL (i.e., web page
                        address) of the location where the copyrighted work
                        exists or a copy of the copyrighted work.
                    </li>
                    <li id="d88d1874-0bb7-480d-ad72-f6a4678520b8">
                        Identification of the URL or other specific location on
                        the Service where the material that You claim is
                        infringing is located.
                    </li>
                    <li id="3fd14bf6-7c7d-4d48-ab31-89f45395cb36">
                        Your address, telephone number, and email address.
                    </li>
                    <li id="8f2675d7-002e-43f7-8a58-d44b90ba106d">
                        A statement by You that You have a good faith belief
                        that the disputed use is not authorized by the copyright
                        owner, its agent, or the law.
                    </li>
                    <li id="265904fa-fabf-4d13-92ff-b11ab56cdd4b">
                        A statement by You, made under penalty of perjury, that
                        the above information in Your notice is accurate and
                        that You are the copyright owner or authorized to act on
                        the copyright owner's behalf.
                    </li>
                </ul>
                <p id="689b7e1f-15d1-4b88-b5cb-01d86298625a">
                    You can contact our copyright agent via email at{" "}
                    <a
                        id="3ea82497-eea3-410d-a9ca-5b1ced151996"
                        href="mailto: support@ablebees.com"
                    >
                        support@ablebees.com
                    </a>
                    . Upon receipt of a notification, the Company will take
                    whatever action, in its sole discretion, it deems
                    appropriate, including removal of the challenged content
                    from the Service.
                </p>
                <h4
                    id="15d8c584-feb3-4e6c-a979-540f576a353c"
                    className="mt-4 mb-3"
                >
                    Intellectual Property
                </h4>
                <p id="f085b0bb-a26f-4dd4-95bd-278ce1549a48">
                    The Service and its original content (excluding Content
                    provided by You or other users), features and functionality
                    are and will remain the exclusive property of the Company
                    and its licensors.
                </p>
                <p id="a794d982-a935-4ab3-b549-a40655ebaee7">
                    The Service is protected by copyright, trademark, and other
                    laws of both the Country and foreign countries.
                </p>
                <p id="29e31a71-e29a-485c-adad-956b7420182d">
                    Our trademarks and trade dress may not be used in connection
                    with any product or service without the prior written
                    consent of the Company.
                </p>
                <h4
                    id="9048eb1e-5850-4b59-8eb8-eb7988f3d4d5"
                    className="mt-4 mb-3"
                >
                    Your Feedback to Us
                </h4>
                <p id="14212174-4c98-44b5-a674-66fcbaf45a3a">
                    You assign all rights, title and interest in any Feedback
                    You provide the Company. If for any reason such assignment
                    is ineffective, You agree to grant the Company a
                    non-exclusive, perpetual, irrevocable, royalty free,
                    worldwide right and license to use, reproduce, disclose,
                    sub-license, distribute, modify and exploit such Feedback
                    without restriction.
                </p>
                <h4
                    id="5fcf4026-62b1-4632-aecb-9b3e1c2ff05c"
                    className="mt-4 mb-3"
                >
                    Links to Other Websites
                </h4>
                <p id="e6301c77-3ddd-4cc2-9c34-cb141653ba0a">
                    Our Service may contain links to third-party web sites or
                    services that are not owned or controlled by the Company.
                </p>
                <p id="512285f0-7482-4250-add6-ddba12d541e5">
                    The Company has no control over, and assumes no
                    responsibility for, the content, privacy policies, or
                    practices of any third party web sites or services. You
                    further acknowledge and agree that the Company shall not be
                    responsible or liable, directly or indirectly, for any
                    damage or loss caused or alleged to be caused by or in
                    connection with the use of or reliance on any such content,
                    goods or services available on or through any such web sites
                    or services.
                </p>
                <p id="c6f4acd2-2215-43e6-a8df-f29b7f224176">
                    We strongly advise You to read the terms and conditions and
                    privacy policies of any third-party web sites or services
                    that You visit.
                </p>
                <h4
                    id="61cf83b7-83c5-48ef-8bc1-dbc39063f878"
                    className="mt-4 mb-3"
                >
                    Termination
                </h4>
                <p id="d2a5b957-dcaf-439b-a6c5-ae2bc72e7f9e">
                    We may terminate or suspend Your Account immediately,
                    without prior notice or liability, for any reason
                    whatsoever, including without limitation if You breach these
                    Terms and Conditions.
                </p>
                <p id="ff48bc3f-78d5-42b3-92ac-042dfdd5a3bb">
                    Upon termination, Your right to use the Service will cease
                    immediately. If You wish to terminate Your Account, You may
                    simply discontinue using the Service.
                </p>
                <h4
                    id="86c47583-8df7-473e-ae86-ccc70fa642c1"
                    className="mt-4 mb-3"
                >
                    Limitation of Liability
                </h4>
                <p id="505eeb8a-2d9a-4f13-bfdc-210a6cff891c">
                    Notwithstanding any damages that You might incur, the entire
                    liability of the Company and any of its suppliers under any
                    provision of this Terms and Your exclusive remedy for all of
                    the foregoing shall be limited to the amount actually paid
                    by You through the Service or 100 USD if You haven't
                    purchased anything through the Service.
                </p>
                <p id="1ba48e33-8281-483f-b0b0-94f02ded585f">
                    To the maximum extent permitted by applicable law, in no
                    event shall the Company or its suppliers be liable for any
                    special, incidental, indirect, or consequential damages
                    whatsoever (including, but not limited to, damages for loss
                    of profits, loss of data or other information, for business
                    interruption, for personal injury, loss of privacy arising
                    out of or in any way related to the use of or inability to
                    use the Service, third-party software and/or third-party
                    hardware used with the Service, or otherwise in connection
                    with any provision of this Terms), even if the Company or
                    any supplier has been advised of the possibility of such
                    damages and even if the remedy fails of its essential
                    purpose.
                </p>
                <p id="525b7e0d-696b-4481-8340-4c4e1be62832">
                    Some states do not allow the exclusion of implied warranties
                    or limitation of liability for incidental or consequential
                    damages, which means that some of the above limitations may
                    not apply. In these states, each party's liability will be
                    limited to the greatest extent permitted by law.
                </p>
                <h4
                    id="50559a5f-6769-491a-9125-2a15443b8879"
                    className="mt-4 mb-3"
                >
                    &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer
                </h4>
                <p id="ff2933cd-68bc-433a-8a83-1ba2469a16f2">
                    The Service is provided to You &quot;AS IS&quot; and
                    &quot;AS AVAILABLE&quot; and with all faults and defects
                    without warranty of any kind. To the maximum extent
                    permitted under applicable law, the Company, on its own
                    behalf and on behalf of its Affiliates and its and their
                    respective licensors and service providers, expressly
                    disclaims all warranties, whether express, implied,
                    statutory or otherwise, with respect to the Service,
                    including all implied warranties of merchantability, fitness
                    for a particular purpose, title and non-infringement, and
                    warranties that may arise out of course of dealing, course
                    of performance, usage or trade practice. Without limitation
                    to the foregoing, the Company provides no warranty or
                    undertaking, and makes no representation of any kind that
                    the Service will meet Your requirements, achieve any
                    intended results, be compatible or work with any other
                    software, applications, systems or services, operate without
                    interruption, meet any performance or reliability standards
                    or be error free or that any errors or defects can or will
                    be corrected.
                </p>
                <p id="e09a7286-cff8-4d6a-bb62-9fd74206e60a">
                    Without limiting the foregoing, neither the Company nor any
                    of the company's provider makes any representation or
                    warranty of any kind, express or implied: (i) as to the
                    operation or availability of the Service, or the
                    information, content, and materials or products included
                    thereon; (ii) that the Service will be uninterrupted or
                    error-free; (iii) as to the accuracy, reliability, or
                    currency of any information or content provided through the
                    Service; or (iv) that the Service, its servers, the content,
                    or e-mails sent from or on behalf of the Company are free of
                    viruses, scripts, trojan horses, worms, malware, timebombs
                    or other harmful components.
                </p>
                <p id="30e0f7ac-dd17-471f-a61a-cf4cbb8cf4c1">
                    Some jurisdictions do not allow the exclusion of certain
                    types of warranties or limitations on applicable statutory
                    rights of a consumer, so some or all of the above exclusions
                    and limitations may not apply to You. But in such a case the
                    exclusions and limitations set forth in this section shall
                    be applied to the greatest extent enforceable under
                    applicable law.
                </p>
                <h4
                    id="fc57c06d-78dd-47df-abcb-7c8c7b6648ed"
                    className="mt-4 mb-3"
                >
                    Governing Law
                </h4>
                <p id="eeae8b07-67ea-424a-9f6a-1a17d625322d">
                    The laws of the Country, excluding its conflicts of law
                    rules, shall govern this Terms and Your use of the Service.
                    Your use of the Application may also be subject to other
                    local, state, national, or international laws.
                </p>
                <h4
                    id="db07359f-dc6a-449d-91c1-ec1ad57d8951"
                    className="mt-4 mb-3"
                >
                    Disputes Resolution
                </h4>
                <p id="1165b7de-ff9a-427c-9b9f-132fecb10cb8">
                    If You have any concern or dispute about the Service, You
                    agree to first try to resolve the dispute informally by
                    contacting the Company.
                </p>
                <h4
                    id="4a3a2f3d-37d7-4870-b87d-612a3ed65f8a"
                    className="mt-4 mb-3"
                >
                    For European Union (EU) Users
                </h4>
                <p id="8b53c2c2-ee84-456f-ab4b-1b88eab67b7d">
                    If You are a European Union consumer, you will benefit from
                    any mandatory provisions of the law of the country in which
                    you are resident in.
                </p>
                <h4
                    id="aafe115b-a47b-4887-96fc-953c6379e653"
                    className="mt-4 mb-3"
                >
                    United States Legal Compliance
                </h4>
                <p id="bb314c95-5cb7-4d32-8d1a-8988b3bee438">
                    You represent and warrant that (i) You are not located in a
                    country that is subject to the United States government
                    embargo, or that has been designated by the United States
                    government as a &quot;terrorist supporting&quot; country,
                    and (ii) You are not listed on any United States government
                    list of prohibited or restricted parties.
                </p>
                <h4
                    id="48428d8a-62b8-4bba-a939-0e53cfb34dbc"
                    className="mt-4 mb-3"
                >
                    Severability and Waiver
                </h4>
                <h5 id="34b9067b-c18a-4ae6-ad43-024fe5e3331c">Severability</h5>
                <p id="6c6f5d17-e8dd-42cc-ada3-54133de9a86b">
                    If any provision of these Terms is held to be unenforceable
                    or invalid, such provision will be changed and interpreted
                    to accomplish the objectives of such provision to the
                    greatest extent possible under applicable law and the
                    remaining provisions will continue in full force and effect.
                </p>
                <h5 id="737ffb14-5f02-4aef-a714-e756280e144e">Waiver</h5>
                <p id="eb482243-c19b-4f1b-855e-9c7ddc2be9c0">
                    Except as provided herein, the failure to exercise a right
                    or to require performance of an obligation under these Terms
                    shall not effect a party's ability to exercise such right or
                    require such performance at any time thereafter nor shall
                    the waiver of a breach constitute a waiver of any subsequent
                    breach.
                </p>
                <h4
                    id="b2f0d2cc-cdb2-4427-86da-94b8d62561b6"
                    className="mt-4 mb-3"
                >
                    Translation Interpretation
                </h4>
                <p id="61061ad7-9b90-4f68-947b-867f53cee617">
                    These Terms and Conditions may have been translated if We
                    have made them available to You on our Service. You agree
                    that the original English text shall prevail in the case of
                    a dispute.
                </p>
                <h4
                    id="ec197be8-563b-4418-a386-5581fe59d5af"
                    className="mt-4 mb-3"
                >
                    Changes to These Terms and Conditions
                </h4>
                <p id="998aaabe-400d-4532-9a3c-47c788d2d593">
                    We reserve the right, at Our sole discretion, to modify or
                    replace these Terms at any time. If a revision is material
                    We will make reasonable efforts to provide notice prior to
                    any new terms taking effect. What constitutes a material
                    change will be determined at Our sole discretion.
                </p>
                <p id="8c248956-0425-4728-9baf-bf006ec4c5e3">
                    By continuing to access or use Our Service after those
                    revisions become effective, You agree to be bound by the
                    revised terms. If You do not agree to the new terms, in
                    whole or in part, please stop using the website and the
                    Service.
                </p>
                <h4
                    id="2beffd41-d245-44e3-8567-7f7f46d93147"
                    className="mt-4 mb-3"
                >
                    Contact Us
                </h4>
                <p id="0ee8a267-4598-445f-bca3-3c72f5c5162a">
                    If you have any questions about these Terms and Conditions,
                    You can contact us:
                </p>
                <ul id="6bd9454d-5158-4d0e-91cd-caa3b42e35e8">
                    <li id="90336eca-18ba-4e01-a903-b306623ff7e7">
                        By email:{" "}
                        <a
                            id="0b01372a-f891-4de1-a409-fde142cbbd58"
                            href="mailto: support@ablebees.com"
                        >
                            support@ablebees.com
                        </a>
                    </li>
                </ul>
            </div>
            <Footer id="1c19750a-ffc3-4b5f-a20d-1823e826eb66" />
        </>
    );
};

export default TermsPage;
