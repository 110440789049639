import User from "../../../models/User";
import ProfilePageFollowsNavbar from "./ProfilePageFollowsNavbar/ProfilePageFollowsNavbar";
import React, { HTMLAttributes, useCallback, useEffect, useState } from "react";
import { listUserFollows as api_listUserFollows } from "../../../api/users/listUserFollows";
import UserFollow from "../../../models/UserFollow";
import UsersList from "../../../components/UsersList/UsersList";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { parseCursor } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {
    user: User;
    activeTab: ProfilePageFollowsTab;
}

export enum ProfilePageFollowsTab {
    FOLLOWERS = "followers",
    FOLLOWING = "following",
}

const ProfilePageFollows = ({ id, user, activeTab }: Props) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState<User[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getUsers = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!hasMore) return;
            setIsLoading(true);
            try {
                const response = await api_listUserFollows(
                    user.username,
                    activeTab,
                    cursor
                );
                if (cursor === undefined)
                    setUsers(
                        response.results.map((item: UserFollow) => item.user)
                    );
                else
                    setUsers((prev: User[]) => [
                        ...prev,
                        ...response.results.map(
                            (item: UserFollow) => item.user
                        ),
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [user.username, activeTab]
    );

    useEffect(() => {
        setUsers([]);
        setHasMore(true);
        setCursor(undefined);
        getUsers(undefined, true);
    }, [getUsers]);

    return (
        <div id={id}>
            <ProfilePageFollowsNavbar
                id="9ff53e01-ff40-45c6-9e51-a18994986713"
                activeTab={activeTab}
            />
            <div id="c3de4dec-ba1b-45c8-9817-cabf2119d697">
                {!!users && (
                    <UsersList
                        id="3e5a351e-aad6-48da-832b-94a88748f3b7"
                        users={users}
                        getUsers={() => getUsers(cursor, hasMore)}
                        isLoading={isLoading}
                    />
                )}
                {isLoading && (
                    <Spinner
                        id="4303c678-575f-42b9-8dd3-348a130c47ef"
                        className="mt-1"
                    />
                )}
                {!isLoading && !users.length && (
                    <div
                        id="8fb49c00-3f7f-43b0-a95b-3c52fb022b14"
                        className="d-flex justify-content-center form-text mt-0"
                    >
                        {activeTab === ProfilePageFollowsTab.FOLLOWERS
                            ? t("profilePage.noFollowers")
                            : t("profilePage.notFollowingAnyone")}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfilePageFollows;
