import React, { useCallback, useContext, useEffect, useState } from "react";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import PaymentMethodsList from "./PaymentMethodsList/PaymentMethodsList";
import {
    FailureResponse as SetPaymentMethodAsDefaultFailureResponse,
    setPaymentMethodAsDefault as api_setPaymentMethodAsDefault,
} from "../../../../api/payments/setPaymentMethodAsDefault";
import {
    deletePaymentMethod as api_deletePaymentMethod,
    FailureResponse as DeletePaymentMethodFailureResponse,
} from "../../../../api/payments/deletePaymentMethod";
import PaymentMethod from "../../../../models/PaymentMethod";
import {
    FailureResponse as GetPaymentMethodsFailureResponse,
    listPaymentMethods as api_listPaymentMethods,
} from "../../../../api/payments/listPaymentMethods";
import AddNewPaymentMethod from "./AddNewPaymentMethod/AddNewPaymentMethod";
import AlertOverlay from "../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../contexts/modal-context";

const PaymentMethodsSettingsPage = () => {
    const { showModal, closeModal } = useContext(ModalContext);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const getPaymentMethods = useCallback(async () => {
        try {
            const response = await api_listPaymentMethods();
            setPaymentMethods(response);
        } catch (error) {
            const { errors } = error as GetPaymentMethodsFailureResponse;
            if (errors)
                showModal(
                    <AlertOverlay
                        id="bc48997f-7769-48eb-8b33-be0719926d44"
                        status="error"
                        message={
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error!
                        }
                    />
                );
        }
        setIsLoading(false);
    }, [showModal]);

    useEffect(() => {
        closeModal();
        getPaymentMethods();
    }, [getPaymentMethods]);

    const setPaymentMethodAsDefault = useCallback(
        async (paymentMethodId: string) => {
            setIsLoading(true);
            try {
                const response = await api_setPaymentMethodAsDefault(
                    paymentMethodId
                );
                setPaymentMethods(response);
            } catch (error) {
                const { errors } =
                    error as SetPaymentMethodAsDefaultFailureResponse;
                if (errors)
                    showModal(
                        <AlertOverlay
                            id="65c7e7a4-060c-4698-8304-a61ef372e3d3"
                            status="error"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
            }
            setIsLoading(false);
        },
        [showModal]
    );

    const deletePaymentMethod = async (paymentMethodId: string) => {
        setIsLoading(true);
        try {
            const response = await api_deletePaymentMethod(paymentMethodId);
            setPaymentMethods(response);
        } catch (error) {
            const { errors } = error as DeletePaymentMethodFailureResponse;
            if (errors)
                showModal(
                    <AlertOverlay
                        id="98e040a1-0264-40cf-b971-77b67c3b67fc"
                        status="error"
                        message={
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error!
                        }
                    />
                );
        }
        setIsLoading(false);
    };

    return (
        <div
            id="358cb8c7-9898-41b1-b9e0-553619801c79"
            className="d-flex flex-column gap-3"
        >
            <AddNewPaymentMethod
                id="348fb108-00e7-4355-b2fc-db66dbe41012"
                setPaymentMethodAsDefault={setPaymentMethodAsDefault}
            />
            <div
                id="95f0bf75-6d6d-4cd1-a09a-4ee76b516776"
                className="border rounded-3 p-3"
            >
                {isLoading && (
                    <Spinner id="1f0d7837-71f9-406c-a690-caf0d3ea89ee" />
                )}
                {!isLoading && (
                    <PaymentMethodsList
                        id="1fb1217b-4554-4f04-9a91-a1db1d3afcdd"
                        paymentMethods={paymentMethods}
                        setPaymentMethodAsDefault={setPaymentMethodAsDefault}
                        deletePaymentMethod={deletePaymentMethod}
                    />
                )}
            </div>
        </div>
    );
};

export default PaymentMethodsSettingsPage;
