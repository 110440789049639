import React, { useCallback, useContext, useEffect, useState } from "react";
import Petition from "../../models/Petition";
import { listPetitions as api_listPetitions } from "../../api/petitions/listPetitions";
import Spinner from "../../components/UI/Spinner/Spinner";
import PetitionsList from "../../components/PetitionsList/PetitionsList";
import { parseCursor } from "../../utils/helpers";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Alert from "../../components/UI/Alert/Alert";
import Button from "../../components/UI/Button/Button";
import AuthContext from "../../contexts/auth-context";

const HomePage = () => {
    const { user } = useContext(AuthContext);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [petitions, setPetitions] = useState<Petition[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getPetitions = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!hasMore) return;
            setIsLoading(true);
            try {
                const response = await api_listPetitions(
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    cursor
                );
                if (cursor === undefined) setPetitions(response.results);
                else
                    setPetitions((prev: Petition[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        []
    );

    useEffect(() => {
        getPetitions(undefined, true);
    }, [getPetitions]);

    let checkIfCreatorAlert = <></>;
    if (localStorage.getItem("checkIfCreator") === "true" && !user!.is_creator)
        checkIfCreatorAlert = (
            <Alert
                id="f3299bed-b524-4981-9b60-9fe198d94306"
                className="alert-info alert-dismissible fade show"
            >
                <Trans
                    id="c04db789-f198-48c5-b5f9-7c4abcfb021f"
                    i18nKey="homePage.areYouCreator"
                    components={[
                        <Link
                            // id="home-page-to-settings-account_become-creator"
                            id="8db2df37-1fcc-45bb-9599-9a0a7dd626ca"
                            to="/settings/account?sc=become-creator"
                            className="alert-link"
                        />,
                    ]}
                />
                <Button
                    id="c4dc8b27-9f81-4631-be0d-7e7d1399a6cc"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() => localStorage.removeItem("checkIfCreator")}
                />
            </Alert>
        );

    return (
        <div id="3fe80c27-7c41-4652-9fb3-8ce5c7963899">
            {checkIfCreatorAlert}
            {!!petitions.length && (
                <PetitionsList
                    id="b7cc013a-94c5-473e-ad37-f34b145651a8"
                    petitions={petitions}
                    getPetitions={() => getPetitions(cursor, hasMore)}
                    isLoading={isLoading}
                />
            )}
            {isLoading && (
                <Spinner
                    id="a30222e6-afcc-4537-9d37-7e4bab0a2122"
                    className="mt-3"
                />
            )}
            {!isLoading && !petitions.length && (
                <div
                    id="49b642a6-4c1e-4332-ae1f-cf12939e8ffd"
                    className="d-flex justify-content-center form-text mt-0"
                >
                    {t("homePage.noPetitionsFound")}
                </div>
            )}
        </div>
    );
};

export default HomePage;
