import ExternalLink from "./ExternalLink";

class UserProfile {
    readonly uuid: string;
    readonly followers_count: number;
    readonly following_count: number;
    readonly headline: string;
    readonly description: string;
    readonly external_links?: ExternalLink[];

    constructor(data: any) {
        this.uuid = data.uuid;
        this.followers_count = data.followers_count;
        this.following_count = data.following_count;
        this.headline = data.headline;
        this.description = data.description;
        this.external_links = data.external_links
            ? data.external_links?.map(
                  (item: ExternalLink) => new ExternalLink(item)
              )
            : undefined;
    }

    about = () => {
        let message = "";
        if (this.headline && this.description)
            message = `${this.headline}\n\n${this.description}`;
        else if (this.headline) message = this.headline;
        else if (this.description) message = this.description;
        return message;
    };
}

export default UserProfile;
