import React, { ChangeEvent, HTMLAttributes, useState } from "react";
import AuthForm from "../../../components/AuthForm/AuthForm";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import { Link, useSearchParams } from "react-router-dom";
import {
    FailureResponse,
    resetPassword,
} from "../../../api/users/resetPassword";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {}

const ResetPasswordForm = ({ id }: Props) => {
    const { t } = useTranslation();
    let [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordInputErrorMsg, setPasswordInputErrorMsg] = useState("");
    const [confirmPasswordInputErrorMsg, setConfirmPasswordInputErrorMsg] =
        useState("");
    const [formErrorMsg, setFormErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const code = searchParams.get("code");

    const isPasswordValid = () => {
        if (!password) {
            setPasswordInputErrorMsg(t("auth.pleaseEnterPassword"));
            return false;
        }
        return true;
    };

    const isConfirmPasswordValid = () => {
        if (!confirmPassword) {
            setConfirmPasswordInputErrorMsg(t("auth.pleaseConfirmPassword"));
            return false;
        }

        if (confirmPassword !== password) {
            setPasswordInputErrorMsg(t("auth.valuesDoNotMatch"));
            setConfirmPasswordInputErrorMsg(t("auth.valuesDoNotMatch"));
            return false;
        }
        return true;
    };

    const passwordInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setPassword(event.currentTarget.value);
        setPasswordInputErrorMsg("");
        setConfirmPasswordInputErrorMsg("");
        setFormErrorMsg("");
    };

    const confirmPasswordInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setConfirmPassword(event.currentTarget.value);
        setPasswordInputErrorMsg("");
        setConfirmPasswordInputErrorMsg("");
        setFormErrorMsg("");
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (isPasswordValid() && isConfirmPasswordValid()) {
            setIsLoading(true);
            try {
                const response = await resetPassword(password, code!);
                setSuccessMsg(response.message);
            } catch (error) {
                const { errors } = error as FailureResponse;

                if (errors) {
                    if (errors.password)
                        setPasswordInputErrorMsg(errors.password[0]);
                    if (errors.code) setFormErrorMsg(errors.code[0]);
                    if (errors.error)
                        setFormErrorMsg(
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error
                        );
                }
            }
            setIsLoading(false);
        }
    };

    return (
        <>
            {!successMsg && (
                <AuthForm
                    // id="reset-password-form"
                    id={id}
                    title={t("auth.resetPassword")}
                    errorMsg={formErrorMsg}
                    submitButtonText={t("auth.resetPassword")}
                    onSubmit={submitHandler}
                    isLoading={isLoading}
                >
                    <InputGroup
                        id="a48bd5c3-0cc1-488c-ae44-dcbf717ae1ca"
                        type="password"
                        placeholder={t("auth.newPassword")}
                        errorMsg={passwordInputErrorMsg}
                        onChange={passwordInputChangeHandler}
                        value={password}
                    />
                    <InputGroup
                        id="cb7cdd48-00dc-4a8a-b6c4-7ef0f7500dd5"
                        type="password"
                        placeholder={t("auth.confirmPassword")}
                        errorMsg={confirmPasswordInputErrorMsg}
                        onChange={confirmPasswordInputChangeHandler}
                        value={confirmPassword}
                    />
                </AuthForm>
            )}
            {successMsg && (
                <>
                    <div
                        id="fef0c566-350c-406d-8c8e-d57c9a4dfcb4"
                        className="alert alert-success w-100"
                        role="alert"
                    >
                        {successMsg}
                    </div>
                    <div
                        id="94f7afe3-3560-4a1a-8c42-33734bdbbda6"
                        className="d-flex flex-column align-items-center mt-2 gap-3"
                    >
                        <div
                            id="9c95034c-39a8-4253-ad0e-9f3aae29e0a6"
                            className="form-text mt-3"
                        >
                            <Link
                                // id="reset-password-form-to-login"
                                id="76b5988d-f7dd-4df1-ac69-efcff54cd13f"
                                to="/login"
                                className="text-decoration-none"
                            >
                                {t("auth.login")}
                            </Link>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ResetPasswordForm;
