import { useMatch } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import Petition from "../../models/Petition";
import Spinner from "../../components/UI/Spinner/Spinner";
import PetitionsListItem from "../../components/PetitionsList/PetitionsListItem/PetitionsListItem";
import {
    FailureResponse,
    getPetition as api_getPetition,
} from "../../api/petitions/getPetition";
import AuthContext from "../../contexts/auth-context";
import Footer from "../../components/Footer/Footer";
import classes from "./PetitionPage.module.scss";
import { useTranslation } from "react-i18next";

const PetitionPage = () => {
    const { t } = useTranslation();
    const match = useMatch("/petitions/:uuid");
    const petition_uuid = match?.params.uuid!;
    const { isLoggedIn } = useContext(AuthContext);
    const [petition, setPetition] = useState<Petition | null>(null);
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [firstTimeVisit, setFirstTimeVisit] = useState<boolean>(
        localStorage.getItem("firstTimeVisit") === petition_uuid || false
    );
    const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(true);
    localStorage.removeItem("firstTimeVisit");
    // const referrer = localStorage.getItem("referrer");
    //
    // if (referrer) {
    //     if (referrer === window.location.href) {
    //         localStorage.removeItem("referrer");
    //     }
    // }

    const getPetition = useCallback(async () => {
        setErrorMsg("");
        try {
            const response = await api_getPetition(petition_uuid);
            setPetition(response);
        } catch (error) {
            const { errors, status_code } = error as FailureResponse;
            console.log(errors);
            if (status_code === 404) {
                setErrorMsg(t("petitionPage.petitionNotFound"));
            }
        }
        setIsLoading(false);
    }, [petition_uuid]);

    useEffect(() => {
        getPetition();
    }, [getPetition]);

    let content = (
        <>
            {isLoading && <Spinner id="0fe4da90-12b6-436a-9802-2eaaf7b09978" />}
            {!isLoading && petition && (
                <>
                    {firstTimeVisit && showSuccessAlert && (
                        <div
                            id="eba9ef7b-3b14-451a-9ecf-7bc833e5f33c"
                            className="alert alert-success alert-dismissible fade show"
                            role="alert"
                        >
                            <h5
                                id="16ec11b5-fe31-4bde-b59d-b459c765f313"
                                className="alert-heading mb-3"
                            >
                                {t("petitionPage.congratulations")}
                            </h5>
                            <p id="d998a051-f1d9-4413-bbcc-c5b5d22288d4">
                                {t("petitionPage.petitionSuccessfullyStarted")}
                            </p>
                            <p
                                id="e5b9537a-1417-43c8-a3fa-3d8de15c3243"
                                className="mb-0"
                            >
                                {petition.am_i_addressee
                                    ? t("petitionPage.shareAsAddressee")
                                    : t("petitionPage.shareAsAuthor")}
                            </p>
                            <button
                                id="e29018c5-870e-4a0d-8185-35d36de96673"
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="alert"
                                aria-label="Close"
                                onClick={() => setShowSuccessAlert(false)}
                            />
                        </div>
                    )}
                    <PetitionsListItem
                        id={`petition-${petition.uuid}`}
                        petition={petition}
                    />
                </>
            )}
            {!isLoading && !petition && (
                <p id="fc93a6b3-2c75-4471-9a10-f159457f0ead">{errorMsg}</p>
            )}
        </>
    );

    if (!isLoggedIn) {
        content = (
            <>
                <div
                    id="111441c9-495d-4090-abca-7865c5711a82"
                    className={`row justify-content-sm-center p-0 m-0 ${classes.content}`}
                >
                    <div
                        id="f080e09f-d6d0-4d7c-b4b6-1f480c997e9f"
                        className="col col-md-9 col-lg-6 col-xl-6 col-xxl-4"
                    >
                        {content}
                    </div>
                </div>
                <Footer id="3034eeef-8933-46d0-b984-853b694e153f" />
            </>
        );
    }

    return content;
};

export default PetitionPage;
