export enum CreatorType {
    EDUCATOR = "educator",
    INTERVIEWER = "interviewer",
    CHEF = "chef",
    INVESTIGATIVE_JOURNALIST = "investigative_journalist",
    COVER_ARTIST = "cover_artist",
    PRODUCT_REVIEWER = "product_reviewer",
    MEDICAL_DOCTOR = "medical_doctor",
    TRAVELER = "traveler",
    PHOTOGRAPHER = "photographer",
    MUSICIAN = "musician",
    FITNESS_INSTRUCTOR = "fitness_instructor",
}
