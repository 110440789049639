import React, { HTMLAttributes, useContext, useState } from "react";
import ModalContext from "../../../../../contexts/modal-context";
import {
    ApiErrorCode,
    MAX_PETITION_END_RESULT_RATE_COMMENT_LENGTH,
} from "../../../../../constants";
import {
    FailureResponse,
    ratePetitionEndResult as api_ratePetitionEndResult,
} from "../../../../../api/petitions/ratePetitionEndResult";
import EmailNotVerifiedErrorOverlay from "../../EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AlertOverlay from "../../AlertOverlay/AlertOverlay";
import Button from "../../../../UI/Button/Button";
import Petition from "../../../../../models/Petition";
import Textarea from "../../../../UI/Textarea/Textarea";
import Select from "../../../../UI/Select/Select";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {
    petition: Petition;
    onRate: (petition: Petition) => void;
}

const RatePetitionEndResultForm = ({ petition, onRate, id }: Props) => {
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const [rate, setRate] = useState<number>(NaN);
    const [comment, setComment] = useState<string>();
    const [rateSelectErrorMsg, setRateSelectErrorMsg] = useState("");
    const [commentTextareaErrorMsg, setCommentTextareaErrorMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const rateChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const val = parseInt(event.currentTarget.value);
        setRate(val);
        setRateSelectErrorMsg("");
    };

    const commentChangeHandler = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setComment(
            event.currentTarget.value.length > 0
                ? event.currentTarget.value
                : undefined
        );
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();

        if (isNaN(rate)) {
            setRateSelectErrorMsg(
                t("modal.overlays.rateEndResult.rateEndResult")
            );
            return;
        }

        setIsLoading(true);
        try {
            const response = await api_ratePetitionEndResult(
                petition.uuid,
                rate!,
                comment
            );
            onRate(response);
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                id="81bd1a50-0420-4757-9f65-8a9ca12f8a7f"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                id="dac402ad-065b-4f04-9cc4-a4422c1831c6"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else {
                    if (errors.rate) setRateSelectErrorMsg(errors.rate[0]);
                    if (errors.comment)
                        setCommentTextareaErrorMsg(errors.comment[0]);
                    if (errors.non_field_errors)
                        setErrorMsg(errors.non_field_errors[0]);
                    if (errors.error)
                        setErrorMsg(
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error
                        );
                }
            }
        }
        setIsLoading(false);
    };

    return (
        <form id={id} onSubmit={submitHandler}>
            <div id="8acedd44-0b83-4b12-9932-3192255ef469" className="mb-3 row">
                <Select
                    id="abf5bab1-21a3-4289-8e06-90760ab32eba"
                    className="form-select form-select-sm"
                    errorMsg={rateSelectErrorMsg}
                    onChange={rateChangeHandler}
                    value={rate}
                >
                    <option id="9285d3b7-aea6-4117-b3bb-37fed627a874" selected>
                        Select rate value
                    </option>
                    <option id="7914390d-bca1-4255-8b23-407be77d331a" value={1}>
                        1
                    </option>
                    <option id="6ad43436-6a60-4166-aab5-347ab9216bda" value={2}>
                        2
                    </option>
                    <option id="46837571-45f7-412f-8397-544e9e9968f8" value={3}>
                        3
                    </option>
                    <option id="ff870cdf-8c1b-42b2-9281-54d1dea7f90d" value={4}>
                        4
                    </option>
                    <option id="9fb51300-5a03-47e3-a31c-2d3b43ab1eef" value={5}>
                        5
                    </option>
                    <option id="af049057-22a9-4267-a468-881df79dce8e" value={6}>
                        6
                    </option>
                    <option id="8f250d74-57f6-422a-999e-f3358ef41700" value={7}>
                        7
                    </option>
                    <option id="3cae4619-dc15-4ba9-a9ae-ba19c59c07c2" value={8}>
                        8
                    </option>
                    <option id="3cbd54cf-2cd9-41fd-920b-583843a0e727" value={9}>
                        9
                    </option>
                    <option
                        id="7f74322c-cb82-433a-b3e1-8761bab90fa5"
                        value={10}
                    >
                        10
                    </option>
                </Select>
            </div>
            <div id="9dabb9e9-9d23-4877-9abb-99c102d5fcaf" className="mb-3 row">
                <Textarea
                    id="039ba779-fa19-425e-91f6-f676b7954c65"
                    className="form-control-sm"
                    rows={5}
                    errorMsg={commentTextareaErrorMsg}
                    placeholder={t(
                        "modal.overlays.rateEndResult.rateEndResult"
                    )}
                    onChange={commentChangeHandler}
                    value={comment}
                    maxLength={MAX_PETITION_END_RESULT_RATE_COMMENT_LENGTH}
                />
                <div
                    id="913c8fd6-28dc-446b-b221-3df00ccd6812"
                    className="form-text d-flex justify-content-end"
                >
                    {MAX_PETITION_END_RESULT_RATE_COMMENT_LENGTH -
                        (comment?.length || 0)}
                </div>
            </div>
            {errorMsg && (
                <div
                    id="ce8bc539-4f70-4bb6-b7b6-305a8579b19c"
                    className="form-text text-danger mt-0"
                >
                    {errorMsg}
                </div>
            )}
            <hr id="55f97022-7b91-4759-9fd5-e1389de1440a" />
            <div
                id="ebc51688-f736-427e-8d18-f2775d706801"
                className="d-flex justify-content-end gap-1"
            >
                <Button
                    id="8f00c2d7-4549-4385-b5a4-932f04003a87"
                    className="btn btn-sm btn-primary"
                    type="submit"
                    disabled={isLoading}
                    isLoading={isLoading}
                >
                    {t("modal.overlays.rateEndResult.rate")}
                </Button>
                <Button
                    id="45a9146f-6f76-4883-8f1c-325889a152c1"
                    className="btn btn-sm btn-dark"
                    type="button"
                    onClick={closeModal}
                >
                    {t("modal.close")}
                </Button>
            </div>
        </form>
    );
};

export default RatePetitionEndResultForm;
