import React, { useContext, useEffect } from "react";
import PayoutMethodSettings from "./PayoutMethodSettings/PayoutMethodSettings";
import PayoutsList from "./PayoutsList/PayoutsList";
import ModalContext from "../../../../contexts/modal-context";
import PayoutCountrySettings from "./PayoutCountrySettings/PayoutCountrySettings";
import AuthContext from "../../../../contexts/auth-context";

const PayoutSettingsPage = () => {
    const { user } = useContext(AuthContext);
    const { closeModal } = useContext(ModalContext);
    useEffect(() => {
        closeModal();
    }, [closeModal]);

    return (
        <div
            id="1d53c8bb-386f-47b2-a15a-f05189c349f3"
            className="d-flex flex-column gap-3"
        >
            {!user?.account!.payment_settings.payout_country_code && (
                <PayoutCountrySettings id="1be6d412-fee3-467b-8ba8-9ec74929966b" />
            )}
            <PayoutMethodSettings id="a65fdf8d-cec5-45a8-a669-d11e5b51bb96" />
            <PayoutsList id="ec8c3f91-d123-422e-b577-ea2e02254d19" />
        </div>
    );
};

export default PayoutSettingsPage;
