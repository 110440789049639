import React, { ComponentProps, HTMLAttributes } from "react";
import Button from "../UI/Button/Button";

interface Props extends ComponentProps<any>, HTMLAttributes<any> {
    title: string;
    errorMsg: string;
    submitButtonText: string;
    onSubmit: (event: React.FormEvent) => void;
    isLoading: boolean;
}

const AuthForm = ({
    id,
    title,
    errorMsg,
    submitButtonText,
    onSubmit,
    isLoading,
    children,
}: Props) => {
    return (
        <form
            id={id}
            className="d-flex flex-column gap-3 align-items-center"
            onSubmit={onSubmit}
        >
            <h3
                id="aa87727c-60d3-42b1-91a3-87fbf15f72ec"
                className={errorMsg && "mb-0"}
            >
                {title}
            </h3>
            {errorMsg && (
                <div
                    id="c12f9400-a191-4947-b70d-664c19cb953c"
                    className="form-text text-danger mt-0"
                >
                    {errorMsg}
                </div>
            )}
            {children}
            <Button
                id="67218ec7-e96c-4108-8774-06befe4b60ec"
                className="btn btn-primary w-100"
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
            >
                {submitButtonText}
            </Button>
        </form>
    );
};

export default AuthForm;
