import React, { HTMLAttributes } from "react";
import { CiBank } from "react-icons/ci";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {
    payoutMethod: {
        account: string;
        account_holder_name: string;
        account_holder_type: string;
        account_type: string;
        available_payout_methods: string[];
        bank_name: string;
        country: string;
        currency: string;
        default_for_currency: boolean;
        fingerprint: string;
        id: string;
        last4: string;
        metadata: any;
        object: string;
        routing_number: string;
        status: string;
    };
}

const PayoutMethodsListItem = ({ id, payoutMethod }: Props) => {
    const { t } = useTranslation();

    let default_badge = <></>;
    if (payoutMethod.default_for_currency)
        default_badge = (
            <span
                id="a34c1e67-1175-49cf-8f92-da4a372917ad"
                className="badge bg-secondary"
            >
                {t(
                    "settingsPage.paymentMethodsSettings.paymentMethodsList.paymentMethodsListItem.default"
                )}
            </span>
        );

    return (
        <div
            id={id}
            className="d-flex flex-wrap align-items-center justify-content-between border rounded px-2 py-1"
        >
            <div
                id="884b25f0-68da-430a-890d-8fef2810bc6a"
                className="d-flex gap-3 form-text align-items-center"
            >
                <CiBank
                    id="cbc53ea6-edb8-420f-abdc-202613610465"
                    className="fs-1"
                />
                <div
                    id="c7ec91f9-b97c-4f1f-b576-564693f145ee"
                    className="d-flex flex-column"
                >
                    <div
                        id="cbe0911e-9467-4e70-8b5e-dff949785fb8"
                        className="d-flex"
                    >
                        <span id="e0cc52c3-a5a2-4711-aa46-ce1217ee3607">
                            {payoutMethod.bank_name}
                        </span>
                    </div>
                    <div
                        id="5db21cff-158f-4e36-9dd7-53f1258f76c3"
                        className="d-flex gap-1 gap-md-2"
                    >
                        <span id="75312f73-bee0-4099-a7a1-dc2d9a10be00">
                            **** {payoutMethod.last4}
                        </span>
                        <span id="de1d4609-8b7c-4109-8e80-d9f1a77b3a78">
                            {payoutMethod.routing_number}
                        </span>
                        <span id="d3bb69c6-39f8-4404-ab25-cb3b713fafa6">
                            {payoutMethod.currency.toUpperCase()}
                        </span>
                        {default_badge}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayoutMethodsListItem;
