import classes from "./ModalOverlay.module.scss";
import { ComponentProps, HTMLAttributes, useContext, useEffect } from "react";
import ModalContext from "../../../contexts/modal-context";

interface Props extends ComponentProps<any>, HTMLAttributes<any> {}

const ModalOverlay = ({ id, children }: Props) => {
    const { closeModal } = useContext(ModalContext);

    useEffect(() => {
        const keyDownHandler = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                event.preventDefault();
                closeModal();
            }
        };

        document.addEventListener("keydown", keyDownHandler);

        return () => document.removeEventListener("keydown", keyDownHandler);
    }, []);

    return (
        <div
            // id="8bafb191-ddd4-4c49-8617-0a5cb7c21fa5"
            id={id}
            className={`modal show d-block ${classes.modal}`}
        >
            <div
                id="17f85566-028c-466f-821f-a29405fa428b"
                className="modal-dialog"
            >
                <div
                    id="a26feb58-33fa-465f-a2e4-4ffe5b14a182"
                    className="modal-content"
                >
                    <div
                        id="f1c2ef2b-c1c5-429a-9d94-e3f9fc27dca7"
                        className="modal-body"
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalOverlay;
