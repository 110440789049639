import React, { ForwardedRef, HTMLAttributes } from "react";
import Notification, {
    NotificationClickDestinationType,
} from "../../../../models/Notification";
import blank_profile_image from "../../../../images/blank-profile-picture.svg";
import classes from "./NotificationsListItem.module.scss";
import { markNotificationAsSeen as api_markNotificationAsSeen } from "../../../../api/notifications/markNotificationAsSeen";
import { useNavigate } from "react-router-dom";
import logo from "../../../../icon.svg";
import { parseProfileImageSrc } from "../../../../utils/helpers";

interface Props extends HTMLAttributes<any> {
    notification: Notification;
}

const NotificationsListItem = React.forwardRef(
    ({ id, notification }: Props, ref: ForwardedRef<HTMLDivElement>) => {
        const navigate = useNavigate();
        let link_to = `/petitions/${notification.notification.click_destination_unique_id}`;
        if (
            notification.notification.click_destination_type ===
            NotificationClickDestinationType.USER
        )
            link_to = `/users/${notification.notification.click_destination_unique_id}`;

        const dt_created_date = new Date(
            notification.dt_created
        ).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            // year: "numeric",
        });

        const dt_created_time = new Date(
            notification.dt_created
        ).toLocaleTimeString("en-GB", { hour: "numeric", minute: "numeric" });

        const markNotificationAsSeen = async () => {
            try {
                await api_markNotificationAsSeen(notification.uuid);
            } catch (error) {
                console.log(error);
            }
        };

        const clickHandler = async () => {
            await markNotificationAsSeen();
            navigate(link_to);
        };

        const get_image_src = () => {
            if (notification.notification.initiator) {
                if (notification.notification.initiator.profile_image) {
                    return parseProfileImageSrc(
                        notification.notification.initiator.profile_image
                    );
                }
                return blank_profile_image;
            }
            return logo;
        };

        return (
            <div
                id={id}
                className={`d-flex align-items-center justify-content-between border rounded p-2 form-text mt-0  ${
                    classes.notification
                } ${!notification.is_seen && classes.not_seen}`}
                onClick={clickHandler}
                ref={ref}
            >
                <div
                    id="6d2abee3-6d7a-4633-8d9e-6d4b6b2eadf2"
                    className="d-flex gap-2 align-items-center"
                >
                    <img
                        id="17d1a4b0-c3d2-46d6-ad10-9daaedc46184"
                        src={get_image_src()}
                        width="45"
                        height="45"
                        className="rounded me-2"
                    />
                    <span id="ef8088fa-78e6-4fa9-807c-6358ac1e5c9c">
                        {notification.notification.message}
                    </span>
                </div>
                <div
                    id="9b799342-2578-437c-85b7-818da02e024d"
                    className="d-flex flex-column align-items-end"
                >
                    <span id="b712b8ad-7ff6-4e0f-9d48-33fd023eeec2">
                        {dt_created_date}
                    </span>
                    <span id="661304e9-ac9c-476e-8a62-f3e7c9170884">
                        {dt_created_time}
                    </span>
                </div>
            </div>
        );
    }
);

export default NotificationsListItem;
