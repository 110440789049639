import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./custom-bootstrap.scss";
import "bootstrap/dist/js/bootstrap.min";
import App from "./App";
import { ModalContextProvider } from "./contexts/modal-context";
import { AuthContextProvider } from "./contexts/auth-context";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PaymentContextProvider } from "./contexts/payment-context";
import { DraftPetitionContextProvider } from "./contexts/draft-petition-context";
import { NotificationContextProvider } from "./contexts/notification-context";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
    >
        <DraftPetitionContextProvider>
            <AuthContextProvider>
                <ModalContextProvider>
                    <PaymentContextProvider>
                        <BrowserRouter>
                            <NotificationContextProvider>
                                <App />
                            </NotificationContextProvider>
                        </BrowserRouter>
                    </PaymentContextProvider>
                </ModalContextProvider>
            </AuthContextProvider>
        </DraftPetitionContextProvider>
    </GoogleOAuthProvider>
);
