import React, {
    ChangeEvent,
    HTMLAttributes,
    useContext,
    useEffect,
    useState,
} from "react";
import Button from "../../../../../components/UI/Button/Button";
import ModalContext from "../../../../../contexts/modal-context";
import {
    FailureResponse,
    requestBecomeCreator as api_requestBecomeCreator,
} from "../../../../../api/admins/requestBecomeCreator";
import { getMyLatestBecomeCreatorRequest as api_getMyLatestBecomeCreatorRequest } from "../../../../../api/admins/getMyLatestBecomeCreatorRequest";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import { ApiErrorCode } from "../../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import InputGroup from "../../../../../components/UI/InputGroup/InputGroup";
import { Trans, useTranslation } from "react-i18next";
import BecomeCreatorRequest, {
    BecomeCreatorRequestStatus,
} from "../../../../../models/BecomeCreatorRequest";
import { BsQuestionCircle } from "react-icons/bs";

interface Props extends HTMLAttributes<any> {}

const BecomeCreatorSettings = ({ id }: Props) => {
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    useState("");
    const [inputErrorMsg, setInputErrorMsg] = useState("");
    const [externalLinkUrl, setExternalLinkUrl] = useState("");
    const [latestBecomeCreatorRequest, setLatestBecomeCreatorRequest] =
        useState<BecomeCreatorRequest>();

    useEffect(() => {
        getMyLatestBecomeCreatorRequest();
    }, []);

    const getMyLatestBecomeCreatorRequest = async () => {
        try {
            const response = await api_getMyLatestBecomeCreatorRequest();
            setLatestBecomeCreatorRequest(response);
        } catch (error) {}
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (externalLinkUrl.length === 0) {
            setInputErrorMsg(
                t(
                    "settingsPage.accountSettings.becomeCreatorSettings.enterExternalLinkUrl"
                )
            );
            return;
        }

        setIsLoading(true);
        try {
            const response = await api_requestBecomeCreator(externalLinkUrl);
            showModal(
                <AlertOverlay
                    id="b4539d3e-9293-4507-b7fb-0c3595ef7c19"
                    status={"success"}
                    message={response.message}
                />
            );
            setLatestBecomeCreatorRequest(response.latest_request);
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                id="5a24f9d3-0c20-4ff7-a740-d5d10be5bab5"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else {
                        showModal(
                            <AlertOverlay
                                id="05a0b101-9541-4462-95d1-42116b618c36"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    }
                } else if (errors.raw_external_link_url) {
                    setInputErrorMsg(errors.raw_external_link_url[0]);
                } else if (
                    errors.non_field_errors &&
                    errors.non_field_errors.length > 0
                ) {
                    showModal(
                        <AlertOverlay
                            id="a2821faa-ef45-4102-bdfc-d16d19bf7a52"
                            status="error"
                            message={errors.non_field_errors[0]}
                        />
                    );
                } else if (errors.error) {
                    showModal(
                        <AlertOverlay
                            id="d677f18b-0e44-422b-baf0-93d5e43b2bac"
                            status="error"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
                } else console.log(errors);
            }
        }
        setIsLoading(false);
    };

    const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setExternalLinkUrl(event.currentTarget.value);
        setInputErrorMsg("");
    };

    let content = (
        <InputGroup
            id="e5a15c5d-9430-4886-bde1-f1d5b752216d"
            label={t(
                "settingsPage.accountSettings.becomeCreatorSettings.primaryPageUrl"
            )}
            className="input-group-sm"
            errorMsg={inputErrorMsg}
            onChange={inputChangeHandler}
            type="text"
            placeholder={t(
                "settingsPage.accountSettings.becomeCreatorSettings.example"
            )}
            trailingAddon={
                <Button
                    id="49c48dcf-0681-41fe-a3ba-36e4d547726c"
                    className="btn btn-sm btn-dark"
                    onClick={submitHandler}
                    isLoading={isLoading}
                >
                    {t(
                        "settingsPage.accountSettings.becomeCreatorSettings.request"
                    )}
                </Button>
            }
        />
    );

    if (latestBecomeCreatorRequest) {
        let statusBadge = <></>;
        switch (latestBecomeCreatorRequest.status) {
            case BecomeCreatorRequestStatus.PENDING:
                statusBadge = (
                    <div
                        id="4e30c040-645c-4b83-88ae-6f78550086fd"
                        className="badge bg-warning-subtle text-warning-emphasis d-flex gap-2"
                    >
                        <span id="208c732e-6459-475e-822a-8e90b1195031">
                            {t(
                                "settingsPage.accountSettings.becomeCreatorSettings.becomeCreatorRequest.status.pending.title"
                            )}
                        </span>
                        <div
                            id="cbb4178e-0ec4-4d1a-95d0-1a9ff74cdd8a"
                            className="dropdown-center"
                        >
                            <button
                                id="a435869f-3d93-4287-b8f7-819e5270e625"
                                className="btn nav-link"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <BsQuestionCircle
                                    id="a435869f-3d93-4287-b8f7-819e5270e625-icon"
                                    style={{ marginBottom: "2px" }}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                />
                            </button>
                            <ul
                                id="54cf0e9d-a400-426f-9b8f-2fac92be58fd"
                                className="dropdown-menu"
                            >
                                <div
                                    id="ebe310ae-98cb-4e69-9991-37938ee00fba"
                                    className="dropdown-header text-wrap fw-normal d-flex flex-column gap-3"
                                >
                                    {t(
                                        "settingsPage.accountSettings.becomeCreatorSettings.becomeCreatorRequest.status.pending.explanation",
                                        {
                                            returnObjects: true,
                                        }
                                    ).map((paragraph, index) => (
                                        <p
                                            id="92e552ff-b1d7-496d-88ca-19ad4e24981c"
                                            key={index}
                                            className="m-0"
                                        >
                                            <Trans
                                                id="8904af76-0501-4ee2-883e-e8896145c60f"
                                                defaults={paragraph}
                                            />
                                        </p>
                                    ))}
                                </div>
                            </ul>
                        </div>
                    </div>
                );
                break;
            case BecomeCreatorRequestStatus.APPROVED:
                statusBadge = (
                    <div
                        id="3eb4c6ef-340d-4800-9bf1-094fe7a538fc"
                        className="badge bg-primary-subtle text-primary-emphasis d-flex gap-2"
                    >
                        <span id="5a9e4239-9afc-4329-9500-dbf99411371b">
                            {t(
                                "settingsPage.accountSettings.becomeCreatorSettings.becomeCreatorRequest.status.approved.title"
                            )}
                        </span>
                        <div
                            id="136b33aa-0cef-438f-b57d-70581ffb9622"
                            className="dropdown-center"
                        >
                            <button
                                id="1a3025de-73e3-4245-aa2d-dc6c616185d6"
                                className="btn nav-link"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <BsQuestionCircle
                                    id="1a3025de-73e3-4245-aa2d-dc6c616185d6-icon"
                                    style={{ marginBottom: "2px" }}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                />
                            </button>
                            <ul
                                id="5f9b5249-8a85-47f6-ac72-7641bfe22522"
                                className="dropdown-menu"
                            >
                                <div
                                    id="59f17ed4-b523-46d2-9929-7edd3fc51954"
                                    className="dropdown-header text-wrap fw-normal d-flex flex-column gap-3"
                                >
                                    {t(
                                        "settingsPage.accountSettings.becomeCreatorSettings.becomeCreatorRequest.status.approved.explanation",
                                        {
                                            returnObjects: true,
                                        }
                                    ).map((paragraph, index) => (
                                        <p
                                            id="676b6732-b7b7-44f8-a155-3cebd57010c5"
                                            key={index}
                                            className="m-0"
                                        >
                                            <Trans
                                                id="93092eae-f673-4d6a-86fc-1c5c6649420f"
                                                defaults={paragraph}
                                            />
                                        </p>
                                    ))}
                                </div>
                            </ul>
                        </div>
                    </div>
                );
                break;
        }

        content = (
            <div
                id="102e2bcd-10ea-44de-82c1-14d0e3af2a41"
                className="d-flex flex-column gap-2"
            >
                <div
                    id="bb3c06c7-54b3-4438-83df-f2f8e3ff5db5"
                    className="border rounded p-2 d-flex flex-column gap-2"
                >
                    <div
                        id="0f0568b8-0213-4a19-9065-f0555f3f2743"
                        className="d-flex gap-2 form-text"
                    >
                        <label id="f7a5f5d1-ed83-4a68-95e5-e45390e1346f">
                            URL:
                        </label>
                        <span id="04c9a227-9155-4be3-b9f8-b459f3feedac">
                            <a
                                id="f9b2b1dd-a839-4c7c-8348-7f49ba3212d9"
                                target="_blank"
                                href={
                                    latestBecomeCreatorRequest.raw_external_link_url
                                }
                            >
                                {
                                    latestBecomeCreatorRequest.raw_external_link_url
                                }
                            </a>
                        </span>
                    </div>
                    <div
                        id="25ff674e-3d3d-4d34-8b26-863c8d6def98"
                        className="d-flex gap-3 justify-content-between"
                    >
                        <span
                            id="8a75950c-0652-4a22-811e-cbe0592e5a48"
                            className="form-text"
                        >
                            {new Date(
                                latestBecomeCreatorRequest.dt_created
                            ).toLocaleString("en-GB", {
                                dateStyle: "medium",
                                timeStyle: "short",
                            })}
                        </span>
                        {statusBadge}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div id={id}>
            <h6 id="5fb19091-12b9-47d1-ac05-0290452dbf7f">
                {t(
                    "settingsPage.accountSettings.becomeCreatorSettings.requestToSwitchToCreatorAccount"
                )}
            </h6>
            <hr id="a037a7a1-5306-4e72-a339-e5a99ac7e967" />
            {content}
        </div>
    );
};

export default BecomeCreatorSettings;
