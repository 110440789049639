import React, { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<any> {}

const Alert = ({ id, className, children }: Props) => {
    return (
        <div id={id} className={`alert ${className}`}>
            {children}
        </div>
    );
};

export default Alert;
