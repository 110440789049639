export const enum GoogleAnalyticsEvent {
    LOGIN_ATTEMPT = "login_attempt",
    LOGIN = "login",
    SIGNUP_ATTEMPT = "signup_attempt",
    SIGNUP = "signup",
}

export const enum LoginMethod {
    LOGIN_FORM = "login_form",
    LOGIN_WITH_GOOGLE = "login_with_google",
}

export const enum SignupMethod {
    SIGNUP_FORM = "signup_form",
    SIGNUP_WITH_GOOGLE = "signup_with_google",
}

export const ga_onLoginAttempt = (loginMethod: LoginMethod, formId: string) => {
    dataLayerPush(GoogleAnalyticsEvent.LOGIN_ATTEMPT, {
        loginMethod,
        "gtm.elementId": formId,
    });
};

export const ga_onLogin = (loginMethod: LoginMethod, formId: string) => {
    dataLayerPush(GoogleAnalyticsEvent.LOGIN, {
        loginMethod,
        "gtm.elementId": formId,
    });
};

export const ga_onSignupAttempt = (
    signupMethod: SignupMethod,
    formId: string
) => {
    dataLayerPush(GoogleAnalyticsEvent.SIGNUP_ATTEMPT, {
        signupMethod,
        "gtm.elementId": formId,
    });
};

export const ga_onSignup = (signupMethod: SignupMethod, formId: string) => {
    dataLayerPush(GoogleAnalyticsEvent.SIGNUP, {
        signupMethod,
        "gtm.elementId": formId,
    });
};

const dataLayerPush = (event: GoogleAnalyticsEvent, props: {}) => {
    (window as any).dataLayer.push({
        event: event,
        ...props,
    });
};
