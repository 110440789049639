import React, { ForwardedRef, HTMLAttributes } from "react";
import Payment, { PaymentStatus } from "../../../../../../models/Payment";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {
    payment: Payment;
}

const PaymentsListItem = React.forwardRef(
    ({ id, payment }: Props, ref: ForwardedRef<HTMLDivElement>) => {
        const { t } = useTranslation();
        let statusBadge = <></>;
        switch (payment.status) {
            case PaymentStatus.PENDING: {
                statusBadge = (
                    <span
                        id="0c9dea4f-3cb9-43dd-a63c-d6f5e5731b34"
                        className="badge bg-warning-subtle text-warning-emphasis"
                    >
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.pending"
                        )}
                    </span>
                );
                break;
            }
            case PaymentStatus.PROCESSING: {
                statusBadge = (
                    <span
                        id="869b97cf-5e99-4c44-bfbb-28246f4dfee7"
                        className="badge bg-warning-subtle text-warning-emphasis"
                    >
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.processing"
                        )}
                    </span>
                );
                break;
            }
            case PaymentStatus.CANCELED:
                statusBadge = (
                    <span
                        id="752d9415-3456-4f78-a383-99ffe16140c9"
                        className="badge bg-danger-subtle text-danger-emphasis"
                    >
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.canceled"
                        )}
                    </span>
                );
                break;
            case PaymentStatus.SUCCEEDED:
                statusBadge = (
                    <span
                        id="e2140be4-69a7-4e5e-8b4f-71f03247460f"
                        className="badge bg-success-subtle text-success-emphasis"
                    >
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.succeeded"
                        )}
                    </span>
                );
                break;
            case PaymentStatus.REQUIRES_ACTION:
                statusBadge = (
                    <span
                        id="1fedb232-210a-4cad-8c88-ec1b1a098b89"
                        className="badge bg-info-subtle text-info-emphasis"
                    >
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.requiresAction"
                        )}
                    </span>
                );
                break;
            case PaymentStatus.REQUIRES_CAPTURE:
                statusBadge = (
                    <span
                        id="cce29cfb-87d6-4c18-9b7c-0036e93e65a9"
                        className="badge bg-info-subtle text-info-emphasis"
                    >
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.requiresCapture"
                        )}
                    </span>
                );
                break;
            case PaymentStatus.REQUIRES_CONFIRMATION:
                statusBadge = (
                    <span
                        id="ab8a76f8-492d-4f63-af76-f5e013d3a39d"
                        className="badge bg-info-subtle text-info-emphasis"
                    >
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.requiresConfirmation"
                        )}
                    </span>
                );
                break;
            case PaymentStatus.REQUIRES_PAYMENT_METHOD:
                statusBadge = (
                    <span
                        id="a39d2aa3-b151-4ee3-8130-dd5a3fc32dd2"
                        className="badge bg-info-subtle text-info-emphasis"
                    >
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.requiresPaymentMethod"
                        )}
                    </span>
                );
                break;
        }

        return (
            <div
                id={id}
                className="d-flex flex-wrap align-items-center justify-content-between border rounded p-2 form-text"
                ref={ref}
            >
                <div
                    id="15c58fb3-8c24-410a-bd6d-949fae51d4e5"
                    className="d-flex flex-column align-items-start gap-1"
                >
                    <div
                        id="fe61b90c-5b0c-4534-8ef1-9da0b4f7ba51"
                        className="d-flex gap-3"
                    >
                        <div
                            id="88730a11-ee2d-4731-9eea-e2e4baf4cfb3"
                            className="d-flex gap-1 text-black"
                        >
                            <span id="7f10f830-1be5-41f4-9c45-e2cc2c316077">
                                {payment.currency.toUpperCase()}
                            </span>
                            <span id="62238130-df01-4227-bc1e-690d87faf409">
                                {(payment.amount / 100).toFixed(2)}
                            </span>
                        </div>
                    </div>
                    <div
                        id="c3002f49-7b9d-4bf2-8bba-8f41f58a0b9e"
                        className="d-flex gap-1 text-secondary"
                    >
                        <span id="d433d548-0093-4b0d-9435-1f2fb783263c">
                            {t(
                                "settingsPage.billingSettings.paymentsList.paymentsListItem.fee"
                            )}
                            :
                        </span>
                        <span id="8bdaf560-1d82-484f-8ab0-e8f40c1ed708">
                            {payment.currency.toUpperCase()}
                        </span>
                        <span id="d319bf89-c7ec-428e-8962-68a08846d6b9">
                            {(payment.transaction_fee / 100).toFixed(2)}
                        </span>
                    </div>
                </div>

                <div
                    id="4a217dfe-f8cb-4777-95f4-4c6bb7e8f6d2"
                    className="d-flex flex-column align-items-end gap-1"
                >
                    <span id="e1d742be-43db-4f12-b6e6-ab5428b19739">
                        {new Date(payment.dt_created).toLocaleString("en-GB", {
                            dateStyle: "medium",
                            timeStyle: "short",
                        })}
                    </span>
                    {statusBadge}
                </div>
            </div>
        );
    }
);

export default PaymentsListItem;
