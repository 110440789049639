import React, { HTMLAttributes, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./HeroCarousel.module.scss";
import { FaAnglesDown } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Button from "../../../components/UI/Button/Button";
import { CreatorType } from "../constants";

type CreatorTypeCard = {
    image: any;
    image_mobile: any;
    image_alt: string;
    title:
        | "welcomePage.heroCarousel.creatorTypes.digitalContentCreator.title"
        | "welcomePage.heroCarousel.creatorTypes.educator.title"
        | "welcomePage.heroCarousel.creatorTypes.interviewer.title"
        | "welcomePage.heroCarousel.creatorTypes.chef.title"
        | "welcomePage.heroCarousel.creatorTypes.journalist.title"
        | "welcomePage.heroCarousel.creatorTypes.coverArtist.title"
        | "welcomePage.heroCarousel.creatorTypes.productReviewer.title"
        | "welcomePage.heroCarousel.creatorTypes.medicalDoctor.title"
        | "welcomePage.heroCarousel.creatorTypes.traveler.title"
        | "welcomePage.heroCarousel.creatorTypes.photographer.title"
        | "welcomePage.heroCarousel.creatorTypes.musician.title"
        | "welcomePage.heroCarousel.creatorTypes.fitnessInstructor.title";
    subtitle:
        | "welcomePage.heroCarousel.creatorTypes.digitalContentCreator.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.educator.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.interviewer.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.chef.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.journalist.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.coverArtist.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.productReviewer.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.medicalDoctor.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.traveler.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.photographer.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.musician.subtitle"
        | "welcomePage.heroCarousel.creatorTypes.fitnessInstructor.subtitle";
    creator_type?: CreatorType;
};

const creatorTypeCards: CreatorTypeCard[] = [
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/digital-content-creator-m.jpg",
        image_mobile:
            "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/digital-content-creator-m.jpg",
        image_alt: "Digital content creator",
        title: "welcomePage.heroCarousel.creatorTypes.digitalContentCreator.title",
        subtitle:
            "welcomePage.heroCarousel.creatorTypes.digitalContentCreator.subtitle",
        creator_type: undefined,
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/educator-m.jpg",
        image_mobile:
            "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/educator-m.jpg",
        image_alt: "Educator",
        title: "welcomePage.heroCarousel.creatorTypes.educator.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.educator.subtitle",
        creator_type: CreatorType.EDUCATOR,
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/interviewer-m-cropped.jpg",
        image_mobile:
            "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/interviewer-v-m.jpg",
        image_alt: "Interviewer",
        title: "welcomePage.heroCarousel.creatorTypes.interviewer.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.interviewer.subtitle",
        creator_type: CreatorType.INTERVIEWER,
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/chef-m.jpg",
        image_mobile:
            "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/chef-m.jpg",
        image_alt: "Chef",
        title: "welcomePage.heroCarousel.creatorTypes.chef.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.chef.subtitle",
        creator_type: CreatorType.CHEF,
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/investigative-journalist-m.jpg",
        image_mobile:
            "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/investigative-journalist-m.jpg",
        image_alt: "Investigative journalist",
        title: "welcomePage.heroCarousel.creatorTypes.journalist.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.journalist.subtitle",
        creator_type: CreatorType.INVESTIGATIVE_JOURNALIST,
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/cover-artist-m.jpg",
        image_mobile:
            "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/cover-artist-m.jpg",
        image_alt: "Cover artist",
        title: "welcomePage.heroCarousel.creatorTypes.coverArtist.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.coverArtist.subtitle",
        creator_type: CreatorType.COVER_ARTIST,
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/product-reviewer-m.jpg",
        image_mobile:
            "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/product-reviewer-v-m.jpg",
        image_alt: "Product reviewer",
        title: "welcomePage.heroCarousel.creatorTypes.productReviewer.title",
        subtitle:
            "welcomePage.heroCarousel.creatorTypes.productReviewer.subtitle",
        creator_type: CreatorType.PRODUCT_REVIEWER,
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/medical-doctor-m.jpg",
        image_mobile:
            "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/medical-doctor-m.jpg",
        image_alt: "Medical doctor",
        title: "welcomePage.heroCarousel.creatorTypes.medicalDoctor.title",
        subtitle:
            "welcomePage.heroCarousel.creatorTypes.medicalDoctor.subtitle",
        creator_type: CreatorType.MEDICAL_DOCTOR,
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/traveler-m.jpg",
        image_mobile:
            "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/traveler-m.jpg",
        image_alt: "Traveler",
        title: "welcomePage.heroCarousel.creatorTypes.traveler.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.traveler.subtitle",
        creator_type: CreatorType.TRAVELER,
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/photographer-m.jpg",
        image_mobile:
            "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/photographer-m.jpg",
        image_alt: "Photographer",
        title: "welcomePage.heroCarousel.creatorTypes.photographer.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.photographer.subtitle",
        creator_type: CreatorType.PHOTOGRAPHER,
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/musician-m.jpg",
        image_mobile:
            "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/musician-m.jpg",
        image_alt: "Musician",
        title: "welcomePage.heroCarousel.creatorTypes.musician.title",
        subtitle: "welcomePage.heroCarousel.creatorTypes.musician.subtitle",
        creator_type: CreatorType.MUSICIAN,
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/fitness-instructor-m.jpg",
        image_mobile:
            "https://storage.googleapis.com/ablebees-public/frontend/welcome/hero/fitness-instructor-v-m.jpg",
        image_alt: "Fitness instructor",
        title: "welcomePage.heroCarousel.creatorTypes.fitnessInstructor.title",
        subtitle:
            "welcomePage.heroCarousel.creatorTypes.fitnessInstructor.subtitle",
        creator_type: CreatorType.FITNESS_INSTRUCTOR,
    },
];

interface Props extends HTMLAttributes<any> {
    selectedCreatorType?: CreatorType;
    handleScrollClick: () => void;
    handleSelectedCreatorTypeChange: (creatorType?: CreatorType) => void;
}

const HeroCarousel = ({
    id,
    handleScrollClick,
    handleSelectedCreatorTypeChange,
}: Props) => {
    const ct = new URLSearchParams(window.location.search).get("ct");
    const { t } = useTranslation();
    const [selectedCreatorType, setSelectedCreatorType] =
        useState<CreatorType>();

    useEffect(() => {
        const myCarouselElement = document.getElementById(
            id!
        ) as HTMLFormElement;
        if (myCarouselElement) {
            // const myCarousel = new bootstrap.Carousel(myCarouselElement, {
            //     ride: "carousel",
            //     interval: 2000,
            //     pause: false,
            // });
            myCarouselElement.addEventListener(
                "slid.bs.carousel",
                (event: any) => {
                    setSelectedCreatorType(
                        creatorTypeCards[event.to].creator_type
                    );
                }
            );
        }

        if (Object.values(CreatorType).includes(ct as CreatorType)) {
            setSelectedCreatorType(ct as CreatorType);
        }
    }, []);

    useEffect(() => {
        handleSelectedCreatorTypeChange(selectedCreatorType);
    }, [selectedCreatorType]);

    const renderedCreatorTypeCards = creatorTypeCards.map((item, index) => {
        const isActive = selectedCreatorType
            ? selectedCreatorType === item.creator_type
            : index === 0;
        return (
            <div
                key={index}
                id="b78a7adb-ccce-4e7a-9648-0756e0ea0420"
                className={`carousel-item ${isActive && "active"}`}
            >
                <img
                    id="8e6601ce-eb4b-43a8-be3a-98fc58d1994d"
                    src={
                        window.innerHeight > window.innerWidth
                            ? item.image_mobile
                            : item.image
                    }
                    className="d-block w-100 vh-100"
                    style={{ objectFit: "cover" }}
                    alt={item.image_alt}
                />
                <div
                    id="dd57f3fc-d64d-43e1-bd4c-3ad75c52107c"
                    className={classes["color-overlay"]}
                />
                <div
                    id="575e5ee4-45da-4fbb-a63d-5dd81d0ab974"
                    className={`carousel-caption h-100 d-flex flex-column justify-content-center align-items-center ${classes["carousel-caption"]}`}
                    style={{ bottom: 0 }}
                >
                    <div
                        id="3cf3884c-1600-4a3c-9bde-a505af83598d"
                        className="d-flex flex-column gap-3 align-items-center"
                        style={{ paddingTop: "90px" }}
                    >
                        <span
                            id="bda33e8d-5388-4e3d-b5cb-01d0943797ee"
                            className={classes["carousel-caption-title"]}
                        >
                            {t(item.title)}
                        </span>
                        <span
                            id="b3f3e98d-d888-45c2-abaf-9b642fd21676"
                            className={`${classes["carousel-caption-subtitle"]} mb-3`}
                        >
                            {t(item.subtitle)}
                        </span>
                        <Link
                            id="228d6720-8eca-4f60-95db-5980844819b2"
                            to={"/signup"}
                            className={`btn btn-danger ${classes["carousel-caption-link"]}`}
                        >
                            {t("welcomePage.joinAblebees")}
                        </Link>
                        <Button
                            id="04e71a16-13ee-44b8-9a14-660f95751368"
                            className="btn btn-sm btn-link text-decoration-none text-white mt-5"
                            onClick={handleScrollClick}
                        >
                            <div className="d-flex align-items-center gap-2">
                                <FaAnglesDown id="04e71a16-13ee-44b8-9a14-660f95751368-icon" />
                                Scroll to learn more
                                <FaAnglesDown id="04e71a16-13ee-44b8-9a14-660f95751368-icon" />
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        );
    });

    const renderedCarouselIndicators = creatorTypeCards.map((item, index) => {
        const isActive = selectedCreatorType
            ? selectedCreatorType === item.creator_type
            : index === 0;
        return (
            <Button
                key={index}
                id={`be6a7c90-b96d-419b-b731-49022ea43189-${index}`}
                type="button"
                data-bs-target={`#${id}`}
                data-bs-slide-to={index}
                className={isActive ? "active" : ""}
                aria-current={isActive ? "true" : "false"}
                aria-label={`Slide ${index + 1}`}
            />
        );
    });

    return (
        <div
            id={id}
            className="carousel slide carousel-fade vh-100"
            // data-bs-ride={"carousel"}
            // data-bs-pause={false}
            // data-bs-keyboard={false}
            // data-bs-touch={false}
            // data-bs-interval={2000}
        >
            <div
                id="31ec720b-32c9-4ef3-8afa-d8722a58ed5b"
                className="carousel-indicators"
                style={{ bottom: "6rem" }}
            >
                {renderedCarouselIndicators}
            </div>
            <div
                id="2cb58f1b-2447-4a5f-9845-c2f4835e933d"
                className="carousel-inner"
            >
                {renderedCreatorTypeCards}
            </div>
            <Button
                id="af090bb8-9d64-421d-bc21-1435041b8444"
                className="carousel-control-prev"
                type="button"
                data-bs-target={`#${id}`}
                data-bs-slide="prev"
            >
                <span
                    id="af090bb8-9d64-421d-bc21-1435041b8444-icon"
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                ></span>
                <span
                    id="af090bb8-9d64-421d-bc21-1435041b8444-text"
                    className="visually-hidden"
                >
                    {t("welcomePage.heroCarousel.previous")}
                </span>
            </Button>
            <Button
                id="a57c5c3b-fe75-4904-87bd-4664939cb96b"
                className="carousel-control-next"
                type="button"
                data-bs-target={`#${id}`}
                data-bs-slide="next"
            >
                <span
                    id="a57c5c3b-fe75-4904-87bd-4664939cb96b-icon"
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                ></span>
                <span
                    id="a57c5c3b-fe75-4904-87bd-4664939cb96b-text"
                    className="visually-hidden"
                >
                    {t("welcomePage.heroCarousel.next")}
                </span>
            </Button>
        </div>
    );
};

export default HeroCarousel;
