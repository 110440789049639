import { Link } from "react-router-dom";
import { ProfilePageFollowsTab } from "../ProfilePageFollows";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { ProfilePagePetitionsTab } from "../../ProfilePagePetitions/ProfilePagePetitions";
import { useTranslation } from "react-i18next";
import { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<any> {
    activeTab: ProfilePageFollowsTab;
}

const ProfilePageFollowsNavbar = ({ id, activeTab }: Props) => {
    const { t } = useTranslation();
    return (
        <ul id={id} className="nav nav-underline py-3">
            <li id="15920d1b-992a-4536-89df-a392b1bbc30b" className="nav-item">
                <Link
                    // id="profile-page-follows-navbar-to-supported"
                    id="7d8264ed-6344-4b89-a648-9812d5b78740"
                    className={`nav-link`}
                    to={ProfilePagePetitionsTab.SUPPORTED}
                >
                    <AiOutlineArrowLeft id="7d8264ed-6344-4b89-a648-9812d5b78740-icon" />
                </Link>
            </li>
            <li id="b340490f-7da0-40da-a633-e0a4953aad56" className="nav-item">
                <Link
                    // id="profile-page-follows-navbar-to-followers"
                    id="7d003170-9eb8-4f7c-b825-8c235c48f5d6"
                    className={`nav-link ${
                        activeTab === ProfilePageFollowsTab.FOLLOWERS &&
                        "active"
                    }`}
                    to={ProfilePageFollowsTab.FOLLOWERS}
                >
                    {t("profilePage.header.followers")}
                </Link>
            </li>
            <li id="1b6bda35-6b3d-49f2-a192-87cb61032a9b" className="nav-item">
                <Link
                    // id="profile-page-follows-navbar-to-following"
                    id="656a1c4e-8542-4d10-aba5-38a645e0fc4b"
                    className={`nav-link ${
                        activeTab === ProfilePageFollowsTab.FOLLOWING &&
                        "active"
                    }`}
                    to={ProfilePageFollowsTab.FOLLOWING}
                >
                    {t("profilePage.header.following")}
                </Link>
            </li>
        </ul>
    );
};

export default ProfilePageFollowsNavbar;
