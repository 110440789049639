import React, { ButtonHTMLAttributes, ForwardedRef } from "react";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading?: boolean;
    onlySpinner?: boolean;
}

const Button = React.forwardRef(
    (
        { isLoading, onlySpinner, disabled, children, id, ...rest }: Props,
        ref: ForwardedRef<HTMLButtonElement>
    ) => {
        let content = (
            <>
                {isLoading && (
                    <span
                        id="9b76f390-4acc-4cc2-8a75-42b35e697d70"
                        className="spinner-border spinner-border-sm me-3"
                    />
                )}
                {children}
            </>
        );

        if (onlySpinner)
            content = (
                <>
                    {isLoading && (
                        <span
                            id="d30b9fe9-a5fe-46da-81ee-1cec31812902"
                            className="spinner-border spinner-border-sm mx-3"
                        />
                    )}
                    {!isLoading && children}
                </>
            );

        return (
            <button
                id={id}
                disabled={isLoading || disabled}
                ref={ref}
                {...rest}
            >
                {content}
            </button>
        );
    }
);

export default Button;
