import {
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import Button from "../../../../../../components/UI/Button/Button";
import React, { HTMLAttributes, useContext, useEffect, useState } from "react";
import ModalContext from "../../../../../../contexts/modal-context";
import AlertOverlay from "../../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import { useTranslation } from "react-i18next";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import {
    FailureResponse,
    getStripeSetupIntent as api_getStripeSetupIntent,
} from "../../../../../../api/payments/getStripeSetupIntent";
import { ApiErrorCode } from "../../../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import { Link } from "react-router-dom";
import DraftPetitionContext from "../../../../../../contexts/draft-petition-context";

interface Props extends HTMLAttributes<any> {
    setPaymentMethodAsDefault: (id: string) => void;
}

const PaymentMethodSetupForm = ({ id, setPaymentMethodAsDefault }: Props) => {
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const { draftPetition } = useContext(DraftPetitionContext);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "setup_intent_client_secret"
        );

        if (clientSecret)
            stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
                // Inspect the SetupIntent `status` to indicate the status of the payment
                // to your customer.
                //
                // Some payment methods will [immediately succeed or fail][0] upon
                // confirmation, while others will first enter a `processing` state.
                //
                // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                switch (setupIntent!.status) {
                    case "succeeded":
                        setPaymentMethodAsDefault(
                            typeof setupIntent!.payment_method === "string"
                                ? setupIntent!.payment_method
                                : setupIntent!.payment_method!.id
                        );
                        showModal(
                            <AlertOverlay
                                id="6b0c667e-0343-4cfe-80bd-f8bfee21c25d"
                                status="success"
                                message={t(
                                    "settingsPage.paymentMethodsSettings.addNewPaymentMethod.paymentMethodSetupForm.savedPaymentMethod"
                                )}
                                link={
                                    draftPetition ? (
                                        <Link
                                            // id="payment-method-setup-form-alert-overlay-to-start"
                                            id="53be2b95-b699-4ef4-bcfa-a653757ce9e9"
                                            to="/start"
                                            className="btn btn-sm btn-primary"
                                            onClick={closeModal}
                                        >
                                            {t("navbar.startPetition")}
                                        </Link>
                                    ) : undefined
                                }
                            />
                        );
                        break;
                    case "processing":
                        showModal(
                            <AlertOverlay
                                id="9b564514-bf63-4faf-896b-4b74e905dcff"
                                status="warning"
                                message={t(
                                    "settingsPage.paymentMethodsSettings.addNewPaymentMethod.paymentMethodSetupForm.processingPaymentDetails"
                                )}
                            />
                        );
                        break;
                    case "requires_payment_method":
                        showModal(
                            <AlertOverlay
                                id="db06fa44-2b5c-40bd-87ba-3f59a6d9a86b"
                                status="error"
                                message={t(
                                    "settingsPage.paymentMethodsSettings.addNewPaymentMethod.paymentMethodSetupForm.failedProcessPaymentDetails"
                                )}
                            />
                        );
                        break;
                }
            });
    }, [stripe, setPaymentMethodAsDefault, showModal]);

    const getStripeSetupIntent = async () => {
        try {
            const response = await api_getStripeSetupIntent();
            return response.client_secret;
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;
            if (errors)
                if (
                    status_code === 403 &&
                    code === ApiErrorCode.EMAIL_NOT_VERIFIED
                ) {
                    showModal(
                        <EmailNotVerifiedErrorOverlay
                            id="ac997b95-e43e-4702-b009-bbf1b5cce14f"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
                } else
                    showModal(
                        <AlertOverlay
                            id="5ad9d9c9-6be2-4427-a43f-d0a9eb51681f"
                            status="error"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
        }
    };

    const submitHandler = async (event: React.FormEvent) => {
        // Docs: https://stripe.com/docs/payments/accept-a-payment-deferred?platform=web&type=setup
        event.preventDefault();
        setErrorMessage(undefined);

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        // Trigger form validation
        const { error: submitError } = await elements.submit();
        if (submitError) {
            setIsLoading(false);
            setErrorMessage(submitError.message);
            return;
        }

        const clientSecret = await getStripeSetupIntent();
        if (clientSecret) {
            const { error } = await stripe.confirmSetup({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: `${process.env.REACT_APP_BASE_URL}/settings/payment-methods`,
                },
            });

            if (error) {
                setErrorMessage(error.message);
            }
        }

        setIsLoading(false);
    };

    const options: StripePaymentElementOptions = {
        layout: {
            type: "accordion",
            defaultCollapsed: false,
            radios: true,
            spacedAccordionItems: false,
        },
    };

    return (
        <form id={id} onSubmit={submitHandler}>
            <PaymentElement
                id="5bbd99f8-4803-492a-a975-fc42f576e0f9"
                options={options}
            />
            <span
                id="69863a76-77e1-425c-aa62-220e66fd1eb3"
                className="text-danger form-text"
            >
                {errorMessage}
            </span>
            <Button
                id="f7925369-a657-4585-9adf-56d2e8f5b856"
                className="btn btn-sm btn-primary mt-3 float-end"
                type="submit"
                disabled={!stripe}
                isLoading={isLoading}
            >
                {t(
                    "settingsPage.paymentMethodsSettings.addNewPaymentMethod.addPaymentMethod"
                )}
            </Button>
        </form>
    );
};

export default PaymentMethodSetupForm;
