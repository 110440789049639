import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import SupportPetitionForm from "./SupportPetitionForm/SupportPetitionForm";
import Petition from "../../../../models/Petition";
import { useTranslation } from "react-i18next";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { FALLBACK_LOCALE, LOCALE } from "../../../../constants";
import { StripeElementLocale } from "@stripe/stripe-js/types/stripe-js/elements-group";
import { HTMLAttributes } from "react";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PK!, {
    locale: (localStorage.getItem(LOCALE) ||
        FALLBACK_LOCALE) as StripeElementLocale,
});

interface Props extends HTMLAttributes<any> {
    petition: Petition;
    onSupport: (petition: Petition) => void;
}

const SupportPetitionOverlay = ({ petition, onSupport, id }: Props) => {
    const { t } = useTranslation();
    return (
        <ModalOverlay id={id}>
            <h6 id="5e96a448-fc45-462c-8cba-cf716343a679">
                {t("modal.overlays.supportPetition.supportPetition")}
            </h6>
            <hr id="cbb1ac5b-f8b1-49ee-920a-de5ac05085e8" />
            <Elements stripe={stripe}>
                <SupportPetitionForm
                    id="53e00148-5a96-4184-8d96-995916d3e130"
                    petition={petition}
                    onSupport={onSupport}
                />
            </Elements>
        </ModalOverlay>
    );
};

export default SupportPetitionOverlay;
