class PaymentMethod {
    readonly id: string;
    readonly brand: string;
    readonly exp_month: number;
    readonly exp_year: number;
    readonly is_default: boolean;
    readonly is_expired: boolean;
    readonly last4: string;
    readonly country: string;

    constructor(data: any) {
        this.id = data.id;
        this.brand = data.brand;
        this.exp_month = data.exp_month;
        this.exp_year = data.exp_year;
        this.is_default = data.is_default;
        this.is_expired = data.is_expired;
        this.last4 = data.last4;
        this.country = data.country;
    }
}

export default PaymentMethod;
