import ExternalPlatform from "./ExternalPlatform";

class ExternalLink {
    readonly uuid: string;
    readonly url: string;
    readonly type: ExternalPlatform;
    readonly primary: boolean;
    readonly verified: boolean;

    constructor(data: any) {
        this.uuid = data.uuid;
        this.url = data.url;
        this.type = new ExternalPlatform(data.type);
        this.primary = data.primary;
        this.verified = data.verified;
    }
}

export default ExternalLink;
