import { listNotifications as api_listNotifications } from "../../api/notifications/listNotifications";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Notification from "../../models/Notification";
import Spinner from "../../components/UI/Spinner/Spinner";
import NotificationsList from "./NotificationsList/NotificationsList";
import NotificationContext from "../../contexts/notification-context";
import { parseCursor } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

const NotificationsPage = () => {
    const { t } = useTranslation();
    const { markAllNotificationsAsStale } = useContext(NotificationContext);
    const [isLoading, setIsLoading] = useState(true);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getNotifications = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!hasMore) return;
            setIsLoading(true);
            try {
                const response = await api_listNotifications(cursor);
                if (cursor === undefined) setNotifications(response.results);
                else
                    setNotifications((prev: Notification[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
                markAllNotificationsAsStale();
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [markAllNotificationsAsStale]
    );

    useEffect(() => {
        setNotifications([]);
        setCursor(undefined);
        setHasMore(true);
        getNotifications(undefined, true);
    }, [getNotifications]);

    return (
        <div id="cdfbbee7-8fab-411a-adcd-2effdbd1a145">
            <h6 id="7a368f90-4372-4016-8aff-b25dffafafc9" className="mb-3">
                {t("navbar.notifications")}
            </h6>
            <hr id="2fd8b062-88bf-4415-b515-13a2ee5ae7d1" />
            {!!notifications.length && (
                <NotificationsList
                    id="f090f3a3-e0e0-4cd2-a67d-816cd8390dcf"
                    notifications={notifications}
                    getNotifications={() => getNotifications(cursor, hasMore)}
                    isLoading={isLoading}
                />
            )}
            {isLoading && (
                <Spinner
                    id="e30f243b-64dd-4796-aa80-53e3c2455d62"
                    className="mt-3"
                />
            )}
            {!isLoading && !notifications.length && (
                <div
                    id="76d81541-2740-47ff-9f52-0c8132d4d051"
                    className="d-flex justify-content-center form-text"
                >
                    {t("notificationsPage.noNotificationsFound")}
                </div>
            )}
        </div>
    );
};

export default NotificationsPage;
