import { createContext, useCallback, useState } from "react";
import { getNewNotificationsCount as api_getNewNotificationsCount } from "../api/notifications/getNewNotificationsCount";
import { markAllNotificationsAsStale as api_markAllNotificationsAsStale } from "../api/notifications/markAllNotificationsAsStale";

const defaultState = {
    newNotificationsCount: 0,
    getNewNotificationsCount: () => {},
    markAllNotificationsAsStale: () => {},
};

const NotificationContext = createContext<{
    newNotificationsCount: number;
    getNewNotificationsCount: () => void;
    markAllNotificationsAsStale: () => void;
}>(defaultState);

export const NotificationContextProvider = ({
    children,
}: {
    children: JSX.Element;
}) => {
    const [newNotificationsCount, setNewNotificationsCount] =
        useState<number>(0);

    const getNewNotificationsCount = useCallback(async () => {
        try {
            const response = await api_getNewNotificationsCount();
            setNewNotificationsCount(response);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const markAllNotificationsAsStale = useCallback(async () => {
        try {
            await api_markAllNotificationsAsStale();
            setNewNotificationsCount(0);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const contextValue = {
        newNotificationsCount,
        getNewNotificationsCount,
        markAllNotificationsAsStale,
    };

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationContext;
