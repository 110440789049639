import PetitionsListItem from "./PetitionsListItem/PetitionsListItem";
import Petition from "../../models/Petition";
import {
    ComponentProps,
    HTMLAttributes,
    Ref,
    useCallback,
    useRef,
} from "react";

interface Props extends ComponentProps<any>, HTMLAttributes<any> {
    petitions: Petition[];
    getPetitions: () => void;
    isLoading: boolean;
}

const PetitionsList = ({
    petitions,
    getPetitions,
    isLoading,
    className,
    id,
}: Props) => {
    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback(
        (node: Element) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => {
                    if (entries[0].isIntersecting) {
                        getPetitions();
                    }
                }
            );
            if (node) observer.current.observe(node);
        },
        [isLoading, getPetitions]
    ) as Ref<HTMLDivElement>;

    const renderPetitionsList = petitions.map(
        (petition: Petition, index: number) => {
            return (
                <PetitionsListItem
                    id={`petition-${petition.uuid}`}
                    key={petition.uuid}
                    petition={petition}
                    ref={
                        petitions.length === index + 1
                            ? lastElementRef
                            : undefined
                    }
                />
            );
        }
    );

    return (
        <div id={id} className={`${className} d-flex flex-column gap-3`}>
            {renderPetitionsList}
        </div>
    );
};

export default PetitionsList;
