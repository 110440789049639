import React, { useContext } from "react";
import ProfileImageForm from "./ProfileImageForm/ProfileImageForm";
import ProfileInfoForm from "./ProfileInfoForm/ProfileInfoForm";
import InvitationLinkForm from "./InvitationLinkForm/InvitationLinkForm";
import AuthContext from "../../../../contexts/auth-context";
import ProfileAboutForm from "./ProfileAboutForm/ProfileAboutForm";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "../../SettingsPage";

const ProfileSettingsPage = () => {
    const { sc, scrollMarginTop } = useOutletContext<OutletContextType>();
    const { user } = useContext(AuthContext);

    return (
        <div
            id="38552714-8d7f-44b4-a89e-826263871f5b"
            className="d-flex flex-column gap-3"
        >
            <ProfileImageForm id="dd2e1e7f-ab28-4749-b2c2-a8a8973c2074" />
            <ProfileInfoForm
                id="info"
                className={
                    sc === "info" ? "border-3 border-primary-subtle" : undefined
                }
            />
            {user?.is_creator && (
                <>
                    <ProfileAboutForm
                        id="about"
                        className={
                            sc === "about"
                                ? "border-3 border-primary-subtle"
                                : undefined
                        }
                    />
                    <InvitationLinkForm
                        id="invitation"
                        className={
                            sc === "invitation"
                                ? "border-3 border-primary-subtle"
                                : undefined
                        }
                    />
                </>
            )}
        </div>
    );
};

export default ProfileSettingsPage;
