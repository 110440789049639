import { createContext, useEffect, useState } from "react";
import {
    DEFAULT_PETITION_DURATION_IN_DAYS,
    MIN_PETITION_PLEDGE_AMOUNT,
} from "../constants";
import {
    PledgeSourceType,
    PledgeSourceTypes,
} from "../components/SelectPledgeSourceForm/SelectPledgeSourceForm";

class DraftPetition {
    readonly title: string;
    readonly description: string;
    readonly addresseeUsername: string;
    readonly petitionDurationInDays: number;
    readonly pledgeAmount: number;
    readonly pledgeSource: PledgeSourceType;

    constructor(data: any) {
        this.title = data.title;
        this.description = data.description;
        this.addresseeUsername = data.addresseeUsername;
        this.petitionDurationInDays = data.petitionDurationInDays;
        this.pledgeAmount = data.pledgeAmount;
        this.pledgeSource = data.pledgeSource;
    }
}

const defaultState = {
    draftPetition: undefined,
    saveDraftPetition: (
        title: string,
        description: string,
        addresseeUsername: string,
        petitionDurationInDays: number,
        pledgeAmount: number,
        pledgeSource: PledgeSourceType
    ) => {},
    getDraftPetition: () => {},
    removeDraftPetition: () => {},
};

const DraftPetitionContext = createContext<{
    draftPetition: DraftPetition | undefined;
    saveDraftPetition: (
        title: string,
        description: string,
        addresseeUsername: string,
        petitionDurationInDays: number,
        pledgeAmount: number,
        pledgeSource: PledgeSourceType
    ) => void;
    getDraftPetition: () => void;
    removeDraftPetition: () => void;
}>(defaultState);

export const DraftPetitionContextProvider = ({
    children,
}: {
    children: JSX.Element;
}) => {
    const emptyDraft = JSON.stringify({
        title: "",
        description: "",
        addresseeUsername: "",
        petitionDurationInDays: DEFAULT_PETITION_DURATION_IN_DAYS,
        pledgeAmount: MIN_PETITION_PLEDGE_AMOUNT,
        pledgeSource: PledgeSourceTypes.BALANCE,
    });
    const [draftPetitionStr, setDraftPetitionStr] = useState<string | null>(
        null
    );
    const [draftPetition, setDraftPetition] = useState<DraftPetition>();

    useEffect(() => {
        setDraftPetitionStr(localStorage.getItem("draftPetition"));
    }, []);

    useEffect(() => {
        if (draftPetitionStr && draftPetitionStr != emptyDraft) {
            setDraftPetition(new DraftPetition(JSON.parse(draftPetitionStr)));
            localStorage.setItem("draftPetition", draftPetitionStr);
        } else {
            localStorage.removeItem("draftPetition");
            setDraftPetition(undefined);
        }
    }, [draftPetitionStr]);

    const getDraftPetition = () => {
        setDraftPetitionStr(localStorage.getItem("draftPetition"));
    };

    const saveDraftPetition = (
        title: string,
        description: string,
        addresseeUsername: string,
        petitionDurationInDays: number,
        pledgeAmount: number,
        pledgeSource: PledgeSourceType
    ) => {
        // the order of fields while stringify-ing should match with the one in emptyDraft
        setDraftPetitionStr(
            JSON.stringify({
                title: title,
                description: description,
                addresseeUsername: addresseeUsername,
                petitionDurationInDays: petitionDurationInDays,
                pledgeAmount: pledgeAmount,
                pledgeSource: pledgeSource,
            })
        );
    };

    const removeDraftPetition = () => {
        setDraftPetitionStr(null);
    };

    const contextValue = {
        draftPetition,
        getDraftPetition,
        saveDraftPetition,
        removeDraftPetition,
    };

    return (
        <DraftPetitionContext.Provider value={contextValue}>
            {children}
        </DraftPetitionContext.Provider>
    );
};

export default DraftPetitionContext;
