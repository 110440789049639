import React, { HTMLAttributes, useEffect, useState } from "react";
import InputGroup from "../UI/InputGroup/InputGroup";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {
    onSearchTermChange: (searchTerm: string) => void;
    creatorsOnly?: boolean;
}
let timeout: NodeJS.Timeout;
const SearchUserForm = ({
    id,
    onSearchTermChange,
    creatorsOnly = false,
}: Props) => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            onSearchTermChange(searchTerm);
        }, 1000);
    });

    const placeholder = creatorsOnly
        ? t("searchUserForm.findCreator")
        : t("searchUserForm.findUser");

    return (
        <form id={id}>
            <InputGroup
                id="d08451c0-f700-4132-a500-bf0a66e95135"
                className="input-group-sm"
                type="text"
                autoFocus={true}
                placeholder={placeholder}
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
            />
        </form>
    );
};

export default SearchUserForm;
