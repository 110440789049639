import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import classes from "./AboutPage.module.scss";

const AboutPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div
                id="8a042b2f-19d9-449d-94c2-5cd476201afa"
                className={`d-flex flex-column gap-3 p-3 mb-5 form-text text-dark container ${classes.content}`}
            >
                <h2 id="29f0bb02-1f28-424c-ade9-72e8efe6e396" className="mb-3">
                    About
                </h2>
                <div id="f42ec341-0f31-44b8-a3cd-d243b154cae7">
                    <h5 id="a9068ee1-89f6-409f-9a99-c5537d79d8d7">
                        What is Ablebees?
                    </h5>
                    <p id="6abd64ec-a409-4a0e-814e-7e8a50a0a6d7">
                        Ablebees is a platform where followers can rally
                        together to request specific content from their favorite
                        creators. Think of it as a community-powered way to
                        suggest and fund ideas for the content you’d love to
                        see!
                    </p>
                </div>
                <div id="19f1431a-d9b6-413d-8f46-09369abf3cd1">
                    <h5 id="5c6071fa-8096-4418-9344-21f6fd2e99ef">
                        How does Ablebees work?
                    </h5>
                    <ul
                        id="a552680a-25fc-469b-b26e-c684c18754f9"
                        className="d-flex flex-column gap-3 mt-4"
                    >
                        <li id="5276eb8f-f080-407a-9c1a-f4ef1d58d431">
                            A follower starts a petition asking a creator to
                            make something specific—whether it’s a video,
                            article, podcast, or other digital content.
                        </li>
                        <li id="288ab44c-5c7a-45fb-abb1-52d7e2241556">
                            Other followers who like the idea can join in,
                            showing their support.
                        </li>
                        <li id="a68fcc64-65fc-41f0-932a-f2abb1b12398">
                            Together, everyone pledges an amount they’re
                            comfortable with, creating a mini crowdfund to
                            encourage the creator.
                        </li>
                        <li id="7ea1ac1b-9756-4b34-b0ec-6a0376ac7faf">
                            The creator can check out each petition, seeing both
                            the number of supporters and the funds raised,
                            making it easy to see which ideas have the most
                            excitement.
                        </li>
                        <li id="4fa8590e-a2d4-45eb-9f07-319e6045d11b">
                            Each petition is open for a set number of days,
                            during which the creator can choose to accept it.
                        </li>
                        <li id="62b8b470-91d9-4cad-9bf4-48744e6be382">
                            When the content is ready, the creator posts it on
                            their usual platform (like YouTube or Medium),
                            shares the link on Ablebees, and marks it as
                            fulfilled so they can receive the funds.
                        </li>
                        <li id="312792a6-baa4-4b08-9c87-8356dcacceab">
                            Followers who participated in the petition can rate
                            the content after it’s posted, giving the creator
                            helpful feedback on how it turned out.
                        </li>
                    </ul>
                </div>
                <div id="db270ead-4e64-407b-95ba-67e6d4438209">
                    <h5 id="9888cab1-18c9-435b-a40f-d9c3e4fedc59">
                        How do followers benefit?
                    </h5>
                    <p id="9a19728e-5fc2-4cdf-b2ab-8f11205a86ae">
                        If you’ve ever wanted to see your favorite creator
                        tackle a specific topic, Ablebees gives you a way to
                        make that happen! You can find others who feel the same,
                        and by pitching in a bit of support, you’re showing the
                        creator there’s real interest in the idea.
                    </p>
                </div>
                <div id="33d9c025-a63f-4419-bfcc-ed94a0d23b84">
                    <h5 id="b88b5280-f39b-40d2-96a7-293ab57a655e">
                        How do creators benefit?
                    </h5>
                    <p id="523c6933-c5c6-4131-af6f-bcfda17cfea7">
                        For creators, Ablebees provides a steady stream of fresh
                        content ideas directly from the people who matter most:
                        your audience! You get to see what excites your
                        followers, fund production from the pledges, and build
                        stronger connections by engaging with their requests.
                        Plus, when you create content your followers ask for,
                        they’re likely to share it around, helping grow your
                        audience.
                    </p>
                </div>
                <div id="1c4492f4-ee9c-427c-b40c-587a07a920bb">
                    <h5 id="10f6bee6-9ffc-43bf-af9d-59959b84780c">
                        What’s new about Ablebees?
                    </h5>
                    <p id="6d04bed1-66bc-4d92-acf8-05984beb51a9">
                        Ablebees creates a new kind of relationship between
                        creators and their followers. It brings together
                        like-minded followers to show the hidden demand for
                        ideas and helps creators tap into that demand in a
                        meaningful way. Everyone wins: followers get the content
                        they want, and creators get new inspiration and a
                        growing audience!
                    </p>
                </div>
            </div>
            <Footer id="c0a434e5-8542-4d38-8ac9-9fd59d627063" />
        </>
    );
};

export default AboutPage;
