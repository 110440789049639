import BalanceSettings from "./BalanceSettings/BalanceSettings";
import React, { useContext, useEffect, useState } from "react";
import {
    FailureResponse as UpdateAccountFailureResponse,
    updateAccount as api_updateAccount,
} from "../../../../api/users/updateAccount";
import AlertOverlay from "../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../contexts/modal-context";
import PayoutBalanceSettings from "./PayoutBalanceSettings/PayoutBalanceSettings";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import CurrencySettings from "./CurrencySettings/CurrencySettings";
import { ApiErrorCode } from "../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AuthContext from "../../../../contexts/auth-context";
import AcceptPledgesSettings from "./AcceptPledgesSettings/AcceptPledgesSettings";
import BecomeCreatorSettings from "./BecomeCreatorSettings/BecomeCreatorSettings";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "../../SettingsPage";

const AccountSettingsPage = () => {
    const { sc, scrollMarginTop } = useOutletContext<OutletContextType>();
    const { user, refreshUser } = useContext(AuthContext);
    const { showModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        refreshUser();
    }, []);

    const updateAccount = async (payment_settings?: {
        currency?: string;
        accepts_pledges?: boolean;
    }) => {
        setIsLoading(true);
        try {
            await api_updateAccount(payment_settings);
            refreshUser();
        } catch (error) {
            const { errors, status_code, code } =
                error as UpdateAccountFailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                id="6f81bf6a-ec30-4665-b664-f56bab5cf5ad"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else {
                        showModal(
                            <AlertOverlay
                                id="7610e475-09d1-4e67-8375-43f05d3e9666"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    }
                } else console.log(errors);
            }
        }
        setIsLoading(false);
    };

    return (
        <div
            id="07347298-9174-44c8-b7ff-4423500f2431"
            className="d-flex flex-column gap-3"
        >
            {isLoading && (
                <Spinner
                    id="541143ef-5aa8-49bb-b74d-466e2fede602"
                    className="text-dark"
                />
            )}
            {!user?.account!.payment_settings.currency && (
                <div
                    id="b12fe652-4263-4df1-8d9e-b21d24634c99"
                    className="border rounded-3 p-3"
                >
                    {isLoading && (
                        <Spinner
                            id="1ab485ea-d9ae-41a9-acb4-884bf74426c1"
                            className="text-dark"
                        />
                    )}
                    <CurrencySettings
                        id="d73c400f-38c3-437c-9dc5-acf4f9703ddf"
                        isLoading={isLoading}
                        updateAccount={(currency: string) =>
                            updateAccount({
                                currency,
                            })
                        }
                    />
                </div>
            )}
            {user?.account!.payment_settings.currency && (
                <>
                    <div
                        id="a20e6419-b358-4709-8db7-c0ef835d4f46"
                        className="border rounded-3 p-3"
                    >
                        {isLoading && (
                            <Spinner
                                id="2b3daa9c-67b0-4a39-bb8d-8724254af680"
                                className="text-dark"
                            />
                        )}
                        <BalanceSettings id="83c2e5e9-faf1-46f6-971f-395d30519461" />
                    </div>
                    {user?.is_creator && (
                        <div
                            id="0b890d84-b732-4243-9224-218d0aaee5a5"
                            className="border rounded-3 p-3"
                        >
                            {isLoading && (
                                <Spinner
                                    id="6f5c5965-e1b9-414b-99fb-0fdd30508535"
                                    className="text-dark"
                                />
                            )}
                            <PayoutBalanceSettings id="e9a72e22-d0fa-403f-967a-1fd114ae86b0" />
                        </div>
                    )}
                </>
            )}
            {user?.is_creator &&
                (!user.account!.payment_settings.can_accept_pledges ||
                    !user.account!.payment_settings.accepts_pledges) && (
                    <div
                        id="c8a02664-d012-40b2-99d0-cbec5176d8e7"
                        className="border rounded-3 p-3"
                    >
                        {isLoading && (
                            <Spinner
                                id="bed7ab80-af60-49af-a68c-25905bb763ac"
                                className="text-dark"
                            />
                        )}
                        <AcceptPledgesSettings
                            id="1a02c493-4d2e-4f08-bf7a-7097d764df1e"
                            account={user.account!}
                        />
                    </div>
                )}
            {!user?.is_creator && (
                <div
                    id="become-creator"
                    className={`border rounded-3 p-3 ${
                        sc === "become-creator" &&
                        "border-3 border-primary-subtle"
                    }`}
                >
                    <BecomeCreatorSettings id="52a269ec-8283-419c-be75-67049fd8d162" />
                </div>
            )}
        </div>
    );
};

export default AccountSettingsPage;
