import React, { HTMLAttributes, useContext } from "react";
import ClipboardCopy from "../../../../../components/UI/ClipboardCopy/ClipboardCopy";
import AuthContext from "../../../../../contexts/auth-context";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "../../../SettingsPage";

interface Props extends HTMLAttributes<any> {}

const InvitationLinkForm = ({ id, className }: Props) => {
    const { t } = useTranslation();
    const { scrollMarginTop } = useOutletContext<OutletContextType>();
    const { user } = useContext(AuthContext);
    const invitationLink = `${process.env.REACT_APP_BASE_URL}/team/${user?.username}`;
    return (
        <div
            id={id}
            className={`border rounded-3 p-3 ${className}`}
            style={{ scrollMarginTop: scrollMarginTop }}
        >
            <h6 id="a3fe6376-65f9-42c0-903d-8ab2ba525e59">
                {t(
                    "settingsPage.profileSettings.invitationLinkForm.shareInvitationLink"
                )}
            </h6>
            <hr id="a5726500-abde-449b-a809-14793c03648c" />
            {user?.is_email_verified && (
                <ClipboardCopy
                    id="e092a055-035a-46a6-b0cf-00efdb90f6fe"
                    copyText={invitationLink}
                    btnText={t("clipboardCopy.copyLink")}
                />
            )}
            {!user?.is_email_verified && (
                <span id="6380bde4-e7e0-41ab-acbb-ac81275fec22">
                    {t(
                        "settingsPage.profileSettings.invitationLinkForm.verifyYourEmail"
                    )}
                </span>
            )}
        </div>
    );
};

export default InvitationLinkForm;
