import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/auth-context";
import { listArticles as api_listArticles } from "../../api/blog/listArticles";
import Article from "../../models/Article";
import Spinner from "../../components/UI/Spinner/Spinner";
import Footer from "../../components/Footer/Footer";
import classes from "./Blog.module.scss";

const Blog = () => {
    const { hideNavbar } = useContext(AuthContext);
    const [articles, setArticles] = useState<Article[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // hide navbar when visiting the page
        hideNavbar(true);
        getArticles();
        return () => {
            // show navbar when leaving the page
            hideNavbar(false);
        };
    }, []);

    const getArticles = async () => {
        setIsLoading(true);
        try {
            const response = await api_listArticles();
            setArticles(response.results);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const renderedArticles = articles.map((item: Article) => (
        <div
            id="29f63dec-aa88-4009-8b16-2dbc926fe90c"
            className="col"
            key={item.slug}
        >
            <a
                id="d15b80f5-357a-41ec-a0a0-2b4f9b7f540d"
                href={item.url}
                target="_blank"
                className="card shadow-sm text-decoration-none"
            >
                <img
                    id="d09b1960-044f-4cd7-a38b-e96648f39bb8"
                    src={item.thumbnail_url}
                    width="100%"
                    height="225"
                    alt=""
                    className="card-img-top"
                    style={{ objectFit: "cover" }}
                />
                <div
                    id="06899d40-528b-42e9-b349-69964e112989"
                    className="card-body"
                >
                    <h5
                        id="f58471af-02b8-44a9-a469-e75dfb4ee7ee"
                        className="mt-2"
                    >
                        {item.title}
                    </h5>
                    <div
                        id="a724adc0-1745-471d-b988-64fa6a4d89df"
                        className="d-flex justify-content-between align-items-center"
                    >
                        <small
                            id="ef1ee057-1010-4171-a494-5c21cf69e815"
                            className="text-body-secondary"
                        >
                            {item.dt_published}
                        </small>
                    </div>
                </div>
            </a>
        </div>
    ));

    return (
        <div
            id="df8e6460-7a98-4d78-a2a6-dcfe97413f1c"
            className="row d-flex flex-column align-items-center justify-content-between"
        >
            <div
                id="33e2504a-26a1-441d-9125-42a949446851"
                className={`${classes.content}`}
            >
                {isLoading && (
                    <Spinner id="b0dc83ec-4335-4950-9549-de89d6f82dd5" />
                )}
                {!isLoading && (
                    <>
                        <section
                            id="c5b11ee7-4cdf-4a0b-9323-325c31c9aed2"
                            className="py-5 text-center container"
                        >
                            <div
                                id="7b3cf596-484c-490d-9c5d-e358c72c5478"
                                className="row"
                            >
                                <div
                                    id="eb3ee62f-9b3b-442b-9755-5a24450802b1"
                                    className="col-lg-6 col-md-8 mx-auto"
                                >
                                    <h1
                                        id="a1ee162a-808b-4089-9411-12a9e839ca8b"
                                        className="fw-light mb-3"
                                    >
                                        Welcome to Ablebees blog!
                                    </h1>
                                    <p
                                        id="0d881bba-849d-4a9a-9ddf-2f775cfa17be"
                                        className="lead text-body-secondary"
                                    >
                                        Here, we share our thoughts, product
                                        updates and anything else you may find
                                        interesting about what we do.
                                    </p>
                                </div>
                            </div>
                        </section>
                        <div
                            id="3700f7ce-660e-41d7-ae14-9d97f4b92325"
                            className="py-5"
                        >
                            <div
                                id="494ce795-b9c0-4fba-abbc-80f122144efb"
                                className="container"
                            >
                                <div
                                    id="3ae97b7d-23b8-4881-a667-1410e91fea01"
                                    className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3"
                                >
                                    {renderedArticles}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <Footer id="49b9ad9b-1ec7-47d9-8c51-0af24bf08f40" />
        </div>
    );
};

export default Blog;
