export const FALLBACK_LOCALE: string = "en";
export const MAX_PETITION_TITLE_LENGTH: number = 100;
export const MAX_PETITION_DESCRIPTION_LENGTH: number = 500;
export const MIN_PETITION_PLEDGE_AMOUNT = 1.0;
export const MAX_PETITION_PLEDGE_AMOUNT = 1000.0;
export const MIN_TOP_UP_BALANCE_AMOUNT = 1.0;
export const MAX_TOP_UP_BALANCE_AMOUNT = 1000.0;
export const MIN_PAYOUT_BALANCE_AMOUNT = 100.0;
export const MIN_PETITION_END_RESULT_RATE: number = 1;
export const MAX_PETITION_END_RESULT_RATE: number = 10;
export const MAX_PETITION_END_RESULT_RATE_COMMENT_LENGTH: number = 500;
export const MAX_PETITION_REJECTION_REASON_LENGTH: number = 500;
export const MIN_PETITION_DURATION_IN_DAYS = 7;
export const MAX_PETITION_DURATION_IN_DAYS = 365;
export const DEFAULT_PETITION_DURATION_IN_DAYS = 90;
export const MIN_FAVORITE_CREATORS = 3;
export const MAX_FAVORITE_CREATORS = 5;
export const MAX_HEADLINE_LENGTH = 50;
export const MAX_DESCRIPTION_LENGTH = 500;

// API Error codes
export const enum ApiErrorCode {
    EMAIL_NOT_VERIFIED = "email_not_verified",
    CONFLICT_ERROR = "conflict_error",
    NOT_FOUND = "not_found",
    ERROR = "error",
    CURRENCY_NOT_SET = "currency_not_set",
    NO_PAYOUT_METHOD = "no_payout_method",
    NO_PAYMENT_METHOD = "no_payment_method",
    PAYOUTS_NOT_ALLOWED = "payouts_not_allowed",
    NOT_ENOUGH_BALANCE = "not_enough_balance",
}

// ENV vars
export const SIGNUP_BY_INVITE_ONLY =
    !!process.env.REACT_APP_SIGNUP_BY_INVITE_ONLY &&
    ["true", "1", "yes", "y", "t"].includes(
        process.env.REACT_APP_SIGNUP_BY_INVITE_ONLY
    );

// Local Storage Keys
export const LOCALE: string = "locale";
