import Petition, { PetitionStatus } from "../../../../models/Petition";
import PetitionAddressee, {
    PetitionAddresseeStatus,
} from "../../../../models/PetitionAddressee";
import { TbHeartHandshake } from "react-icons/tb";
import { BsShare } from "react-icons/bs";
import { MdClear, MdDone, MdDoneAll, MdStarBorder } from "react-icons/md";
import React, { HTMLAttributes, useContext } from "react";
import AuthContext from "../../../../contexts/auth-context";
import Button from "../../../UI/Button/Button";
import { GoLinkExternal } from "react-icons/go";
import ClipboardCopy from "../../../UI/ClipboardCopy/ClipboardCopy";
import { useTranslation } from "react-i18next";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    XIcon,
    LinkedinShareButton,
    LinkedinIcon,
} from "react-share";

interface Props extends HTMLAttributes<any> {
    petition: Petition;
    onCancelButtonClick: () => void;
    onSupportButtonClick: () => void;
    onAcceptButtonClick: (addressee_uuid: string) => void;
    onRejectButtonClick: (addressee_uuid: string) => void;
    onMarkAsFulfilledButtonClick: () => void;
    onRateEndResultButtonClick: () => void;
    acceptLoading: boolean;
    rejectLoading: boolean;
    markAsFulfilledLoading: boolean;
    cancelLoading: boolean;
    supportLoading: boolean;
}

const PetitionActionButtons = ({
    id,
    petition,
    onCancelButtonClick: cancelButtonClickHandler,
    onSupportButtonClick: supportButtonClickHandler,
    onAcceptButtonClick: acceptButtonClickHandler,
    onRejectButtonClick: rejectButtonClickHandler,
    onMarkAsFulfilledButtonClick: markAsFulfilledButtonClickHandler,
    onRateEndResultButtonClick: rateEndResultButtonClickHandler,
    acceptLoading,
    rejectLoading,
    markAsFulfilledLoading,
    cancelLoading,
    supportLoading,
}: Props) => {
    const { t } = useTranslation();
    const petitionUrl = `${
        process.env.REACT_APP_API_BASE_URL || process.env.REACT_APP_BASE_URL
    }/ssr/petitions/${petition.uuid}`;
    const { user } = useContext(AuthContext);
    const shareButton = (
        <div id="ad381259-b984-44f4-8481-7ae51f229923" className="dropup">
            <button
                id="6714e628-aa50-4561-95f0-d4f280e32b44"
                type="button"
                className="btn btn-sm btn-link text-decoration-none text-dark"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <BsShare
                    id="6714e628-aa50-4561-95f0-d4f280e32b44-icon"
                    className="mb-1 me-1"
                />{" "}
                {t("petition.buttons.share")}
            </button>
            <ul
                id="75873c87-701d-43fe-9757-d5f0716a4b79"
                className="dropdown-menu dropdown-menu-end"
                style={{ width: "16rem" }}
            >
                {/*<li className="px-3 py-2">{t("petition.sharePetitionLink")}</li>*/}
                {/*<li>*/}
                {/*    <hr className="dropdown-divider" />*/}
                {/*</li>*/}
                <li
                    id="10b8ed4f-0035-4561-9c7b-e56f4be5e00a"
                    className="px-3 py-2 d-flex gap-2"
                >
                    <FacebookShareButton
                        id="b1a1bd32-09b7-44ac-8534-7daaad3ff06b"
                        url={petitionUrl}
                    >
                        <FacebookIcon
                            id="b1a1bd32-09b7-44ac-8534-7daaad3ff06b-icon"
                            size={32}
                            round
                        />
                    </FacebookShareButton>
                    <TwitterShareButton
                        id="30bf3599-35ee-4ebd-9627-e1d77ec137f4"
                        url={petitionUrl}
                    >
                        <XIcon
                            id="30bf3599-35ee-4ebd-9627-e1d77ec137f4-icon"
                            size={32}
                            round
                        />
                    </TwitterShareButton>
                    <LinkedinShareButton
                        id="99746d01-047b-466c-ba88-358bbeeca15f"
                        url={petitionUrl}
                    >
                        <LinkedinIcon
                            id="99746d01-047b-466c-ba88-358bbeeca15f-icon"
                            size={32}
                            round
                        />
                    </LinkedinShareButton>
                    <ClipboardCopy
                        id="3a400a48-f58f-4af9-bf2d-429ae9be4725"
                        copyText={petitionUrl}
                        btnText={t("clipboardCopy.copyLink")}
                    />
                </li>
            </ul>
        </div>
    );
    let supportButton = <></>;
    let cancelButton = <></>;
    let acceptButton = <></>;
    let rejectButton = <></>;
    let markAsFulfilledButton = <></>;
    let viewEndResultButton = <></>;
    let rateEndResultButton = <></>;

    if (petition.am_i_addressee) {
        const addressee = petition.addressees.find(
            (item: PetitionAddressee) => item.user.uuid === user!.uuid
        )!;

        switch (petition.status) {
            case PetitionStatus.ONGOING:
                switch (addressee.status) {
                    case PetitionAddresseeStatus.PENDING:
                        acceptButton = (
                            <Button
                                id="0743eb00-9578-4801-96ca-078c692951f8"
                                className="btn btn-sm btn-link text-decoration-none text-success"
                                title={t("petition.buttons.accept")}
                                onClick={() =>
                                    acceptButtonClickHandler(addressee.uuid)
                                }
                                isLoading={acceptLoading}
                                disabled={acceptLoading || rejectLoading}
                            >
                                <MdDone
                                    id="0743eb00-9578-4801-96ca-078c692951f8-icon"
                                    className="mb-1"
                                />{" "}
                                {t("petition.buttons.accept")}
                            </Button>
                        );
                        rejectButton = (
                            <Button
                                id="4d25a4e1-1eef-4977-bd9e-26b3353a3276"
                                className="btn btn-sm btn-link text-decoration-none text-danger"
                                title={t("petition.buttons.reject")}
                                onClick={() =>
                                    rejectButtonClickHandler(addressee.uuid)
                                }
                                isLoading={rejectLoading}
                                disabled={acceptLoading || rejectLoading}
                            >
                                <MdClear
                                    id="4d25a4e1-1eef-4977-bd9e-26b3353a3276-icon"
                                    className="mb-1"
                                />{" "}
                                {t("petition.buttons.reject")}
                            </Button>
                        );
                        break;
                    case PetitionAddresseeStatus.ACCEPTED:
                        acceptButton = (
                            <Button
                                id="117d4904-96fe-475f-b1b2-4fb71e82a88b"
                                className="btn btn-sm btn-link text-decoration-none text-success"
                                title={t("petition.buttons.accepted")}
                                disabled
                            >
                                <MdDone
                                    id="117d4904-96fe-475f-b1b2-4fb71e82a88b-icon"
                                    className="mb-1"
                                />{" "}
                                {t("petition.buttons.accepted")}
                            </Button>
                        );
                        break;
                }
                break;
            case PetitionStatus.REJECTED:
                rejectButton = (
                    <Button
                        id="5b867842-c5a9-473a-b712-63d36923699e"
                        className="btn btn-sm btn-link text-decoration-none text-danger"
                        title={t("petition.buttons.rejected")}
                        disabled
                    >
                        <MdClear
                            id="5b867842-c5a9-473a-b712-63d36923699e-icon"
                            className="mb-1"
                        />{" "}
                        {t("petition.buttons.rejected")}
                    </Button>
                );
                break;
            case PetitionStatus.ACCEPTED:
                markAsFulfilledButton = (
                    <Button
                        id="9abca30e-9d36-40d8-9111-75a79b4e98cc"
                        className="btn btn-sm btn-link text-decoration-none text-success"
                        title={t("petition.buttons.markAsFulfilled")}
                        onClick={markAsFulfilledButtonClickHandler}
                        isLoading={markAsFulfilledLoading}
                    >
                        <MdDoneAll
                            id="9abca30e-9d36-40d8-9111-75a79b4e98cc-icon"
                            className="mb-1"
                        />{" "}
                        {t("petition.buttons.markAsFulfilled")}
                    </Button>
                );
                break;
        }
    } else {
        if (petition.am_i_supporter) {
            supportButton = (
                <Button
                    id="c7c35b13-aa0a-4c7a-a39e-30229a505e23"
                    className="btn btn-sm btn-link text-decoration-none"
                    title={t("petition.buttons.supported")}
                    disabled
                >
                    <TbHeartHandshake
                        id="c7c35b13-aa0a-4c7a-a39e-30229a505e23-icon"
                        className="mb-1"
                    />{" "}
                    {t("petition.buttons.supported")}
                </Button>
            );

            // if (petition.am_i_author && petition.supporters_count === 1) {
            if (petition.am_i_author) {
                if (petition.status === PetitionStatus.ONGOING) {
                    cancelButton = (
                        <Button
                            id="b858ce17-9899-49a4-8fde-c47ef41169d1"
                            className="btn btn-sm btn-link text-decoration-none text-danger"
                            title={t("petition.buttons.cancel")}
                            onClick={cancelButtonClickHandler}
                            isLoading={cancelLoading}
                        >
                            <MdClear
                                id="b858ce17-9899-49a4-8fde-c47ef41169d1-icon"
                                className="mb-1"
                            />{" "}
                            {t("petition.buttons.cancel")}
                        </Button>
                    );
                } else if (petition.status === PetitionStatus.CANCELED) {
                    // cancelButton = (
                    //     <Button
                    //         className="btn btn-sm btn-link text-decoration-none text-danger"
                    //         title={t("petition.buttons.canceled")}
                    //         disabled
                    //     >
                    //         <MdClear className="mb-1" />{" "}
                    //         {t("petition.buttons.canceled")}
                    //     </Button>
                    // );
                }
            }
        } else if (petition.status === PetitionStatus.ONGOING) {
            supportButton = (
                <Button
                    id="9c540b6f-5d42-473a-867d-b8dbade8f989"
                    className="btn btn-sm btn-link text-decoration-none"
                    title={t("petition.buttons.support")}
                    onClick={supportButtonClickHandler}
                    isLoading={supportLoading}
                >
                    <TbHeartHandshake
                        id="9c540b6f-5d42-473a-867d-b8dbade8f989-icon"
                        className="mb-1"
                    />{" "}
                    {t("petition.buttons.support")}
                </Button>
            );
        }
    }

    if (petition.status === PetitionStatus.FULFILLED) {
        supportButton = <></>;
        if (petition.end_result) {
            viewEndResultButton = (
                <a
                    id="93f5db29-27a3-417f-886e-d54d8a5607c5"
                    className="btn btn-sm btn-link text-decoration-none text-dark"
                    href={petition.end_result.url}
                    target="_blank"
                    rel="noreferrer"
                >
                    <GoLinkExternal
                        id="93f5db29-27a3-417f-886e-d54d8a5607c5-icon"
                        className="mb-1"
                    />{" "}
                    {t("petition.buttons.viewEndResult")}
                </a>
            );

            if (petition.end_result.can_i_rate) {
                rateEndResultButton = (
                    <Button
                        id="f0056836-40ff-4686-a64a-2378e7839d71"
                        className="btn btn-sm btn-link text-decoration-none text-dark"
                        title={t("petition.buttons.rateEndResult")}
                        onClick={rateEndResultButtonClickHandler}
                    >
                        <MdStarBorder
                            id="f0056836-40ff-4686-a64a-2378e7839d71-icon"
                            className="mb-1"
                        />{" "}
                        {t("petition.buttons.rateEndResult")}
                    </Button>
                );
            }
        }
    }
    return (
        <div id={id} className="d-flex flex-fill justify-content-end gap-2">
            {supportButton}
            {cancelButton}
            {acceptButton}
            {rejectButton}
            {markAsFulfilledButton}
            {rateEndResultButton}
            {viewEndResultButton}
            {shareButton}
        </div>
    );
};

export default PetitionActionButtons;
