import React, {
    HTMLAttributes,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import {
    FailureResponse as PayOutFailureResponse,
    payOut as api_payOut,
} from "../../../../../api/payments/payOut";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import Button from "../../../../../components/UI/Button/Button";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../../contexts/modal-context";
import { parseDate } from "../../../../../utils/helpers";
import StripeBalance from "../../../../../models/StripeBalance";
import { getStripeBalance as api_getStripeBalance } from "../../../../../api/payments/getStripeBalance";
import { Link } from "react-router-dom";
import { ApiErrorCode } from "../../../../../constants";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../../contexts/auth-context";

interface Props extends HTMLAttributes<any> {}

const PayoutBalanceSettings = ({ id }: Props) => {
    const { t } = useTranslation();
    const { showModal } = useContext(ModalContext);
    const { user, refreshUser } = useContext(AuthContext);
    const [isPayoutLoading, setIsPayoutLoading] = useState(false);
    const [balance, setBalance] = useState<StripeBalance>();
    const [isLoadingBalance, setIsLoadingBalance] = useState(true);

    const getStripeBalance = useCallback(async () => {
        setIsLoadingBalance(true);
        try {
            const response = await api_getStripeBalance();
            setBalance(response);
        } catch (error) {
            console.log(error);
        }
        setIsLoadingBalance(false);
    }, []);

    useEffect(() => {
        if (user!.account!.payment_settings.is_stripe_account_set_up)
            getStripeBalance();
    }, [
        user,
        user?.account!.payment_settings.is_stripe_account_set_up,
        getStripeBalance,
    ]);

    const payoutButtonClickHandler = async () => {
        setIsPayoutLoading(true);
        try {
            const payouts = await api_payOut();
            const payout = payouts[0];
            const dt_arrival = parseDate(payout.arrival_date);
            let alert_status: "success" | "warning" | "error" = "success";
            let alert_message = `${t(
                "settingsPage.accountSettings.payoutBalanceSettings.payoutArrivalDate"
            )} ${dt_arrival}`;
            if (payout.status === "failed") {
                alert_status = "error";
                alert_message = payout.failure_message;
            }

            showModal(
                <AlertOverlay
                    id="f44b3552-6d8f-40d3-ac3a-fc39003e1d6d"
                    status={alert_status}
                    message={alert_message}
                />
            );
            refreshUser();
            await getStripeBalance();
        } catch (error) {
            const { errors, code } = error as PayOutFailureResponse;
            if (errors)
                showModal(
                    <AlertOverlay
                        id="56f51ccb-eee6-4776-9909-13a73bffc5a2"
                        status="error"
                        message={
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error!
                        }
                        link={
                            code === ApiErrorCode.PAYOUTS_NOT_ALLOWED && (
                                <Link
                                    // id="payout-balance-settings-payouts_not_allowed-alert-overlay-to-settings-payout"
                                    id="a907edd0-5681-4bc1-9dce-fcdae8c34a68"
                                    to="/settings/payout"
                                    className="btn btn-sm btn-primary"
                                >
                                    {t(
                                        "settingsPage.accountSettings.payoutBalanceSettings.payoutDetails"
                                    )}
                                </Link>
                            )
                        }
                    />
                );
        }
        setIsPayoutLoading(false);
    };

    return (
        <div id={id}>
            <h6 id="b852afcc-d3b6-4ff9-bce3-b3dae77aecf5">
                {t(
                    "settingsPage.accountSettings.payoutBalanceSettings.payOutEarnings"
                )}
            </h6>
            <hr id="b407068c-bbd8-4ce9-91b4-2b8c216375d7" />
            {!user?.account!.payment_settings.is_stripe_account_set_up && (
                <Link
                    // id="payout-balance-settings-to-settings-payout"
                    id="c3ee0dcf-b94c-4ab9-8ee1-fa59d05a2b78"
                    to="/settings/payout"
                    className="btn btn-sm btn-primary"
                >
                    {t(
                        "settingsPage.accountSettings.payoutBalanceSettings.setupPayoutAccount"
                    )}
                </Link>
            )}
            {user?.account!.payment_settings.is_stripe_account_set_up && (
                <div
                    id="de01d5e7-3d01-41f8-8732-3762182b4ab4"
                    className="d-flex gap-5 align-items-center justify-content-between"
                >
                    <div
                        id="cce3b648-fbf0-49cc-9733-006e24222513"
                        className="d-flex gap-2"
                    >
                        <span
                            id="c55de7f6-c933-4f35-9eb3-059a8d9fdc26"
                            className="fs-6"
                        >
                            {t(
                                "settingsPage.accountSettings.payoutBalanceSettings.earnings"
                            )}
                            :
                        </span>{" "}
                        <span
                            id="eefcff59-94ef-4a46-bf82-c91489f3596d"
                            className="text-primary"
                        >
                            {!isLoadingBalance &&
                                !!balance &&
                                balance.available.map((item) => (
                                    <span
                                        id="f4220cb0-9512-491b-844b-f26bc7be6883"
                                        key={item.currency}
                                    >
                                        {item.currency.toUpperCase()}{" "}
                                        {(item.amount / 100).toFixed(2)}
                                    </span>
                                ))}
                            {isLoadingBalance && (
                                <Spinner
                                    id="37537b91-cb58-4f4a-ba91-914ad0e4fff6"
                                    className="mt-1"
                                />
                            )}
                        </span>
                    </div>
                    <Button
                        id="8794a647-bb63-4380-ae7d-5dfe99a112e4"
                        className="btn btn-sm btn-dark"
                        onClick={payoutButtonClickHandler}
                        isLoading={isPayoutLoading}
                    >
                        {t(
                            "settingsPage.accountSettings.payoutBalanceSettings.payOut"
                        )}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default PayoutBalanceSettings;
