import React, { HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {
    showBorderTop?: boolean;
}

const Footer = ({ id, showBorderTop = true }: Props) => {
    const { t } = useTranslation();
    return (
        <div id={id} className={`py-3 pb-5 ${showBorderTop && "border-top"}`}>
            <div
                id="c4831959-cfa4-41e4-9560-5f3f1e185b9f"
                className="d-flex flex-column gap-3 form-text align-items-center pb-xxl-3"
            >
                <div
                    id="2af28f95-bde2-4cd7-a763-10a11837a188"
                    className="d-flex flex-column justify-content-center align-items-center flex-sm-row gap-3"
                >
                    <Link
                        // id="footer-to-about"
                        id="fc6efac9-4227-4b7b-bba7-84208be5b732"
                        to="/about"
                        className="text-decoration-none"
                    >
                        {t("footer.about")}
                    </Link>
                    <Link
                        // id="footer-to-faq"
                        id="82f69bf8-bb84-4ed2-bf11-c4515ab34f7d"
                        to="/faq"
                        className="text-decoration-none"
                    >
                        {t("footer.faqs")}
                    </Link>
                    <Link
                        // id="footer-to-terms"
                        id="df3809b4-6037-4ce8-8d72-f709190efb76"
                        to="/terms"
                        className="text-decoration-none"
                    >
                        {t("footer.termsAndConditions")}
                    </Link>
                    <Link
                        // id="footer-to-privacy"
                        id="a1b46777-cf35-48fd-b089-92962ae28ebc"
                        to="/privacy"
                        className="text-decoration-none"
                    >
                        {t("footer.privacyPolicy")}
                    </Link>
                    <Link
                        // id="footer-to-cookies"
                        id="052d91c2-0fb8-4a5b-a72b-38b3ea469b56"
                        to="/cookies"
                        className="text-decoration-none"
                    >
                        {t("footer.cookiesPolicy")}
                    </Link>
                </div>
                <div
                    id="d1cbc7ca-7382-49c9-8e25-276351cfef8c"
                    className="d-flex flex-column gap-1 align-items-center"
                >
                    <span id="3e72ea3a-77c1-462a-a7f4-8879f72a38e2">
                        Ⓒ 2024 Ablebees Inc.
                    </span>
                    <span
                        id="b561f2ac-3b9b-4834-81e9-7d23d51857e4"
                        className="text-center"
                    >
                        {/*<Trans*/}
                        {/*    i18nKey="footer.madeWithLove"*/}
                        {/*    components={[*/}
                        {/*        <BsFillSuitHeartFill className="text-danger" />,*/}
                        {/*    ]}*/}
                        {/*/>*/}
                        548 Market St, PMB 76869, San Francisco, CA 94104-5401,
                        US
                    </span>
                    <span id="413fb503-2e64-4240-875a-9b39d94416dc">
                        <a
                            id="9c51c9f2-be51-43ea-b818-28751bd7274f"
                            href="mailto: info@ablebees.com"
                            className="text-decoration-none"
                        >
                            info@ablebees.com
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Footer;
