import React, { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {
    amount: number;
    type: "topUp" | "pledge";
}

const ChargeBreakdown = ({ amount, type, id }: Props) => {
    const { t } = useTranslation();
    return (
        <div id={id} className="d-flex flex-column gap-2 mt-3">
            <div
                id="7802a110-143e-4dd5-8f59-afc68d84fcf4"
                className="form-text"
            >
                <div
                    id="25057cf8-a28e-4833-88f3-07e56517efa3"
                    className="row fw-bolder"
                >
                    <div
                        id="cd737bfd-baf6-41aa-95f8-1c29e25d3a98"
                        className="col"
                    ></div>
                    <div
                        id="a2cf54d9-982e-4d84-8c5b-c2380c177058"
                        className="col"
                    >
                        {t("chargeBreakdown.usCard")}
                    </div>
                    <div
                        id="378d652d-1fac-4755-bb48-ffa2c235635b"
                        className="col"
                    >
                        {t("chargeBreakdown.nonUSCard")}
                    </div>
                </div>
                <div id="7eda19a8-479d-49e2-8266-87a50fca3371" className="row">
                    <div
                        id="082aeb32-2bca-4778-961d-98a316c64f34"
                        className="col fw-bolder"
                    >
                        {type === "pledge"
                            ? t("chargeBreakdown.pledgeAmount")
                            : t("chargeBreakdown.topUpAmount")}
                    </div>
                    <div
                        id="e6bc11be-d619-4678-a5b1-ee075fe42f68"
                        className="col text-primary"
                    >
                        USD {amount.toFixed(2)}
                    </div>
                    <div
                        id="063c45df-a12d-4342-895a-103f55e52d10"
                        className="col text-primary"
                    >
                        USD {amount.toFixed(2)}
                    </div>
                </div>
                <div id="9645d199-f9cd-4a61-881f-49d0a86fbbca" className="row">
                    <div
                        id="8b88f9aa-4859-41c5-a63f-a97e8a7aee13"
                        className="col fw-bolder"
                    >
                        {t("chargeBreakdown.transactionFee")}*
                    </div>
                    <div
                        id="9233e702-9f76-4537-b557-23b952cb2ab6"
                        className="col text-primary"
                    >
                        USD{" "}
                        {(
                            Math.round(
                                ((amount + 0.3) / (1 - 0.029) - amount) * 100
                            ) / 100
                        ).toFixed(2)}
                    </div>
                    <div
                        id="83c66a67-4b26-41a1-b2ed-0de8cf5906ec"
                        className="col text-primary"
                    >
                        USD{" "}
                        {(
                            Math.round(
                                ((amount + 0.3) / (1 - 0.044) - amount) * 100
                            ) / 100
                        ).toFixed(2)}
                    </div>
                </div>
                <div id="1a88aecf-57db-4a34-b549-e00d4e03b4ad" className="row">
                    <div
                        id="55e4656f-5ac1-4ec9-acb9-9036e61eca23"
                        className="col fw-bolder"
                    >
                        {t("chargeBreakdown.chargeAmount")}
                    </div>
                    <div
                        id="95fe4b67-c5c1-422c-839d-dfc51c8d958b"
                        className="col text-primary"
                    >
                        USD{" "}
                        {(
                            Math.round(((amount + 0.3) / (1 - 0.029)) * 100) /
                            100
                        ).toFixed(2)}
                    </div>
                    <div
                        id="1d74b34e-b437-4d1d-8f33-9afb46746f95"
                        className="col text-primary"
                    >
                        USD{" "}
                        {(
                            Math.round(((amount + 0.3) / (1 - 0.044)) * 100) /
                            100
                        ).toFixed(2)}
                    </div>
                </div>
            </div>
            <span
                id="da2fda0a-89cf-481c-a2c2-110ecdba6ef9"
                className="form-text"
            >
                *{" "}
                {t(
                    "settingsPage.accountSettings.balanceSettings.topUpBalanceForm.transactionFeeNonRefundable"
                )}
            </span>
        </div>
    );
};

export default ChargeBreakdown;
