import React, { HTMLAttributes } from "react";
import PayoutMethodsListItem from "./PayoutMethodsListItem/PayoutMethodsListItem";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {
    payoutMethods: any[];
}

const PayoutMethodsList = ({ id, payoutMethods }: Props) => {
    const { t } = useTranslation();
    const renderPayoutMethodsList = payoutMethods.map((item) => {
        return (
            <PayoutMethodsListItem
                id={`payout-method-${item.id}`}
                key={item.id}
                payoutMethod={item}
            />
        );
    });

    return (
        <div id={id} className="d-flex flex-column gap-1">
            {renderPayoutMethodsList.length > 0 ? (
                renderPayoutMethodsList
            ) : (
                <span
                    id="83c54d8e-da4f-4ce0-bec0-94b0a9e86625"
                    className="form-text"
                >
                    {t(
                        "settingsPage.payoutSettings.payoutMethodSettings.payoutMethodsList.noPayoutMethodFound"
                    )}
                </span>
            )}
        </div>
    );
};

export default PayoutMethodsList;
