import AuthForm from "../../../components/AuthForm/AuthForm";
import React, { ChangeEvent, HTMLAttributes, useState } from "react";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import {
    FailureResponse,
    getPasswordResetCode,
} from "../../../api/users/getPasswordResetCode";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {}

const ForgotPasswordForm = ({ id }: Props) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [emailInputErrorMsg, setEmailInputErrorMsg] = useState("");
    const [formErrorMsg, setFormErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const isEmailValid = () => {
        if (!email) {
            setEmailInputErrorMsg(t("auth.pleaseEnterEmail"));
            return false;
        }
        return true;
    };

    const emailInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
        setEmailInputErrorMsg("");
        setFormErrorMsg("");
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (isEmailValid()) {
            setIsLoading(true);
            try {
                const response = await getPasswordResetCode(email);
                setSuccessMsg(response.message);
            } catch (error) {
                const { errors } = error as FailureResponse;

                if (errors) {
                    if (errors.email) setEmailInputErrorMsg(errors.email[0]);
                    if (errors.error)
                        setFormErrorMsg(
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error
                        );
                }
            }
            setIsLoading(false);
        }
    };

    return (
        <>
            {!successMsg && (
                <AuthForm
                    // id="forgot-password-form"
                    id={id}
                    title={t("auth.forgotPassword")}
                    errorMsg={formErrorMsg}
                    submitButtonText={t("auth.resetPassword")}
                    onSubmit={submitHandler}
                    isLoading={isLoading}
                >
                    <div
                        id="a9519e80-6d72-41f2-9237-ef9efc5e09b1"
                        className="form-text"
                    >
                        {t("auth.forgotPasswordInstructions")}
                    </div>
                    <InputGroup
                        id="d1bceb00-9b73-46c3-85e8-83c83db5c3c6"
                        type="email"
                        placeholder={t("auth.email")}
                        errorMsg={emailInputErrorMsg}
                        onChange={emailInputChangeHandler}
                        value={email}
                    />
                </AuthForm>
            )}
            {successMsg && (
                <div
                    id="8a9bbed1-3375-4b3b-b76a-d018d371bfa5"
                    className="alert alert-success"
                    role="alert"
                >
                    {successMsg}
                </div>
            )}
            <div
                id="a833bdbb-a603-4028-9eed-1c016ceaef5c"
                className="d-flex flex-column align-items-center mt-2 gap-3"
            >
                <div
                    id="5a30bce7-d32a-4e4d-b709-56c49a2e30d3"
                    className="form-text mt-3"
                >
                    <Link
                        // id="forgot-password-form-to-login"
                        id="9f4f372c-2539-4e9c-9d8d-d7ca716a9ef7"
                        to="/login"
                        className="text-decoration-none"
                    >
                        {t("auth.login")}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ForgotPasswordForm;
