import React, {
    HTMLAttributes,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { getPayoutCountries as api_getPayoutCountries } from "../../../../../api/payments/getPayoutCountries";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/UI/Button/Button";
import {
    FailureResponse as UpdateAccountFailureResponse,
    updateAccount as api_updateAccount,
} from "../../../../../api/users/updateAccount";
import { ApiErrorCode } from "../../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import AuthContext from "../../../../../contexts/auth-context";
import ModalContext from "../../../../../contexts/modal-context";

type County = {
    code: string;
    name: string;
};

interface Props extends HTMLAttributes<any> {}

const PayoutCountrySettings = ({ id }: Props) => {
    const { user, refreshUser } = useContext(AuthContext);
    const { showModal } = useContext(ModalContext);
    const { t } = useTranslation();
    const [isLoadingCountries, setIsLoadingCountries] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState<[County]>();
    const [countryCode, setCountryCode] = useState<string>();
    const [errorMsg, setErrorMsg] = useState<string>();

    const getPayoutCountries = useCallback(async () => {
        setIsLoadingCountries(true);
        const response = await api_getPayoutCountries();
        setCountries(response.countries);
        setIsLoadingCountries(false);
    }, []);

    useEffect(() => {
        getPayoutCountries();
    }, [getPayoutCountries]);

    const updateAccount = async (payment_settings?: {
        payout_country_code?: string;
    }) => {
        setIsLoading(true);
        try {
            await api_updateAccount(payment_settings);
            refreshUser();
        } catch (error) {
            const { errors, status_code, code } =
                error as UpdateAccountFailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                id="16df36db-92ba-4417-ac60-04ab29e7613e"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else {
                        showModal(
                            <AlertOverlay
                                id="1b3de43b-5f21-444a-8e3a-95ea2896d899"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    }
                } else {
                    if (errors.payment_settings?.payout_country_code)
                        setErrorMsg(
                            errors.payment_settings?.payout_country_code[0]
                        );
                }
            }
        }
        setIsLoading(false);
    };

    const handleSaveCountry = async () => {
        if (!countryCode) {
            setErrorMsg(
                t(
                    "settingsPage.payoutSettings.payoutCountrySettings.pleaseSelectPayoutCountry"
                )
            );
            return;
        }
        await updateAccount({ payout_country_code: countryCode });
    };

    const changeHandler = (event: React.FormEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setErrorMsg(undefined);
        const val = event.currentTarget.value;
        if (!!val) setCountryCode(val);
        else setCountryCode(undefined);
    };

    const renderCountry = (country: { code: string; name: string }) => (
        <option
            id="d5401bcc-77e5-41e6-918a-d5cacdf7e941"
            value={country.code}
            key={country.code}
        >
            {country.name} ({country.code})
        </option>
    );

    let renderedCountries = <></>;

    if (countries)
        renderedCountries = (
            <>
                <option
                    id="dd2c7dbd-946d-4348-9f42-615b9e9533ee"
                    value=""
                    key="default"
                >
                    {t(
                        "settingsPage.payoutSettings.payoutCountrySettings.selectCountry"
                    )}
                </option>
                {countries.map((country) => renderCountry(country))}
            </>
        );

    let content = <Spinner id="ef552693-6b9b-470f-aa7b-f425a7c682a1" />;
    if (!isLoadingCountries) {
        content = (
            <div
                id="49dd434f-561d-4482-bbe4-bfcaa4c477ad"
                className="input-group input-group-sm has-validation"
            >
                <select
                    id="5da9c608-eb5f-4150-a764-02c783feeda5"
                    className={`form-select  ${!!errorMsg && "is-invalid"}`}
                    onChange={changeHandler}
                >
                    {renderedCountries}
                </select>
                <Button
                    id="832d05c6-f364-4ff0-849a-32caf4ded88e"
                    className="btn btn-sm btn-dark"
                    type="submit"
                    onClick={handleSaveCountry}
                    isLoading={isLoading}
                    onlySpinner={true}
                >
                    {t("settingsPage.save")}
                </Button>
                <div
                    id="498019c7-f463-44cd-9b2d-867a5b5c37cc"
                    className="invalid-feedback"
                >
                    {errorMsg}
                </div>
            </div>
        );
    }

    return (
        <div id={id} className="border rounded-3 p-3">
            <h6 id="fc148bc6-5757-498b-bc8a-5413dddafb57">
                {t(
                    "settingsPage.payoutSettings.payoutCountrySettings.payoutCountry"
                )}
            </h6>
            <hr id="2e55b8bb-5c42-4aad-9ff6-0a044d3282ee" />
            {content}
        </div>
    );
};

export default PayoutCountrySettings;
