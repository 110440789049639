import React, { HTMLAttributes } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HelpPageTab } from "../../HelpPage/HelpPage";
import Button from "../../../components/UI/Button/Button";

type FAQ = {
    question: string;
    answer: string[];
};

interface Props extends HTMLAttributes<any> {
    centerTabs?: boolean;
    viewAsContentCreator: boolean;
    setViewAsContentCreator: React.Dispatch<React.SetStateAction<boolean>>;
}

const Faqs = ({
    id,
    centerTabs = false,
    viewAsContentCreator,
    setViewAsContentCreator,
}: Props) => {
    const { t } = useTranslation();

    const consumerFAQs: FAQ[] = t("faqPage.questions.byConsumers", {
        returnObjects: true,
    }).map((item) => item);

    const creatorFAQs: FAQ[] = t("faqPage.questions.byCreators", {
        returnObjects: true,
    }).map((item) => item);

    const renderFAQ = (item: FAQ, index: number, itemType: string) => {
        const components = {
            "link-to-how-to-set-up-creator-account": (
                <Link
                    id="6cc2666f-0337-4c01-b0f9-5534b1733bdc"
                    target="_blank"
                    to={`/help/${HelpPageTab.HOW_TO_SETUP_CREATOR_ACCOUNT}`}
                    className="text-decoration-none"
                />
            ),
        };

        return (
            <div
                id={`faq-${itemType}-${index}-accordion-item`}
                className="accordion-item"
                key={`faq-${itemType}-${index}`}
            >
                <h2
                    id={`faq-${itemType}-${index}-accordion-header`}
                    className="accordion-header"
                >
                    <Button
                        id={`faq-${itemType}-${index}-button`}
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#faq-${itemType}-${index}-accordion-collapse`}
                        aria-expanded="false"
                        aria-controls={`faq-${itemType}-${index}-accordion-collapse`}
                    >
                        {item.question}
                    </Button>
                </h2>
                <div
                    id={`faq-${itemType}-${index}-accordion-collapse`}
                    className="accordion-collapse collapse"
                    data-bs-parent={`#accordion${
                        itemType.charAt(0).toUpperCase() + itemType.slice(1)
                    }`}
                >
                    <div
                        id={`faq-${itemType}-${index}-accordion-body`}
                        className="accordion-body"
                    >
                        {item.answer.map((paragraph: string, index: number) => (
                            <p
                                id="b80c7f0e-c1a4-470f-a8d9-a5d217391963"
                                key={index}
                            >
                                <Trans
                                    id="1535010c-caa6-4e14-a10e-b68bd5d78104"
                                    defaults={paragraph}
                                    components={components}
                                />
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const renderedConsumerFAQs = consumerFAQs.map((item: FAQ, index: number) =>
        renderFAQ(item, index, "consumer")
    );

    const renderedCreatorFAQs = creatorFAQs.map((item: FAQ, index: number) =>
        renderFAQ(item, index, "creator")
    );

    return (
        <div id={id}>
            <div
                id="830deb74-ced7-47c2-a790-31b61c30a140"
                className={centerTabs ? `d-flex justify-content-center` : ""}
            >
                <ul
                    id="1b7c4f03-23dc-4d63-a0ac-668f110b7834"
                    className="nav nav-underline mb-3"
                    role="tablist"
                >
                    <li
                        id="15c0660c-8757-4976-8a42-0eea5fb839fe"
                        className="nav-item"
                        role="presentation"
                    >
                        <button
                            id="d76e3b3d-6514-423a-9883-65a2febdeedf"
                            className={`nav-link ${
                                viewAsContentCreator ? "active" : ""
                            }`}
                            data-bs-toggle="pill"
                            data-bs-target="#pills-content-creators"
                            type="button"
                            role="tab"
                            aria-controls="pills-content-creators"
                            aria-selected="false"
                            onClick={() => setViewAsContentCreator(true)}
                        >
                            {t("faqPage.byCreators")}
                        </button>
                    </li>
                    <li
                        id="d1cc750a-d54c-4749-acb7-d1de1236e1c3"
                        className="nav-item"
                        role="presentation"
                    >
                        <button
                            id="b362d95e-14ef-4f5e-9a7e-e03de321ed52"
                            className={`nav-link ${
                                viewAsContentCreator ? "" : "active"
                            }`}
                            data-bs-toggle="pill"
                            data-bs-target="#pills-content-consumers"
                            type="button"
                            role="tab"
                            aria-controls="pills-content-consumers"
                            aria-selected="false"
                            onClick={() => setViewAsContentCreator(false)}
                        >
                            {t("faqPage.byFollowers")}
                        </button>
                    </li>
                </ul>
            </div>
            <div id="3a2f4992-0911-4215-ad63-b5545d8e2b96">
                <div
                    id="8c57258a-2fe7-45c3-8a43-be3a4c718b11"
                    className="tab-content w-100"
                >
                    <div
                        id="pills-content-creators"
                        className={`tab-pane fade ${
                            viewAsContentCreator ? "show active" : ""
                        }`}
                        role="tabpanel"
                        aria-labelledby="pills-content-creators-tab"
                    >
                        <div id="accordionCreator" className="accordion">
                            {renderedCreatorFAQs}
                        </div>
                    </div>
                </div>
                <div id="pills-tabContent" className="tab-content w-100">
                    <div
                        id="pills-content-consumers"
                        className={`tab-pane fade ${
                            viewAsContentCreator ? "" : "show active"
                        }`}
                        role="tabpanel"
                        aria-labelledby="pills-content-creators-tab"
                    >
                        <div id="accordionConsumer" className="accordion">
                            {renderedConsumerFAQs}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faqs;
