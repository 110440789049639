import React, { HTMLAttributes, useContext, useState } from "react";
import Button from "../../../UI/Button/Button";
import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import ModalContext from "../../../../contexts/modal-context";
import { getEmailVerificationCode as api_getEmailVerificationCode } from "../../../../api/users/getEmailVerificationCode";
import AlertOverlay from "../AlertOverlay/AlertOverlay";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {
    message: string;
}

const EmailNotVerifiedErrorOverlay = ({ message, id }: Props) => {
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);

    const getEmailVerificationCode = async () => {
        setIsLoading(true);
        try {
            const response = await api_getEmailVerificationCode();
            showModal(
                <AlertOverlay
                    id="cbbfbacc-fbba-451e-89f3-69f803af952b"
                    status={"success"}
                    message={response.message}
                />
            );
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    return (
        <ModalOverlay id={id}>
            <h6
                id="76552b28-c5e4-42e7-a1a7-740e9cf26fbb"
                className={`text-capitalize text-danger`}
            >
                {t("modal.error")}
            </h6>
            <hr id="906e2a1c-8cfd-47d5-9dd7-65d8f1f803a4" />
            <p id="829e6b9a-2f9d-4a5a-860b-e3c6eba42fcc">{message}</p>
            <hr id="348bd09c-d8f4-4c0b-add9-2e1f9a97bda8" />
            <div
                id="e6297a0a-2ccd-48b2-a2f7-e57cd6f99cd4"
                className="d-flex justify-content-end gap-2"
            >
                <Button
                    id="52eea2c3-64a2-4af8-ab26-0adc8bc4fb38"
                    className="btn btn-sm btn-primary"
                    onClick={() => getEmailVerificationCode()}
                    isLoading={isLoading}
                >
                    {t(
                        "modal.overlays.emailNotVerifiedError.emailVerificationCode"
                    )}
                </Button>
                <Button
                    id="37ad7e94-c849-40f5-80a0-bde97b8d5817"
                    className="btn btn-sm btn-dark"
                    onClick={closeModal}
                >
                    {t("modal.close")}
                </Button>
            </div>
        </ModalOverlay>
    );
};

export default EmailNotVerifiedErrorOverlay;
