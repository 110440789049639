import {
    MAX_TOP_UP_BALANCE_AMOUNT,
    MIN_TOP_UP_BALANCE_AMOUNT,
} from "../../../../../../constants";
import InputGroup from "../../../../../../components/UI/InputGroup/InputGroup";
import React, { HTMLAttributes, useContext, useState } from "react";
import Button from "../../../../../../components/UI/Button/Button";
import AlertOverlay from "../../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../../../contexts/modal-context";
import { useStripe } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../../../contexts/auth-context";
import ChargeBreakdown from "../../../../../../components/ChargeBreakdown/ChargeBreakdown";
import PaymentContext from "../../../../../../contexts/payment-context";

interface Props extends HTMLAttributes<any> {}

const TopUpBalanceForm = ({ id }: Props) => {
    const { t } = useTranslation();
    const stripe = useStripe();
    const { refreshUser } = useContext(AuthContext);
    const { showModal } = useContext(ModalContext);
    const { makePayment } = useContext(PaymentContext);
    const [topUpAmount, setTopUpAmount] = useState<number>(
        MIN_TOP_UP_BALANCE_AMOUNT
    );
    const [topUpAmountInputErrorMsg, setTopUpAmountInputErrorMsg] =
        useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onTopUpSucceeded = () => {
        refreshUser();
        setTopUpAmount(MIN_TOP_UP_BALANCE_AMOUNT);
        showModal(
            <AlertOverlay
                id="bad7a78e-3364-4194-8058-6266c3726ef5"
                status="success"
                message={t(
                    "settingsPage.accountSettings.balanceSettings.topUpBalanceForm.balanceTopUpSucceeded"
                )}
            />
        );
    };

    const isTopUpAmountValid = () => {
        if (
            isNaN(topUpAmount) ||
            topUpAmount === undefined ||
            topUpAmount < MIN_TOP_UP_BALANCE_AMOUNT ||
            topUpAmount > MAX_TOP_UP_BALANCE_AMOUNT
        ) {
            setTopUpAmountInputErrorMsg(
                t(
                    "settingsPage.accountSettings.balanceSettings.topUpBalanceForm.pleaseEnterValueBetween",
                    {
                        min_value: MIN_TOP_UP_BALANCE_AMOUNT,
                        max_value: MAX_TOP_UP_BALANCE_AMOUNT,
                    }
                )
            );
            return false;
        }
        return true;
    };

    const topUpAmountChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const val = parseInt(event.currentTarget.value);
        setTopUpAmount(val);
        if (
            isNaN(val) ||
            val < MIN_TOP_UP_BALANCE_AMOUNT ||
            val > MAX_TOP_UP_BALANCE_AMOUNT
        ) {
            setTopUpAmountInputErrorMsg(
                t(
                    "settingsPage.accountSettings.balanceSettings.topUpBalanceForm.pleaseEnterValueBetween",
                    {
                        min_value: MIN_TOP_UP_BALANCE_AMOUNT,
                        max_value: MAX_TOP_UP_BALANCE_AMOUNT,
                    }
                )
            );
        } else {
            setTopUpAmountInputErrorMsg("");
        }
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!isTopUpAmountValid()) return;
        await makePayment(
            stripe,
            topUpAmount,
            setIsLoading,
            setTopUpAmountInputErrorMsg,
            onTopUpSucceeded
        );
    };

    return (
        <form id={id} onSubmit={submitHandler}>
            <InputGroup
                id="47f6f2c2-e261-409f-b3b8-23aca36ed273"
                className="input-group-sm"
                errorMsg={topUpAmountInputErrorMsg}
                leadingAddon={
                    <span
                        id="e45e624c-6baa-4ec6-a972-32ee17de1745"
                        className="input-group-text"
                    >
                        USD
                    </span>
                }
                trailingAddon={
                    <>
                        <span
                            id="82c06205-d96d-4012-9ab9-dfb696714df4"
                            className="input-group-text"
                        >
                            .00
                        </span>
                        <Button
                            id="65a8fdec-e78e-4b36-9dae-509b93119acb"
                            className="btn btn-sm btn-primary"
                            type="submit"
                            isLoading={isLoading}
                            onlySpinner={true}
                        >
                            {t(
                                "settingsPage.accountSettings.balanceSettings.topUpBalanceForm.topUp"
                            )}
                        </Button>
                    </>
                }
                type="number"
                placeholder={t(
                    "settingsPage.accountSettings.balanceSettings.topUpBalanceForm.topUpAmount"
                )}
                min={MIN_TOP_UP_BALANCE_AMOUNT}
                max={MAX_TOP_UP_BALANCE_AMOUNT}
                // step={0.01}
                onChange={topUpAmountChangeHandler}
                value={topUpAmount !== undefined ? topUpAmount : ""}
            />
            <ChargeBreakdown
                id="93718924-00bb-48e7-8451-133753fa76e5"
                amount={topUpAmount}
                type="topUp"
            />
        </form>
    );
};

export default TopUpBalanceForm;
