import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import User from "../../../../models/User";
import React, {
    HTMLAttributes,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import UsersList from "../../../UsersList/UsersList";
import SearchUserForm from "../../../SearchUserForm/SearchUserForm";
import { listUsers as api_listUsers } from "../../../../api/users/listUsers";
import Spinner from "../../../UI/Spinner/Spinner";
import ModalContext from "../../../../contexts/modal-context";
import Button from "../../../UI/Button/Button";
import { parseCursor } from "../../../../utils/helpers";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {
    selectUserHandler: (user: User) => void;
    creatorsOnly?: boolean;
    showVerifiedMark?: boolean;
}

const FindUserOverlay = ({
    selectUserHandler,
    creatorsOnly = false,
    showVerifiedMark = true,
    id,
}: Props) => {
    const { t } = useTranslation();
    const { closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const getUsers = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!searchTerm || !hasMore) return;
            setIsLoading(true);
            try {
                let response: {
                    results: User[];
                    next: any;
                    previous?: string;
                };

                response = await api_listUsers(
                    searchTerm,
                    false,
                    creatorsOnly,
                    cursor
                );

                if (cursor === undefined) setUsers(response.results);
                else setUsers((prev: User[]) => [...prev, ...response.results]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [searchTerm]
    );

    useEffect(() => {
        setUsers([]);
        setCursor(undefined);
        setHasMore(true);
        getUsers(undefined, true);
    }, [getUsers]);

    return (
        <ModalOverlay id={id}>
            <SearchUserForm
                id="1c0e5631-3aad-44d0-af76-6259ba1d78ff"
                onSearchTermChange={setSearchTerm}
                creatorsOnly={creatorsOnly}
            />
            {(isLoading || users.length > 0 || searchTerm) && (
                <div id="133c2529-fc86-45f1-a607-8c00f21e3bee" className="mt-3">
                    {!!users && (
                        <UsersList
                            id="068a3358-d420-480c-8e35-1f82f1e079f5"
                            users={users}
                            itemClickHandler={selectUserHandler}
                            showFollowButton={false}
                            showVerifiedMark={showVerifiedMark}
                            getUsers={() => getUsers(cursor, hasMore)}
                            isLoading={isLoading}
                        />
                    )}
                    {isLoading && (
                        <Spinner
                            id="c149ca8d-fa94-41cf-9a80-b74cb0531288"
                            className="mt-3"
                        />
                    )}
                    {!isLoading && users.length === 0 && (
                        <p
                            id="84fa5e72-9f10-4ae9-bcef-9ae6f4e90963"
                            className="form-text text-center"
                        >
                            {t("modal.overlays.findUser.noOneFound")}
                        </p>
                    )}
                </div>
            )}
            <hr id="de19f308-b69b-42f5-a233-254de67dde2c" />
            <div
                id="777c61e3-a38d-46dd-bcb5-6f03af0f8a44"
                className="d-flex justify-content-end"
            >
                <Button
                    id="6c4f65b2-0f0a-4e25-8b0b-14185f4a8cca"
                    className="btn btn-sm btn-dark"
                    onClick={closeModal}
                >
                    {t("modal.close")}
                </Button>
            </div>
        </ModalOverlay>
    );
};

export default FindUserOverlay;
