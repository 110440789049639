import classes from "./ModalBackdrop.module.scss";
import { useContext } from "react";
import ModalContext from "../../../contexts/modal-context";

const ModalBackdrop = () => {
    const { closeModal } = useContext(ModalContext);
    return (
        <div
            id="806aa769-4b2f-40be-a5d0-8b3627442b19"
            className={classes.backdrop}
            onClick={closeModal}
        />
    );
};

export default ModalBackdrop;
