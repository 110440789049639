export enum BecomeCreatorRequestStatus {
    PENDING = "pending",
    APPROVED = "approved",
    REJECTED = "rejected",
    VERIFIED = "verified",
}

class BecomeCreatorRequest {
    readonly uuid: string;
    readonly raw_external_link_url: string;
    readonly status: BecomeCreatorRequestStatus;
    readonly dt_created: string;

    constructor(data: any) {
        this.uuid = data.uuid;
        this.raw_external_link_url = data.raw_external_link_url;
        this.status = data.status;
        this.dt_created = data.dt_created;
    }
}

export default BecomeCreatorRequest;
