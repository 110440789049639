import SearchUserForm from "../../components/SearchUserForm/SearchUserForm";
import UsersList from "../../components/UsersList/UsersList";
import React, { useCallback, useEffect, useState } from "react";
import User from "../../models/User";
import { listUsers as api_listUsers } from "../../api/users/listUsers";
import Spinner from "../../components/UI/Spinner/Spinner";
import { parseCursor } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

const SearchPage = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const getUsers = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!searchTerm || !hasMore) return;
            setIsLoading(true);
            try {
                const response = await api_listUsers(
                    searchTerm,
                    false,
                    false,
                    cursor
                );
                if (cursor === undefined) setUsers(response.results);
                else setUsers((prev: User[]) => [...prev, ...response.results]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [searchTerm]
    );

    useEffect(() => {
        setUsers([]);
        setCursor(undefined);
        setHasMore(true);
        getUsers(undefined, true);
    }, [getUsers]);

    return (
        <div id="6a0cca2a-5ee7-4e81-9822-e91ffcea68f9">
            <h6 id="b8069bd4-134e-4023-a17f-08cc387c68d4" className="mb-3">
                {t("navbar.search")}
            </h6>
            <hr id="dd0754da-1420-4af2-8302-3ab99717fd3c" />
            <SearchUserForm
                id="d920f171-578f-4a94-b6e9-9cffd91aef4e"
                onSearchTermChange={setSearchTerm}
            />
            {(isLoading || users.length > 0) && (
                <div id="621720c4-7fd3-4840-9c40-3ad5e89503b4" className="mt-3">
                    {!!users && (
                        <UsersList
                            id="c33a19b1-7468-429b-b318-7451bca967dc"
                            users={users}
                            getUsers={() => getUsers(cursor, hasMore)}
                            isLoading={isLoading}
                        />
                    )}
                    {isLoading && (
                        <Spinner
                            id="75c9cd5e-2485-4778-ace6-175206fd440c"
                            className="mt-3"
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchPage;
