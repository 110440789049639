import { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<any> {}

const Spinner = ({ className, id }: Props) => {
    return (
        <div id={id} className="d-flex justify-content-center">
            <div
                id="669743af-504e-4d2c-a5c2-7efac12e08ed"
                className={`spinner-border spinner-border-sm ${className}`}
            >
                <span
                    id="7399b4a3-6fd4-4972-b23f-17d972e74998"
                    className="visually-hidden"
                >
                    Loading...
                </span>
            </div>
        </div>
    );
};

export default Spinner;
