import React, { HTMLAttributes, useContext, useState } from "react";
import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import { useTranslation } from "react-i18next";
import Button from "../../../UI/Button/Button";
import ModalContext from "../../../../contexts/modal-context";
import { MAX_PETITION_REJECTION_REASON_LENGTH } from "../../../../constants";
import Textarea from "../../../UI/Textarea/Textarea";

interface Props extends HTMLAttributes<any> {
    onConfirm: (rejectionReason: string) => void;
}

const RejectPetitionOverlay = ({ onConfirm, id }: Props) => {
    const { t } = useTranslation();
    const { closeModal } = useContext(ModalContext);
    const [rejectionReason, setRejectionReason] = useState<string>("");
    const [
        rejectionReasonTextareaErrorMsg,
        setRejectionReasonTextareaErrorMsg,
    ] = useState("");

    const rejectionReasonChangeHandler = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setRejectionReason(event.currentTarget.value);
        setRejectionReasonTextareaErrorMsg("");
    };

    const confirmHandler = () => {
        if (rejectionReason.length > 0) {
            onConfirm(rejectionReason);
            closeModal();
        } else {
            setRejectionReasonTextareaErrorMsg(
                t("modal.overlays.rejectPetition.provideRejectionReason")
            );
        }
    };

    return (
        <ModalOverlay id={id}>
            <h6 id="895d636e-f4b8-4142-a690-36de1c9bdcb1">
                {t("modal.warning")}
            </h6>
            <hr id="1a89ee4e-e382-40ec-8125-1f7fdc6429a0" />
            <p
                id="828da5ac-0a4f-4554-93eb-e58cb0150ca5"
                className="form-text text-black"
            >
                {t("modal.overlays.rejectPetition.sureWantToReject")}
            </p>
            <p
                id="b48a1f0d-b572-48d6-aece-c0e232c430bd"
                className="form-text text-black"
            >
                {t("modal.overlays.rejectPetition.mindToExplain")}
            </p>
            <div id="75f04623-d6f8-49f1-9c3a-2cd7741b69e3">
                <Textarea
                    id="753bbd2d-d01b-49c9-a7b0-1eb50e1a8608"
                    className="form-control-sm"
                    rows={3}
                    errorMsg={rejectionReasonTextareaErrorMsg}
                    placeholder={t(
                        "modal.overlays.rejectPetition.rejectionReason"
                    )}
                    onChange={rejectionReasonChangeHandler}
                    value={rejectionReason}
                    maxLength={MAX_PETITION_REJECTION_REASON_LENGTH}
                />
                <div
                    id="5311c32a-7970-49a6-82e6-7de10ac3d22a"
                    className="form-text d-flex justify-content-end"
                >
                    {MAX_PETITION_REJECTION_REASON_LENGTH -
                        rejectionReason.length}
                </div>
            </div>
            <hr id="8f056c43-3f5d-4b6e-9580-9370fcaab57a" />
            <div
                id="09b06dca-da1e-4b54-8292-14e93d3328eb"
                className="d-flex justify-content-end gap-2"
            >
                <Button
                    id="7f3c8efe-aba0-49eb-902a-423fbb222c12"
                    className="btn btn-sm btn-dark"
                    onClick={closeModal}
                >
                    {t("modal.close")}
                </Button>
                <Button
                    id="5c7dfa77-eff4-4ed6-a954-afba2db77a3a"
                    className="btn btn-sm btn-primary"
                    onClick={confirmHandler}
                >
                    {t("modal.confirm")}
                </Button>
            </div>
        </ModalOverlay>
    );
};

export default RejectPetitionOverlay;
