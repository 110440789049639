import SettingsPageNavbar from "./SetingsPageNavbar/SettingsPageNavbar";
import { Navigate, Outlet, useMatch } from "react-router-dom";
import { useContext, useEffect } from "react";
import ModalContext from "../../contexts/modal-context";

export enum SettingsPageTab {
    PROFILE = "profile",
    ACCOUNT = "account",
    PAYMENT_METHODS = "payment-methods",
    BILLING = "billing",
    PAYOUT = "payout",
    NOTIFICATIONS = "notifications",
}

export type OutletContextType = {
    sc: string | null;
    scrollMarginTop: string;
};

const SettingsPage = () => {
    const sc = new URLSearchParams(window.location.search).get("sc");
    const scrollMarginTop: string = "80px";

    function scrollToElement(id: string) {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ block: "start", behavior: "smooth" });
        }
    }

    useEffect(() => {
        if (sc) scrollToElement(sc);
    }, []);

    const { closeModal } = useContext(ModalContext);
    useEffect(() => {
        closeModal();
    }, [closeModal]);

    const match = useMatch("/settings/:tab");
    if (!match)
        return (
            <Navigate
                to={`/settings/${SettingsPageTab.PROFILE}`}
                replace={true}
            />
        );

    return (
        <div id="a871c922-d07c-4f6d-a3ec-a7bedc81f453">
            <SettingsPageNavbar id="eb9a075e-e62d-4a96-adb4-89615da0b526" />
            <div id="ae47c204-1aa0-4101-84a6-ac5fe41f4b41" className="py-4">
                <Outlet
                    context={
                        { sc, scrollMarginTop } satisfies OutletContextType
                    }
                />
            </div>
        </div>
    );
};

export default SettingsPage;
