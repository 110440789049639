import {
    FailureResponse as GetStripeAccountFailureResponse,
    getStripeAccount as api_getStripeAccount,
} from "../../../../../api/payments/getStripeAccount";
import {
    FailureResponse as GetStripeAccountLinkFailureResponse,
    getStripeAccountLink as api_getStripeAccountLink,
} from "../../../../../api/payments/getStripeAccountLink";
import React, {
    HTMLAttributes,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import ModalContext from "../../../../../contexts/modal-context";
import { ApiErrorCode } from "../../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import Button from "../../../../../components/UI/Button/Button";
import PayoutMethodsList from "./PayoutMethodsList/PayoutMethodsList";
import StripeAccount, {
    PayoutDisabledReason,
} from "../../../../../models/StripeAccount";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {}

const PayoutMethodSettings = ({ id }: Props) => {
    const { t } = useTranslation();
    const { showModal } = useContext(ModalContext);
    const [stripeAccount, setStripeAccount] = useState<StripeAccount | null>(
        null
    );
    const [gettingAccount, setGettingAccount] = useState(true);
    const [gettingAccountLink, setGettingAccountLink] = useState(false);

    const urlParam = new URLSearchParams(window.location.search).get("url");

    const getStripeAccount = useCallback(async () => {
        try {
            const response = await api_getStripeAccount();
            setStripeAccount(response);
        } catch (error) {
            const { errors, status_code, code } =
                error as GetStripeAccountFailureResponse;

            if (code === ApiErrorCode.NOT_FOUND) {
                setStripeAccount(null);
            } else if (errors)
                if (
                    status_code === 403 &&
                    code === ApiErrorCode.EMAIL_NOT_VERIFIED
                ) {
                    showModal(
                        <EmailNotVerifiedErrorOverlay
                            id="9aa99ba0-278e-4b68-abd3-14745f92eeb2"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
                } else
                    showModal(
                        <AlertOverlay
                            id="2aabe39a-748d-4572-8c57-04a51167626a"
                            status="error"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
        }
        setGettingAccount(false);
    }, [showModal]);

    useEffect(() => {
        getStripeAccount();
        if (urlParam === "refresh") getStripeAccountLink(false);
    }, [getStripeAccount]);

    const getStripeAccountLink = async (update: boolean) => {
        setGettingAccountLink(true);
        try {
            const response = await api_getStripeAccountLink(update);
            // redirect
            window.location.href = response.url;
        } catch (error) {
            const { errors, status_code, code } =
                error as GetStripeAccountLinkFailureResponse;

            if (errors)
                if (
                    status_code === 403 &&
                    code === ApiErrorCode.EMAIL_NOT_VERIFIED
                ) {
                    showModal(
                        <EmailNotVerifiedErrorOverlay
                            id="d7d57084-af6e-46ec-b9af-9384ea0b53b5"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
                } else
                    showModal(
                        <AlertOverlay
                            id="21ed115d-8819-4bcc-9abe-879f93bbc552"
                            status="error"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
            setGettingAccountLink(false);
        }
    };

    const payoutMethods = stripeAccount
        ? stripeAccount.external_accounts.data
        : [];

    const alerts = [];
    let disabled_reason: string = "";
    let buttonClass: string = "dark";
    let buttonText: string = t(
        "settingsPage.payoutSettings.payoutMethodSettings.addPayoutMethod"
    );
    let buttonDisabled: boolean = false;

    let linkTypeUpdate: boolean = false;

    if (!gettingAccount) {
        if (stripeAccount) {
            if (stripeAccount.external_accounts.data.length > 0) {
                linkTypeUpdate = true;
                buttonClass = "warning";
                buttonText = t(
                    "settingsPage.payoutSettings.payoutMethodSettings.editPayoutMethod"
                );
            }

            if (stripeAccount.payouts_enabled) {
                if (stripeAccount.requirements.eventually_due.length > 0) {
                    linkTypeUpdate = false;
                    buttonClass = "warning";
                    buttonText = t(
                        "settingsPage.payoutSettings.payoutMethodSettings.provideMissingInfo"
                    );

                    alerts.push(
                        <div
                            id="8ba6d049-fe1d-409b-8da8-276b53885585"
                            className="alert alert-warning w-100 d-flex flex-column gap-3"
                            role="alert"
                        >
                            <div id="18cbdd5e-5fef-459e-b954-107536b71335">
                                <span id="f215a48f-7d80-4d10-a714-a4ff6f172680">
                                    {t(
                                        "settingsPage.payoutSettings.payoutMethodSettings.followingEventuallyRequired"
                                    )}
                                </span>
                                <ul
                                    id="7580e080-abf0-4bed-b6d6-0978e539190e"
                                    className="m-0 mt-2"
                                >
                                    {stripeAccount.requirements.eventually_due.map(
                                        (item: string, index: number) => (
                                            <li
                                                id="96b8125c-d8eb-4012-afde-b0035f996cfc"
                                                key={index}
                                            >
                                                {item.split(".").join(" ")}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        </div>
                    );

                    if (stripeAccount.requirements.errors.length > 0) {
                        linkTypeUpdate = false;
                        buttonClass = "warning";
                        buttonText = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.provideMissingInfo"
                        );

                        alerts.push(
                            <div
                                id="9b418b17-f901-4fdc-a015-a8f1bbc13959"
                                className="alert alert-warning w-100"
                                role="alert"
                            >
                                <span id="dadc8522-bfc4-43d9-9660-c888f3366aa4">
                                    {t(
                                        "settingsPage.payoutSettings.payoutMethodSettings.errors"
                                    )}
                                    :
                                </span>
                                <ul
                                    id="22ce1e7f-4e46-44ab-b279-5d0b26af9d92"
                                    className="m-0 mt-2"
                                >
                                    {stripeAccount.requirements.errors.map(
                                        (item, index: number) => (
                                            <li
                                                id="15f75318-b43b-4719-94ad-6a231bb5ddf4"
                                                key={index}
                                            >
                                                {item.reason}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        );
                    }
                }
            } else {
                linkTypeUpdate = false;
                buttonClass = "danger";
                buttonText = t(
                    "settingsPage.payoutSettings.payoutMethodSettings.fixPayoutMethod"
                );

                // Docs: https://stripe.com/docs/connect/handling-api-verification
                switch (stripeAccount.requirements.disabled_reason) {
                    case PayoutDisabledReason.ACTION_REQUIRED__REQUESTED_CAPABILITIES: {
                        disabled_reason = "";
                        break;
                    }
                    case PayoutDisabledReason.LISTED: {
                        // disabled_reason =
                        //     "Your account might be on a prohibited persons or companies list. Stripe will investigate and either reject or reinstate your account.";
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.accountUnderReviewByStripe"
                        );
                        buttonClass = "secondary";
                        buttonText = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.payoutMethodUnderReview"
                        );
                        buttonDisabled = true;
                        break;
                    }
                    case PayoutDisabledReason.REJECTED__FRAUD: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.accountRejectedDueSuspectedFraud"
                        );
                        break;
                    }
                    case PayoutDisabledReason.REJECTED__INCOMPLETE_VERIFICATION: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.accountRejectedDueIncompleteVerification"
                        );
                        break;
                    }
                    case PayoutDisabledReason.REJECTED__LISTED: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.accountRejectedDueThirdParty"
                        );
                        break;
                    }
                    case PayoutDisabledReason.REJECTED__OTHER: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.accountRejectedForAnotherReason"
                        );
                        break;
                    }
                    case PayoutDisabledReason.REJECTED__TERMS_OF_SERVICE: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.accountRejectedDueSuspectedTermsViolations"
                        );
                        break;
                    }
                    case PayoutDisabledReason.REQUIREMENTS__PAST_DUE: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.additionalVerificationInfoRequired"
                        );
                        break;
                    }
                    case PayoutDisabledReason.REQUIREMENTS__PENDING_VERIFICATION: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.stripeVerifyingInfo"
                        );
                        buttonClass = "secondary";
                        buttonText = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.payoutMethodUnderReview"
                        );
                        buttonDisabled = true;
                        break;
                    }
                    case PayoutDisabledReason.UNDER_REVIEW: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.accountUnderReviewByStripe"
                        );
                        buttonClass = "secondary";
                        buttonText = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.payoutMethodUnderReview"
                        );
                        buttonDisabled = true;
                        break;
                    }
                }

                alerts.push(
                    <div
                        id="a54f1abb-9285-4bf0-b746-c3d6410a39f1"
                        className="alert alert-danger w-100"
                        role="alert"
                    >
                        <div
                            id="3d868a2e-b09c-4cf7-972e-16bc2cfd423f"
                            className="fw-semibold"
                        >
                            {t(
                                "settingsPage.payoutSettings.payoutMethodSettings.payoutsDisabled"
                            )}
                        </div>
                        <div
                            id="bbc37c92-f81d-4d09-95ac-fb6dcce34312"
                            className="mt-2"
                        >
                            <small id="977df823-40e7-491b-83d1-b7d466d64fcc">
                                {disabled_reason}
                            </small>
                        </div>
                        <div
                            id="debb56ca-dd1c-4d33-8da6-bdabd7b5969a"
                            className="mt-3"
                        >
                            <small id="ae9f7a65-7332-4efd-b27f-b2cf7341a28b">
                                {t(
                                    "settingsPage.payoutSettings.payoutMethodSettings.contactUsForHelp"
                                )}
                            </small>
                        </div>
                    </div>
                );

                if (stripeAccount.requirements.errors.length > 0) {
                    buttonClass = "danger";
                    buttonText = t(
                        "settingsPage.payoutSettings.payoutMethodSettings.fixPayoutMethod"
                    );
                    buttonDisabled = false;

                    alerts.push(
                        <div
                            id="445f3c8d-f7da-4c7f-9a54-5924266cbc9e"
                            className="alert alert-danger w-100"
                            role="alert"
                        >
                            <span id="0b41f6b1-883a-4354-97a5-4b282729e4c1">
                                {t(
                                    "settingsPage.payoutSettings.payoutMethodSettings.errors"
                                )}
                                :
                            </span>
                            <ul
                                id="ab76b002-1982-435f-8e2c-bd6693296645"
                                className="m-0 mt-2"
                            >
                                {stripeAccount.requirements.errors.map(
                                    (item, index: number) => (
                                        <li
                                            id="23ddba04-fe9d-4ddd-a9a5-02d61517453e"
                                            key={index}
                                        >
                                            {item.reason}
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                    );
                }
            }
        }
    }

    return (
        <div id={id} className="border rounded-3 p-3">
            <h6 id="1b3fca6e-0c07-4d87-9eb9-d4aa28da58ad">
                {t(
                    "settingsPage.payoutSettings.payoutMethodSettings.payoutDetails"
                )}
            </h6>
            <hr id="d779674b-5415-4a16-a434-0dd1e92f8629" />
            {gettingAccount && (
                <Spinner id="c21a2e15-546d-4eb7-b552-9b4c4fab1e7b" />
            )}
            {!gettingAccount && (
                <>
                    {alerts.map((item, index: number) => (
                        <span
                            id="1a264579-324c-4948-89ab-861d85dd9354"
                            key={index}
                        >
                            {item}
                        </span>
                    ))}
                    <PayoutMethodsList
                        id="e27063b7-8afc-4295-96d7-092ed3671968"
                        payoutMethods={payoutMethods}
                    />
                    <hr id="afc438e8-81ae-4f7b-9335-3bf64659e0e7" />
                    <Button
                        id="a4fd8f58-b447-422b-9109-1132bb60d396"
                        className={`btn btn-sm btn-${buttonClass} float-end`}
                        isLoading={gettingAccountLink}
                        onClick={() => getStripeAccountLink(linkTypeUpdate)}
                        disabled={buttonDisabled}
                    >
                        {buttonText}
                    </Button>
                </>
            )}
        </div>
    );
};

export default PayoutMethodSettings;
