class ExternalPlatform {
    readonly name: string;
    readonly icon_url: string;

    constructor(data: any) {
        this.name = data.name;
        this.icon_url = data.icon_url;
    }
}

export default ExternalPlatform;
